import React from 'react';
import Page from '../../../components/Page';
import { useParams } from 'react-router-dom';
import { accessVerify } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function MetaDashboard() {
  const { type } = useParams();

  const getUrl = () => {
    switch (type) {
      case 'sales':
        return accessVerify('DASHBOARD_META_DASHBOARD_SALE_REPORT') ? 'https://meta.bubblemania.lk/public/dashboard/dd7d8824-ec22-41cc-9c16-1f4a09e473e7' : '';
      case 'employees':
        return accessVerify('DASHBOARD_META_DASHBOARD_EMPLOYEE_REPORT') ? 'https://meta.bubblemania.lk/public/dashboard/9995ab6c-1412-4f9f-9f3a-0376a9e47aee' : '';
      case 'stockChecker':
        return accessVerify('DASHBOARD_META_DASHBOARD_STOCK_AVAILABILITY_REPORT') ? 'https://meta.bubblemania.lk/public/dashboard/c2321816-8be5-44b4-ab03-167ed3f9887c' : '';
      case 'stockDashboard':
        return accessVerify('DASHBOARD_META_DASHBOARD_STOCK_REPORT') ? 'https://meta.bubblemania.lk/public/dashboard/2ac3bb6c-e27c-421e-9d3e-9189936049b4' : '';
    }
  };

  return (
    <Page title='Metabase Reporting'>{getUrl() !== '' ? <iframe src={getUrl()} style={{ width: '100%', height: '500vh' }} frameborder='0' allowTransparency></iframe> : <PermissionRequired />}</Page>
  );
}

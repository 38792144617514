import React from 'react';
import { DateTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import moment from 'moment';
import { dispatch } from '../redux/store';
import { getTimeLogs } from '../redux/slices/hrRedux/timeLogRedux';

const DateTimeRangePickerCustomer = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const handleStartDateChange = (date) => {
    setStartDate(date);

    // If the end date is set and it is before the new start date, reset the end date
    if (endDate && moment(endDate).isBefore(date)) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    // If the new end date is before the start date, do not update the end date
    if (startDate && moment(date).isBefore(startDate)) {
      return;
    }

    setEndDate(date);
  };

  return (
    <>
      <DateTimePicker
        label='Start Date'
        value={startDate}
        onChange={handleStartDateChange}
        onAccept={() => {
          setTimeout(() => {
            dispatch(getTimeLogs(moment(startDate).format('YYYY-MM-DD HH:mm:ss'), moment(endDate).format('YYYY-MM-DD HH:mm:ss')));
          }, 1);
        }}
        renderInput={(props) => <TextField {...props} style={{ marginRight: '10px' }} />}
      />
      <DateTimePicker
        label='End Date'
        value={endDate}
        minDateTime={startDate}
        onChange={handleEndDateChange}
        onAccept={() => {
          setTimeout(() => {
            dispatch(getTimeLogs(moment(startDate).format('YYYY-MM-DD HH:mm:ss'), moment(endDate).format('YYYY-MM-DD HH:mm:ss')));
          }, 1);
        }}
        renderInput={(props) => <TextField {...props} style={{ marginRight: '10px' }} />}
      />
    </>
  );
};

export default DateTimeRangePickerCustomer;

import { capitalCase } from 'change-case';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../../../components/Page';
import useTabs from '../../../../hooks/useTabs';
import UserSalary from './components/userSalary';
import Iconify from '../../../../components/Iconify';
import useSettings from '../../../../hooks/useSettings';
import { Box, Container, Tab, Tabs } from '@mui/material';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import AccountActivation from './components/accountActivation';
import { dispatch, useSelector } from '../../../../redux/store';
import { UserAccountGeneral, UserUploadedFiles } from './components';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { _userAddressBook, _userInvoices, _userPayment } from '../../../../_mock';
import { getSpecificUserData, getUsers } from '../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import UserAccessControl from './components/userAccessControl';
import { accessVerify, safeJSONParse } from '../../../../utils/common';
import LeaveCalender from './components/leaveCalender';
import PermissionRequired from '../../../errorPages/permissionRequired';

// ----------------------------------------------------------------------

export default function UserManagementAccount() {
  const { id } = useParams();
  const { themeStretch } = useSettings();
  const [usersList, setUsersList] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [currentUserData, setCurrentUserData] = useState({});
  const { selectedUser } = useSelector((state) => state.user);

  const { data } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setUsersList(data);
  }, [data]);

  useEffect(() => {
    setCurrentUserData(selectedUser?.[0]);
  }, [selectedUser]);

  useEffect(() => {
    id === 'own' ? setCurrentUserId(safeJSONParse(localStorage.getItem('authenticatedUser'))?.id) : setCurrentUserId(id);
  }, [id]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(getSpecificUserData(currentUserId));
    }
  }, [currentUserId]);

  const { currentTab, onChangeTab } = useTabs('personal_info');

  const ACCOUNT_TABS = [
    {
      value: 'personal_info',
      icon: <Iconify icon={'solar:user-broken'} width={20} height={20} />,
      component: accessVerify('GENERAL_DATA') ? <UserAccountGeneral userId={currentUserId} /> : <PermissionRequired />,
    },
    {
      value: 'payroll',
      icon: <Iconify icon={'uiw:pay'} width={20} height={20} />,
      component: accessVerify('USER_PROFILE_UPDATE_PAYROLL') ? <UserSalary userId={currentUserId} /> : <PermissionRequired />,
    },
    {
      value: 'uploaded_files',
      icon: <Iconify icon={'ph:upload'} width={20} height={20} />,
      component: accessVerify('GENERAL_DATA') ? <UserUploadedFiles userId={currentUserId} /> : <PermissionRequired />,
    },
    {
      value: 'account_activation',
      icon: <Iconify icon={'carbon:account'} width={20} height={20} />,
      component: accessVerify('USER_PROFILE_ACTIVATION') ? (
        <AccountActivation cards={_userPayment} addressBook={_userAddressBook} invoices={_userInvoices} userId={currentUserId} />
      ) : (
        <PermissionRequired />
      ),
    },
    {
      value: 'leave_manager',
      icon: <Iconify icon={'fluent-mdl2:manager-self-service'} width={20} height={20} />,
      component: accessVerify('USER_PROFILE_APPLY_LEAVES') ? <LeaveCalender /> : <PermissionRequired />,
    },
    {
      value: 'access_controller',
      icon: <Iconify icon={'fluent-mdl2:permissions'} width={20} height={20} />,
      component: accessVerify('USER_PROFILE_PERMISSION_CONTROL') ? <UserAccessControl userId={id} usersList={usersList} /> : <PermissionRequired />,
    },
  ];

  return (
    <Page title='User: Account Settings'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`${currentUserData?.name || 'User'}'s Account`}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'User', href: '' }, { name: `${currentUserData?.name || 'User'}'s Account` }]}
        />

        <Tabs allowScrollButtonsMobile variant='scrollable' scrollButtons='auto' value={currentTab} onChange={onChangeTab}>
          {ACCOUNT_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}

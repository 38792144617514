import { Autocomplete, Card, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import Iconify from '../../../../../components/Iconify';

export default function AddCategoryButton({ onAddCategory }) {
  return (
    <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={onAddCategory}>
      <div style={{ height: '1px', width: '47%', backgroundColor: 'black' }}></div>
      <div style={{ margin: '0 10px' }}>
        <IconButton color='primary'>
          <Iconify icon={'carbon:add-filled'} />
        </IconButton>
      </div>
      <div style={{ height: '1px', width: '47%', backgroundColor: 'black' }}></div>
    </a>
  );
}

import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Button, ClickAwayListener, Input, InputAdornment, Menu, MenuItem, Slide } from '@mui/material';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CloseIcon from '@mui/icons-material/Close';
import { dispatch, useSelector } from '../../../redux/store';
import { viewPOSCloseTab, viewPOSTab } from '../../../redux/slices/posRedux/posRedux';
import { accessVerify } from '../../../utils/common';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [anchorEl, setAnchorEl] = useState(null);

  const [isOpen, setOpen] = useState(false);
  const { loggedInUser } = useSelector((state) => state?.user);
  const { selectedLocation } = useSelector((state) => state.location);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPOS = () => {
    dispatch(viewPOSTab());
    handleClose();
  };

  const handleClosePOS = () => {
    dispatch(viewPOSCloseTab());
    handleClose();
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {selectedLocation?.isPosAvailable === 1 && accessVerify('POS') && (
          <>
            {loggedInUser?.posCashDrawerStatus === 'open' ? (
              <>
                <Button variant='outlined' startIcon={<Iconify icon={'mdi:printer-pos-check'} />} onClick={handleClick} color={'success'}>
                  POS Options
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={handleOpenPOS}>
                    <LocalAtmIcon style={{ marginRight: '8px' }} />
                    Open POS Drawer
                  </MenuItem>
                  <MenuItem onClick={handleClosePOS}>
                    <CloseIcon style={{ marginRight: '8px' }} />
                    Close POS
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Button
                variant='outlined'
                startIcon={<LocalAtmIcon />}
                onClick={() => {
                  dispatch(viewPOSTab());
                }}
              >
                OPEN POS DRAWER
              </Button>
            )}
          </>
        )}

        <Slide direction='down' in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder='Search…'
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant='contained' onClick={handleClose}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}

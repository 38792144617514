// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function TotalSalesIcon({ ...other }) {
  const theme = useTheme();
  const PRIMARY_LIGHTER = theme.palette.primary.lighter;
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box
      sx={{
        width: 120,
        height: 120,
        lineHeight: 0,
        borderRadius: '50%',
        bgcolor: 'background.neutral',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...other}
    >
      <svg xmlns='http://www.w3.org/2000/svg' className='svg-icon' style={{ width: '60%', height: '60%', verticalAlign: 'middle' }} fill={PRIMARY_MAIN} overflow='hidden' viewBox='0 0 1322 1024'>
        <path d='M330.326 602.847v421.15h198.191v-421.15l-99.1-82.587-99.09 82.587zM0 1023.997h198.19V701.936L0 858.84v165.159zm990.966-520.253v520.254h198.197v-685.41L990.966 503.744zM660.648 710.192v313.806H858.84v-412.9L701.935 743.23l-41.287-33.039zM1321.288 0H941.42l156.904 148.64-404.65 330.329-264.26-222.97L0 594.578v148.647L429.418 404.64l272.517 222.97 487.228-396.386 132.127 123.865L1321.287 0z'></path>
      </svg>
    </Box>
  );
}

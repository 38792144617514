import React from 'react';
import { Card, Grid } from '@mui/material';
import MenuList from './subcomponents/orderInfo/menuList';
import PosCart from './subcomponents/orderInfo/posCart';
import PaymentCollector from './subcomponents/payments/paymentCollector';
import PaymentSummery from './subcomponents/payments/paymentSummery';

export const Payments = ({ data, setData, onClickSound, onChangeTab }) => {
  const cartItems = data?.orderInfo?.cart;

  // Create a map to store counts for each unique id
  const idCounts = new Map();

  // Count occurrences of each id
  cartItems.forEach((cartItem) => {
    const id = cartItem?.id;
    idCounts.set(id, (idCounts.get(id) || 0) + 1);
  });
  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12} md={4}>
        <Card sx={{ p: 3 }}>
          <PaymentSummery data={data} setData={setData} onClickSound={onClickSound} onChangeTab={onChangeTab} />
        </Card>
      </Grid>
      <Grid item xs={12} md={8} justifyContent='center'>
        <Card sx={{ p: 3 }}>
          <Grid container justifyContent='left'>
            <PaymentCollector data={data} setData={setData} onClickSound={onClickSound} onChangeTab={onChangeTab} />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mailReducer from './slices/templateRedux/mail';
import chatReducer from './slices/templateRedux/chat';
import productReducer from './slices/templateRedux/product';
import calendarReducer from './slices/templateRedux/calendar';
import kanbanReducer from './slices/templateRedux/kanban';
import loading from './slices/templateRedux/productManagementRedux/loadingRedux';
import products from './slices/templateRedux/productManagementRedux/productsRedux';
import categories from './slices/templateRedux/productManagementRedux/categoriesRedux';
import tags from './slices/templateRedux/productManagementRedux/tagsRedux';
import locations from './slices/settingsManagementRedux/locationsRedux';
import departments from './slices/settingsManagementRedux/departmentsRedux';
import user from './slices/hrRedux/UserManagementRedux/userManagementRedux';
import document from './slices/hrRedux/UserManagementRedux/userDocumentUploadRedux';
import userRostManagement from './slices/hrRedux/UserManagementRedux/userRostManagement';
import timeLogRedux from './slices/hrRedux/timeLogRedux';
import inspection from './slices/hrRedux/inspection/inspectionRedux';
import salary from './slices/hrRedux/UserManagementRedux/salaryRedux';
import salesRedux from './slices/saleRedux/salesRedux';
import accessControl from './slices/hrRedux/UserManagementRedux/accessControlRedux';
import supplierRedux from './slices/stockManagementRedux/supplierRedux';
import foodRedux from './slices/stockManagementRedux/foodRedux';
import foodCategoryRedux from './slices/stockManagementRedux/foodCategoryRedux';
import unitRedux from './slices/stockManagementRedux/unitRedux';
import EnterPricesRedux from './slices/purchaseRedux/purchaseRedux';
import purchaseNotesRedux from './slices/purchaseRedux/purchaseNotesRedux';
import stockRedux from './slices/stockManagementRedux/stockRedux';
import StockTransferRedux from './slices/stockManagementRedux/stockTransferRedux';
import posRedux from './slices/posRedux/posRedux';
import CookingRedux from './slices/cookingRedux/cookingRedux';
import CommonRedux from './slices/commonRedux/commonRedux';
import AssetsRedux from './slices/assetsRedux/assetsRedux';
import stockOrderRedux from './slices/stockManagementRedux/stockOrderRedux';
import AssetCategoriesRedux from './slices/assetsRedux/assetCategoriesRedux';
import CashFlowRedux from './slices/financeRedux/cashFlowRedux';
import PaymentTypeRedux from './slices/financeRedux/paymentTypeRedux';
import customerRedux from './slices/customerRedux/customerRedux';
import inspectionTypeRedux from './slices/hrRedux/inspection/inspectionTypeRedux';
import inspectionExaminRedux from './slices/hrRedux/inspection/inspectionExaminRedux';
import userLeaveRedux from './slices/hrRedux/UserManagementRedux/userLeaveRedux';
import HomeRedux from './slices/homeRedux/homeRedux';
import CouponRedux from './slices/marketingRedux/couponRedux';
import cashFlowDocumentTypeRedux from './slices/financeRedux/cashFlowDocumentTypeRedux';
import expenseCategoriesRedux from './slices/expenseRedux/expenseCategoriesRedux';
import CashFlowCategoriesRedux from './slices/financeRedux/cashFlowCategoriesRedux';
import expensesRedux from './slices/expenseRedux/expensesRedux';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  loading: loading,
  products: products,
  categories: categories,
  tags: tags,
  location: persistReducer({ key: 'location', storage }, locations),
  supplier: persistReducer({ key: 'supplier', storage }, supplierRedux),
  user: user,
  userRostManagement: userRostManagement,
  leave: userLeaveRedux,
  home: HomeRedux,
  sales: salesRedux,
  timeLog: timeLogRedux,
  cooking: CookingRedux,
  accessControl: persistReducer({ key: 'accessControl', storage }, accessControl),
  inspection: inspection,
  inspectionExamin: inspectionExaminRedux,
  inspectionType: inspectionTypeRedux,
  department: persistReducer({ key: 'department', storage }, departments),
  food: persistReducer({ key: 'food', storage }, foodRedux),
  unit: persistReducer({ key: 'unit', storage }, unitRedux),
  expenses: persistReducer({ key: 'expenses', storage }, expensesRedux),
  expenseCategories: persistReducer({ key: 'expenseCategories', storage }, expenseCategoriesRedux),
  enterPrices: EnterPricesRedux,
  foodCategory: persistReducer({ key: 'foodCategory', storage }, foodCategoryRedux),
  purchaseNotes: persistReducer({ key: 'purchaseNotes', storage }, purchaseNotesRedux),
  stock: persistReducer({ key: 'stock', storage }, stockRedux),
  stockOrder: persistReducer({ key: 'stockOrder', storage }, stockOrderRedux),
  stockTransfer: persistReducer({ key: 'stockTransfer', storage }, StockTransferRedux),
  paymentTypes: persistReducer({ key: 'PaymentTypes', storage }, PaymentTypeRedux),
  coupon: persistReducer({ key: 'CouponRedux', storage }, CouponRedux),
  // customers: persistReducer({ key: 'customers', storage }, customerRedux),
  customers: customerRedux,
  pos: posRedux,
  assets: AssetsRedux,
  assetCategories: AssetCategoriesRedux,
  cashFlow: CashFlowRedux,
  cashFlowCategories: CashFlowCategoriesRedux,
  cashFlowDocumentType: persistReducer({ key: 'cashFlowDocumentTypeRedux', storage }, cashFlowDocumentTypeRedux),
  common: CommonRedux,
  salary: salary,
  document: document,
  isLoading: false,
});

export { rootPersistConfig, rootReducer };

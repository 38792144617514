import { pdf } from '@react-pdf/renderer';
import PosInvoicePdf from '../../pos/components/subcomponents/posInvoicePdf';
import { handleNumber, utcMoment } from '../../../../utils/common';
import { toast } from 'react-toastify';
import React from 'react';

export const handleRePrint = async (data, selectedLocation, isKOT = false) => {
  const pdfBlob = await pdf(
    <PosInvoicePdf
      data={{
        selectedPrinter: data?.selectedPrinter || 'epson',
        isReprint: true,
        isKOT: isKOT,
        locationName: selectedLocation?.invoiceName,
        locationAddress: selectedLocation?.address,
        phoneNumber: selectedLocation?.contactNumber,
        orderNumber: data?.pos_id,
        orderType: data?.pos_saleTypeName,
        cashierName: data?.pos_entered_userName,
        cashierDrawerId: data?.cashdrawers_id,
        customerName: data?.customerName,
        loyaltyPoint: null,
        tableNumber: data?.tableNumber || '-',
        specialNote: data?.specialNote || '-',
        dateTime: utcMoment(data?.pos_dateTime).format('YYYY-MM-DD HH:mm:ss'),
        invoiceItems: data?.pos_items.map((item) => {
          return {
            title: item.foodName,
            qty: handleNumber(item.qty),
            price: handleNumber(item.itemPrice),
            amount: item.qtyPrice,
          };
        }),
        grandTotal: data?.pos_totalAmount || 0,
        givenAmount: data?.pos_totalPaidAmount || 0,
        balanceAmount: data?.pos_balance || 0,
        paymentMethod: data?.pos_payments?.map((value) => value?.paymentTypesName),
        totalItemCount: data?.pos_items?.length || 0,
      }}
    />
  ).toBlob();

  sendPDFToBackend(pdfBlob, isKOT ? 'ReprintKOT' : 'ReprintInvoice');
};

const sendPDFToBackend = (blob, name) => {
  const formdata = new FormData();
  formdata.append('pdfFile', blob, `${name}.pdf`);
  formdata.append('pageCount', '1');

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  fetch('http://localhost:3001/print', requestOptions)
    .then((response) => response.text())
    .then((result) => {
      toast.success('Invoice Printed Successfully!');
    })
    .catch((error) => {
      console.error('Error sending PDF:', error);
      toast.error('Error Printing Invoice!');
    });
};

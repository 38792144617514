import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid, Stack, Switch, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { accessVerify, capitalize } from '../../../../../utils/common';
import { useDispatch, useSelector } from '../../../../../redux/store';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addAccessControl, getAccessControlById } from '../../../../../redux/slices/hrRedux/UserManagementRedux/accessControlRedux';
import { updateUserDataAfterUpdate } from '../../../../../redux/slices/posRedux/posRedux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function UserAccessControl({ userId }) {
  const dispatch = useDispatch();
  let allLocations;
  ({ data: allLocations } = useSelector((state) => state.location));

  let userInfo;
  let userInfoUpdated;
  ({ data: userInfo, addData: userInfoUpdated } = useSelector((state) => state.accessControl));
  const { permissionsList } = useSelector((state) => state.common);

  const [permissions, setPermissions] = useState([]);
  const [locationPermissions, setLocationPermissions] = useState([]);

  useEffect(() => {
    dispatch(getAccessControlById(userId));
  }, [userId, userInfoUpdated]);

  useEffect(() => {
    dispatch(updateUserDataAfterUpdate());
  }, [userInfoUpdated]);

  useEffect(() => {
    setLocationPermissions(userInfo?.locationPermissions || []);
    setPermissions(userInfo?.permissions || []);
  }, [userInfo]);

  const handleLocationToggle = (code) => {
    setLocationPermissions((prev) => {
      if (prev.includes(code)) {
        return prev.filter((item) => item !== code);
      } else {
        return [...prev, code];
      }
    });
  };
  const handlePermissionToggle = (code) => {
    setPermissions((prev) => {
      if (prev.includes(code)) {
        return prev.filter((item) => item !== code);
      } else {
        return [...prev, code];
      }
    });
  };

  const groupedPermissions = (mainCategory) =>
    []
      .concat(permissionsList)
      .filter((permission) => permission.mainCategory === mainCategory)
      .sort((a, b) => a.priority - b.priority)
      ?.reduce((acc, permission) => {
        if (!acc[permission.category]) {
          acc[permission.category] = [];
        }
        acc[permission.category].push(permission);
        return acc;
      }, {});

  return (
    <Box>
      <Grid container spacing={3}>
        <Card sx={{ py: 3, px: 3 }} style={{ width: '100%' }}>
          <Typography variant='h4' paragraph>
            Location Based Access Control
          </Typography>
          <Card sx={{ py: 3, px: 3, mb: 2 }} style={{ width: '100%' }}>
            <Grid container alignItems='center'>
              {allLocations.map((location) => (
                <Grid item xs={6} lg={3}>
                  <Stack direction='row' alignItems='center'>
                    <FormControlLabel
                      value={location.id}
                      control={<Switch checked={locationPermissions?.includes(location.id)} onChange={() => handleLocationToggle(location.id)} />}
                      label={location.name}
                      labelPlacement='end'
                    />
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Card>

          <Typography variant='h4' paragraph>
            Application Access Control
          </Typography>
          {[
            ...new Set(
              []
                .concat(permissionsList)
                ?.sort((a, b) => a.priority - b.priority)
                ?.map((value) => value?.mainCategory)
            ),
          ]?.map((category) => {
            return (
              <Accordion key={category}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography>{capitalize(category)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(groupedPermissions(category)).map(([category, permissionCategories]) => (
                    <Accordion key={category}>
                      {category === 'superAdminAccess' ? (
                        <>
                          {accessVerify('SUPER_ADMIN') && (
                            <>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                                <Typography>{capitalize(category)}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Card sx={{ py: 3, px: 3, mb: 2 }} style={{ width: '100%' }}>
                                  <Typography variant='subtitle1' sx={{ mb: 2 }}>
                                    {capitalize(category)}
                                  </Typography>
                                  <Grid container alignItems='center' justifyContent='space-between'>
                                    {permissionCategories.map((permissionIndex) => (
                                      <Grid item xs={6} lg={2.4} key={permissionIndex.code}>
                                        <Stack direction='row' alignItems='center'>
                                          <FormControlLabel
                                            value={permissionIndex.code}
                                            control={<Switch checked={permissions.includes(permissionIndex.code)} onChange={() => handlePermissionToggle(permissionIndex.code)} />}
                                            label={permissionIndex.title}
                                            labelPlacement='end'
                                          />
                                        </Stack>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Card>
                              </AccordionDetails>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                            <Typography>{capitalize(category)}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Card sx={{ py: 3, px: 3, mb: 2 }} style={{ width: '100%' }}>
                              <Typography variant='subtitle1' sx={{ mb: 2 }}>
                                {capitalize(category)}
                              </Typography>
                              <Grid container alignItems='center' justifyContent='space-between'>
                                {permissionCategories.map((permissionIndex) => (
                                  <Grid item xs={6} lg={2.4} key={permissionIndex.code}>
                                    <Stack direction='row' alignItems='center'>
                                      <FormControlLabel
                                        value={permissionIndex.code}
                                        control={<Switch checked={permissions.includes(permissionIndex.code)} onChange={() => handlePermissionToggle(permissionIndex.code)} />}
                                        label={permissionIndex.title}
                                        labelPlacement='end'
                                      />
                                    </Stack>
                                  </Grid>
                                ))}
                              </Grid>
                            </Card>
                          </AccordionDetails>
                        </>
                      )}
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Card>
      </Grid>

      <Box position='fixed' bottom={20} right={20} zIndex={9999}>
        <LoadingButton
          type='submit'
          variant='contained'
          onClick={() => {
            dispatch(
              addAccessControl({
                permissions: permissions,
                locationPermissions: locationPermissions,
                userId: userId,
              })
            );
          }}
        >
          Save Changes
        </LoadingButton>
      </Box>
    </Box>
  );
}

import { BASE_URL, ENV } from './config';
import { dispatch } from './redux/store';
import { doneLoading, startLoading } from './redux/slices/templateRedux/productManagementRedux/loadingRedux';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';

export const get = async (path, queryParams = {}) => {
  dispatch(startLoading());

  // Construct query string from queryParams object
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${BASE_URL}${path}${queryString ? '?' + queryString : ''}`;

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    method: 'GET',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.error(responseValue?.message, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const post = async (path, body, baseUrl = BASE_URL) => {
  dispatch(startLoading());
  let toastId;
  if (path !== 'auth') {
    toastId = toast.loading('Loading...');
  }
  const response = await fetch(`${baseUrl}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    body: JSON.stringify(body),
    method: 'POST',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  } else {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const put = async (path, body) => {
  dispatch(startLoading());

  const toastId = toast.loading('Loading...');
  const response = await fetch(`${BASE_URL}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    body: JSON.stringify(body),
    method: 'PUT',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  } else {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const deleteMethod = async (path, body) => {
  dispatch(startLoading());

  const toastId = toast.loading('Loading...');
  const response = await fetch(`${BASE_URL}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('authToken')?.toString() || '',
    },
    body: JSON.stringify(body),
    method: 'DELETE',
  });

  dispatch(doneLoading());

  const responseValue = await response.json();
  if (!responseValue.status) {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  } else {
    toast.update(toastId, {
      render: responseValue?.message,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
  }
  return responseValue;
};

export const imageUpload = async (file, folderName, successCallback, errorCallback) => {
  dispatch(startLoading());

  const toastId = toast.loading('Loading...');
  const originalFileName = file.name;

  try {
    let compressedFile = file;
    let compressedSize = file.size;

    while (compressedSize > 1048576) {
      const quality = Math.max(0.1, 1048576 / compressedSize);
      compressedFile = await new Promise((resolve, reject) => {
        new Compressor(compressedFile, {
          quality: quality,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
      compressedSize = compressedFile.size;

      if (quality <= 0.1 || compressedSize === file.size) {
        break;
      }
    }

    const formData = new FormData();
    formData.append('file', compressedFile, originalFileName);
    formData.append('folderName', folderName);
    formData.append('tag', `WEB_${ENV}`);

    const response = await fetch(`${BASE_URL}common/imageUpload`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: 'Bearer ' + (localStorage.getItem('authToken')?.toString() || ''),
      },
    });

    const data = await response.json();
    if (data?.status) {
      toast.update(toastId, {
        render: 'Image Uploaded Successfully',
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
        closeButton: true,
        isLoading: false,
      });
      successCallback(data);
    } else {
      toast.update(toastId, {
        render: 'Image Upload Failed',
        type: toast.TYPE.ERROR,
        autoClose: 2000,
        closeButton: true,
        isLoading: false,
      });
      errorCallback('Upload Failed.');
    }
  } catch (error) {
    toast.update(toastId, {
      render: 'Image Upload Failed',
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: true,
      isLoading: false,
    });
    console.error(error);
    errorCallback('Upload Failed.');
  } finally {
    dispatch(doneLoading());
  }
};

// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import { safeJSONParse } from '../utils/common';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  const userImageString = localStorage.getItem('authenticatedUser');

  const getUserImage = () => {
    try {
      return safeJSONParse(userImageString)?.userImage || '';
    } catch (e) {
      return '';
    }
  };

  return (
    <Avatar src={getUserImage()} alt={user?.displayName} color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color} {...other}>
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}

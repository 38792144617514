import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { getAvailabilityData } from '../../../redux/slices/stockManagementRedux/stockRedux';
import DatePicker from '@mui/lab/DatePicker';
import { format } from 'date-fns';
import { accessVerify, capitalize } from '../../../utils/common';
import DataGridTable from '../../../components/table/DataGridTable';
import PermissionRequired from '../../errorPages/permissionRequired';
import { fCurrency } from '../../../utils/formatNumber';

const StockAvailabilityReport = () => {
  const { themeStretch } = useSettings();
  const [data, setData] = useState();
  const [filterStartDate, setFilterStartDate] = useState(new Date());
  const [rowSelection, setRowSelection] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  const onFilterStartDate = (newValue) => {
    setFilterStartDate(newValue);
  };

  useEffect(() => {
    const formattedDate = format(filterStartDate, 'yyyy-MM-dd');

    dispatch(getAvailabilityData(`${formattedDate} 00:00:00`, selectedLocation.id));
  }, [filterStartDate, getAvailabilityData, selectedLocation]);

  const { availabilityReportData: availabilityReportData } = useSelector((state) => state?.stock);

  useEffect(() => {
    setData(availabilityReportData);
  }, [availabilityReportData]);

  useEffect(() => {
    if (data?.length > 0) {
      setTotalPrice(data?.map((value) => value?.costPerCurrentQty)?.reduce((a, c) => a + c) || 0);
    } else {
      setTotalPrice(0);
    }
  }, [data]);

  const columns = !data?.[0]
    ? []
    : Object.keys(data?.[0]).map((value) => {
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      });
  return (
    <Page title='Availability Report'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Availability Report'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.transfer.sender }, { name: 'Availability Report' }]}
          action={[]}
        />

        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <DatePicker label='Start date' value={filterStartDate} onChange={onFilterStartDate} renderInput={(params) => <TextField {...params} fullWidth />} />
          </Grid>
          <Grid item xs={12} md={6}>
            <h1 style={{ textAlign: 'right', color: 'red' }}>{fCurrency(totalPrice)}</h1>
          </Grid>
        </Grid>
        {accessVerify('SUPER_ADMIN') ? (
          <DataGridTable
            name={`${selectedLocation?.name} - ${format(filterStartDate, 'yyyy-MM-dd')} - Stock Availability Report`}
            data={data}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            isRowClickable={true}
            onRowClick={(value) => {}}
            renderRowActionItems={(value) => []}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
};

export default StockAvailabilityReport;

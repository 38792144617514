import React from 'react';
import { Card, Typography } from '@mui/material';
import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';
import utcMoment from 'moment';
import Iconify from '../../../../../components/Iconify';

const CashFlowHistoryTimeline = ({ cashFlowLog }) => {
  return (
    <Card>
      <Typography variant='subtitle1' sx={{ p: 3 }}>
        History Information
      </Typography>
      <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Timeline position='alternate'>
          {cashFlowLog?.map((value) => (
            <TimelineItem key={value.id}>
              <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                {utcMoment(value?.dateTime).format('YYYY-MM-DD HH:mm:ss')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <Iconify icon='eva:checkmark-circle-2-fill' />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant='body1' component='span'>
                  <b>{value?.title}</b>
                </Typography>
                <Typography>{value?.description}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </Card>
  );
};

export default CashFlowHistoryTimeline;

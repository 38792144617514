import moment from 'moment/moment';
export const userModel = (selectedObject) => {
  return {
    ...selectedObject,
    id: selectedObject?.id || null,
    uId: selectedObject?.uId || null,
    userImage: selectedObject?.userImage || null,
    name: selectedObject?.name || null,
    email: selectedObject?.email || null,
    NIC_Front: selectedObject?.NIC_Front || null,
    NIC_Back: selectedObject?.NIC_Back || null,
    phoneNumber: selectedObject?.phoneNumber || null,
    telephoneNumber: selectedObject?.telephoneNumber || null,
    emergencyContact: selectedObject?.emergencyContact || null,
    userProfileID: selectedObject?.userProfileID || null,
    status: selectedObject?.status || null,
    city: selectedObject?.city || null,
    zipCode: selectedObject?.zipCode || null,
    about: selectedObject?.about || null,
    accountName: selectedObject?.accountName || null,
    bank: selectedObject?.bank || null,
    branch: selectedObject?.branch || null,
    accountNumber: selectedObject?.accountNumber || null,

    salary_act: selectedObject?.salary_act || null,
    salary_basic: selectedObject?.salary_basic || null,
    salary_workingDaysPerMonth: selectedObject?.salary_workingDaysPerMonth || null,
    salary_workingHoursPerDay: selectedObject?.salary_workingHoursPerDay || null,
    salary_OtMultiplication: selectedObject?.salary_OtMultiplication || null,
    salary_budgetReliefAllowanceOne: selectedObject?.salary_budgetReliefAllowanceOne || null,
    salary_budgetReliefAllowanceTwo: selectedObject?.salary_budgetReliefAllowanceTwo || null,
    salary_attendanceAllowance: selectedObject?.salary_attendanceAllowance || null,
    salary_timelyAttendanceAllowance: selectedObject?.salary_timelyAttendanceAllowance || null,
    salary_startTime: moment(selectedObject?.salary_startTime, 'HH:mm:ss') || null,
    salary_outletStaffDisciplineEligible: selectedObject?.salary_outletStaffDisciplineEligible || null,
    salary_outletStaffDisciplineAmount: selectedObject?.salary_outletStaffDisciplineAmount || null,
    salary_outletStaffDisciplineExam: selectedObject?.salary_outletStaffDisciplineExam || null,
    salary_boardingHouseMaintenanceEligible: selectedObject?.salary_boardingHouseMaintenanceEligible || null,
    salary_boardingHouseMaintenanceAmount: selectedObject?.salary_boardingHouseMaintenanceAmount || null,
    salary_boardingHouseMaintenanceExam: selectedObject?.salary_boardingHouseMaintenanceExam || null,
    salary_staffGroomingEligible: selectedObject?.salary_staffGroomingEligible || null,
    salary_staffGroomingAmount: selectedObject?.salary_staffGroomingAmount || null,
    salary_staffGroomingExam: selectedObject?.salary_staffGroomingExam || null,
    salary_stockInspectionEligible: selectedObject?.salary_stockInspectionEligible || null,
    salary_stockInspectionAmount: selectedObject?.salary_stockInspectionAmount || null,
    salary_equipmentInspectionEligible: selectedObject?.salary_equipmentInspectionEligible || null,
    salary_equipmentInspectionAmount: selectedObject?.salary_equipmentInspectionAmount || null,
    salary_equipmentInspectionExam: selectedObject?.salary_equipmentInspectionExam || null,
    salary_cleannessInspectionEligible: selectedObject?.salary_cleannessInspectionEligible || null,
    salary_cleannessInspectionAmount: selectedObject?.salary_cleannessInspectionAmount || null,
    salary_cleannessInspectionExam: selectedObject?.salary_cleannessInspectionExam || null,
    salary_salesTargetEligible: selectedObject?.salary_salesTargetEligible || null,
    salary_salesTargetAmount: selectedObject?.salary_salesTargetAmount || null,
    salary_documentSubmissionEligible: selectedObject?.salary_documentSubmissionEligible || null,
    salary_documentSubmissionAmount: selectedObject?.salary_documentSubmissionAmount || null,
  };
};

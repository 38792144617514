import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import LoadingBar from 'react-top-loading-bar';
import { useSelector } from '../redux/store';

export function ProgressBar() {
  const theme = useTheme();
  const [progress, setProgress] = useState(10);
  const { apiCalls, completedApiCalls } = useSelector((state) => state.loading);

  useEffect(() => {
    setProgress((completedApiCalls / apiCalls) * 100);
  }, [apiCalls, completedApiCalls]);

  return <LoadingBar color={theme.palette.primary.main} progress={progress} onLoaderFinished={() => setProgress(0)} />;
}

import React from 'react';

export default function Details({ setCurrentPage }) {
  return (
    <div className='fixedMobileSize'>
      <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712659474/WhatsApp_Image_2024-04-06_at_10.32.58_c65c9dc6.jpg'} className={'detailsBlock'} alt='welcomePage' />

      <a
        onClick={() => {
          setCurrentPage(3);
        }}
      >
        <img src={'https://res.cloudinary.com/bubblemania/image/upload/w_300/aurudu/11.png'} alt='button' className={'detailsNextButton'} />
      </a>
    </div>
  );
}

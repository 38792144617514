// routes
import { PATH_DASHBOARD } from './routes/paths';

export const MOBILE_APP_DOWNLOAD_URL = 'https://storage.googleapis.com/bubblemania/WEB_BETA/DriversAndTools/BubbleMania%20App.apk';
export const PRINTER_TOOL_DOWNLOAD_URL = 'https://storage.googleapis.com/bubblemania/WEB_BETA/DriversAndTools/PrinterTool.zip';
export const BARCODE_PRINTER_DRIVER_URL = 'https://storage.googleapis.com/bubblemania/WEB_BETA/DriversAndTools/Barcode%20Matching%20Software.zip';
export const THERMAL_PRINTER_DRIVER_DOWNLOAD_URL = 'https://storage.googleapis.com/bubblemania/WEB_BETA/DriversAndTools/80mm-Thermal-Receipt-Printer-Driver.zip';
// export const BASE_URL = 'https://bubblemelk.expergen.com/';
// export const BASE_URL = 'http://localhost:8080/';
export const BASE_URL = process.env?.REACT_APP_BASEURL ? process.env?.REACT_APP_BASEURL : 'https://api-bubblemania-beta.expergen.com/';
export const ENV = 'BETA';
export const BUSINESS_NAME = 'BubbleMania.lk';

export const COMPANY_DATA_FOR_PDF = {
  name: 'Bubble Mania (PVT) Ltd',
  address: '101, A5 New Kandy Rd, Kaduwela',
  phone: '0112 415 590',
};

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || 'https://minimal-assets-api.vercel.app';

export const FIREBASE_API = {
  apiKey: 'AIzaSyCqIp1AenqQ_abGSjuDGNMpt70ruLPi7-I',
  authDomain: 'bubblemania-prod.firebaseapp.com',
  projectId: 'bubblemania-prod',
  storageBucket: 'bubblemania-prod.appspot.com',
  messagingSenderId: '769435342032',
  appId: '1:769435342032:web:6a972573ee609abacfbad5',
  measurementId: 'G-WW5CHEJ6DJ',
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.sales.list;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'orange',
  themeLayout: 'vertical',
  themeStretch: true,
};

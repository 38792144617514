import React, { useState, useEffect } from 'react';
import { Box, Card, Container, Stack, TextField, Stepper, Step, StepLabel, Button, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import { clearStockBreakerData, getFromBreakerDetails, getStocks, updateStockBreaking } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import { useSelector } from '../../../../redux/store';
import { safeJSONParse } from '../../../../utils/common';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { notifyBossAboutStockTransferMistake } from '../../../../redux/slices/stockManagementRedux/stockTransferRedux';

const steps = ['Enter From Barcode', 'Enter To Barcode'];
const useStyles = makeStyles({
  card: {
    margin: '24px',
    padding: '24px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  info: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  label: {
    fontWeight: 'bold',
  },
});
const WasteManager = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const [activeStep, setActiveStep] = useState(0);

  const [data, setData] = useState({ fromBarcode: '', fromBarcodeData: {}, toBarcode: '', toBarcodeQty: '' });
  const { stockBreakerData, processStockBreaking } = useSelector((state) => state?.stock);
  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  let loggedInUser;
  ({ loggedInUser } = useSelector((state) => state.user));

  useEffect(() => {
    handleReset();
  }, []);

  const handleReset = () => {
    setActiveStep(0);
    setData({ fromBarcode: '', fromBarcodeData: {}, toBarcode: generateUniqueId(), toBarcodeQty: '' });
  };

  const generateUniqueId = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return `Waste_${data?.fromBarcode}-${result}`;
  };

  const handleFromBarcodeEnter = (event, type) => {
    if (event.key === 'Enter') {
      event.target.select();
      const value = event.target.value;
      setData({ ...data, fromBarcode: value, toBarcode: generateUniqueId() });
      dispatch(getFromBreakerDetails(value));
    }
  };
  const handleQty = (event, type) => {
    const value = event.target.value;
    setData({ ...data, [type]: value });
  };
  const handleToBarcodeEnter = (event, type) => {
    if (event.key === 'Enter') {
      event.target.select();
      const value = event.target.value;
      setData({ ...data, [type]: value, toBarcode: generateUniqueId() });
      dispatch(updateStockBreaking(data));
    }
  };

  useEffect(() => {
    if (data.fromBarcode?.length > 0) {
      dispatch(getStocks(-1, -1, -1, data.fromBarcode));
    }
  }, [data.fromBarcode, dispatch]);

  useEffect(() => {
    setData({ ...data, toBarcode: generateUniqueId() });
  }, [data.fromBarcode]);

  useEffect(() => {
    if (stockBreakerData?.length > 0) {
      const barcodeData = stockBreakerData?.[0];
      const stockLocation = safeJSONParse(barcodeData?.locationInfo);
      const sameLocation = stockLocation?.locationId === selectedLocation?.id;
      if (sameLocation) {
        setActiveStep(1);
        setData({
          ...data,
          fromBarcode: stockBreakerData?.[0]?.stockCode,
          fromBarcodeData: {
            ...barcodeData,
            foodInfo: safeJSONParse(barcodeData?.foodInfo),
            foodSupplierInfo: safeJSONParse(barcodeData?.foodSupplierInfo),
            locationInfo: safeJSONParse(barcodeData?.locationInfo),
            supplierInfo: safeJSONParse(barcodeData?.supplierInfo),
            unitInfo: safeJSONParse(barcodeData?.unitInfo),
          },
        });
        dispatch(clearStockBreakerData());
      } else {
        if (data?.fromBarcode?.length > 0) {
          setData({ ...data, fromBarcode: '', fromBarcodeData: {} });
          toast.error('Please select the same location barcode');
          dispatch(
            notifyBossAboutStockTransferMistake({
              code: barcodeData?.stockCode,
              locationId: selectedLocation?.id,
              tag: 'wasteStockManager',
              message: `[Detected in Waste Manager]\n\nBarcode: ${barcodeData?.stockCode}\nLocation: ${selectedLocation?.name}\nScanned By: ${loggedInUser?.name}`,
            })
          );
        }
      }
    }
  }, [stockBreakerData]);

  useEffect(() => {
    if (processStockBreaking?.length > 0) {
      const processStockData = processStockBreaking?.[0];
      setData({
        ...data,
        fromBarcodeData: {
          ...data?.fromBarcodeData,
          currentQty: processStockData?.currentQty,
        },
      });
    }
  }, [processStockBreaking]);

  return (
    <Page title='Waste Manager'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Waste Manager'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.transfer.sender }, { name: 'Waste Manager' }]}
          action={[]}
        />
        <Card sx={{ p: 3 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ p: 3 }}>
            {activeStep === 0 && (
              <TextField
                autoFocus
                margin='normal'
                label='From Barcode'
                fullWidth
                onClick={(event, type) => {
                  event.target.select();
                }}
                onKeyDown={(event) => handleFromBarcodeEnter(event, 'fromBarcode')}
              />
            )}
            {activeStep === 1 && (
              <Stack spacing={2}>
                <Card className={classes.card}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className={classes.info}>
                        <span className={classes.label}>Code:</span> {data?.fromBarcodeData?.stockCode}
                      </Typography>
                      <Typography className={classes.info}>
                        <span className={classes.label}>Food Name:</span> {data?.fromBarcodeData?.foodInfo?.foodName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography className={classes.info}>
                        <span className={classes.label}>Current QTY:</span> {data?.fromBarcodeData?.currentQty}
                      </Typography>
                      <Typography className={classes.info}>
                        <span className={classes.label}>Initial QTY:</span> {data?.fromBarcodeData?.initialQty}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
                <TextField autoFocus margin='normal' label='QTY' fullWidth onChange={(event) => handleQty(event, 'toBarcodeQty')} />
                <TextField
                  margin='normal'
                  label='To Barcode'
                  value={data?.toBarcode}
                  fullWidth
                  onChange={(event) => handleQty(event, 'toBarcode')}
                  onKeyDown={(event) => handleToBarcodeEnter(event, 'toBarcode')}
                />
              </Stack>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color='inherit' disabled={activeStep === 0} onClick={handleReset} sx={{ mr: 1 }}>
              Reset
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {/*{activeStep !== 1 && <Button onClick={handleNext}>Next</Button>}*/}
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default WasteManager;

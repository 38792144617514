import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, Container, Grid, IconButton, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import DataGridTable from '../../../components/table/DataGridTable';
import { FormProvider } from '../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import { generateSalary, getSalary, updateSalary } from '../../../redux/slices/hrRedux/UserManagementRedux/salaryRedux';
import Label from '../../../components/Label';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { accessVerify, safeJSONParse } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function SalaryManagement() {
  const dataModel = {
    month: null,
  };
  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [salaryList, setSalaryList] = useState([]);
  const [isStatusChangeActive, setIsStateChangeActive] = useState(true);
  const [rowSelection, setRowSelection] = useState(false);
  const [monthDate, setMonthDate] = useState(moment().format('YYYY-MM-DD'));
  const { data, addData } = useSelector((state) => state.salary);

  useEffect(() => {
    dispatch(getSalary(monthDate));
  }, [monthDate]);

  useEffect(() => {
    setSalaryList(
      data?.map((value) => {
        const data = safeJSONParse(value?.data);
        return {
          id: value?.id,
          name: data?.username || '-',
          email: data?.email || '-',
          basicSalary: data?.basicSalary?.amount || 0,
          overtimePayment: data?.overtimePayment?.amount || 0,
          roastDeduction: data?.roastDeduction?.amount || 0,
          saleBonus: data?.saleBonus?.amount || 0,
          timeAllowance: data?.timeAllowance?.amount || 0,
          inspectionAllowance: data?.inspectionAllowance?.amount || 0,
          epfDeduction: data?.epfDeduction?.employeeContribution || 0,
          totalSalary: data?.totalSalary || 0,
          status: value?.status,
          token: value?.token,
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedObject(dataModel);
      } else {
        setSelectedObject(modelData);
      }
    }
  };

  const NewProductSchema = Yup.object().shape({
    month: Yup.string().required('Month is required'),
  });

  const defaultValues = useMemo(
    () => ({
      month: moment(selectedObject?.month, 'YYYY-MM-DD') || null,
    }),
    [selectedObject]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isAdd && selectedObject) {
      reset(defaultValues);
    }
    if (!isAdd) {
      reset(defaultValues);
    }
  }, [isAdd, selectedObject]);

  const onSubmit = async (value) => {
    try {
      const tempDataObj = {
        month: moment(value?.month).format('YYYY-MM-01') || null,
      };
      if (isAdd) {
        dispatch(generateSalary(tempDataObj));
      } else {
        dispatch(updateSalary({ ...tempDataObj, id: selectedObject?.id }));
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getSalary(monthDate));
      setIsStateChangeActive(true);
    }
  }, [addData]);

  const columnList = [
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'email',
      header: 'Email',
    },
    {
      accessorKey: 'basicSalary',
      header: 'Basic Salary',
    },
    {
      accessorKey: 'overtimePayment',
      header: 'Overtime Payment',
    },
    {
      accessorKey: 'roastDeduction',
      header: 'Roast Deduction',
    },
    {
      accessorKey: 'inspectionAllowance',
      header: 'Inspection Allowance',
    },
    {
      accessorKey: 'saleBonus',
      header: 'Sale Bonus',
    },
    {
      accessorKey: 'timeAllowance',
      header: 'Time Allowance',
    },
    {
      accessorKey: 'epfDeduction',
      header: 'EPF Deduction',
    },
    {
      accessorKey: 'totalSalary',
      header: 'Total Salary',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell, row }) => (
        <Label
          color={cell.getValue() === 'approved' ? 'success' : 'warning'}
          sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
          onClick={() => {
            const data = row?.original;
            if (isStatusChangeActive) {
              dispatch(
                updateSalary({
                  id: data?.id,
                  status: data?.status === 'approved' ? 'pending' : 'approved',
                })
              );
              setIsStateChangeActive(false);
            }
          }}
        >
          {cell.getValue()}
        </Label>
      ),
    },
  ];

  return (
    <Page title='Salary List'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Salary List'
          links={[
            { name: 'Dashboard', href: '' },
            {
              name: 'Settings',
              href: PATH_DASHBOARD.settings.root,
            },
            { name: 'Salary List' },
          ]}
          action={
            <>
              <DatePicker
                label='Start Date'
                value={monthDate}
                onChange={(value) => setMonthDate(moment(value).format('YYYY-MM-01'))}
                onAccept={(value) => setMonthDate(moment(value).format('YYYY-MM-01'))}
                views={['year', 'month']}
                renderInput={(props) => <TextField {...props} style={{ marginRight: '10px' }} />}
              />
              <Button
                variant='contained'
                startIcon={<Iconify icon='eva:plus-fill' />}
                onClick={() => {
                  manageModel(dataModel, 'add');
                }}
              >
                Generate Salary
              </Button>
            </>
          }
        />

        {accessVerify('SALARY_VIEW') ? (
          <DataGridTable
            name={'Salary'}
            data={salaryList}
            column={columnList}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={true}
            renderRowActionItems={(value, closeMenu) => [
              <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                <IconButton color='primary' onClick={() => window.open('/pay/sheet/' + value?.original?.token, '_blank')}>
                  <VisibilityIcon />
                </IconButton>
              </Box>,
            ]}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>Generate Salary</h1>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Select The Month (Select any date)
                    </Typography>
                    <Stack spacing={3}>
                      <Controller
                        name='month'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <DatePicker
                            label='Month Picker'
                            value={field.value}
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
                            views={['year', 'month']}
                          />
                        )}
                      />
                    </Stack>
                  </Card>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' loading={isSubmitting} style={{ width: '100%' }}>
                      Generate Salary
                    </LoadingButton>
                    <Button color='info' variant='outlined' size='large' loading={isSubmitting} style={{ width: '100%' }} onClick={() => manageModel(dataModel, 'add')}>
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

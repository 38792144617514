import React, { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize } from '../../../../utils/common';
import { addInspectionType, getInspectionType, updateInspectionType } from '../../../../redux/slices/hrRedux/inspection/inspectionTypeRedux';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import PermissionRequired from '../../../errorPages/permissionRequired';

export default function InspectionTypeList() {
  const dataModel = {
    title: '',
    description: '',
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [inspectionTypeList, setInspectionTypeList] = useState([]);

  let inspectionTypeData;
  let inspectionTypeAddData;
  ({ data: inspectionTypeData, addData: inspectionTypeAddData } = useSelector((state) => state.inspectionType));

  useEffect(() => {
    dispatch(getInspectionType());
  }, []);

  useEffect(() => {
    setInspectionTypeList(inspectionTypeData);
    setIsModelOpen(false);
  }, [inspectionTypeData]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (inspectionTypeAddData?.data) {
      dispatch(getInspectionType());
    }
  }, [inspectionTypeAddData]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !inspectionTypeList?.[0]
    ? []
    : Object.keys(inspectionTypeList?.[0])
        .map((value) => {
          if ([].includes(value)) {
            return {};
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.title.length === 0) {
      toast.error('Inspection name is required!');
    } else if (selectedDataObj.description.length === 0) {
      toast.error('Inspection description is required!');
    } else {
      dispatch(isAdd ? addInspectionType(selectedDataObj) : updateInspectionType(selectedDataObj));
    }
  };
  return (
    <Page title={`Inspection Type List`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Inspection Type List`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Inspection Type',
              href: '',
            },
            { name: `Inspection Type List` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Inspection Type
            </Button>
          }
        />

        {accessVerify('INSPECTION_TYPE_VIEW') ? (
          <DataGridTable
            name={'Inspection Type List'}
            data={inspectionTypeList}
            column={columns}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Inspection Type</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Inspection Type Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Title' value={selectedDataObj?.title} onChange={(e) => updateEditingData('title', e.target.value)} />
                    <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                      {isAdd ? 'Add Inspection Type' : 'Save Changes'}
                    </LoadingButton>
                    <Button
                      color='info'
                      variant='outlined'
                      size='large'
                      style={{ width: '100%' }}
                      onClick={() => {
                        manageModel(dataModel, 'add');
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

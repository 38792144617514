import React from 'react';
import { SwipeableDrawer } from '@mui/material';
import POS from '../../../POS';
import { hidePOSTab, viewPOSTab } from '../../../../../../redux/slices/posRedux/posRedux';
import { useSelector, dispatch } from '../../../../../../redux/store';

export default function PosDrawer() {
  const { viewPosStatus } = useSelector((state) => state?.pos);
  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={viewPosStatus}
      onOpen={() => {
        dispatch(viewPOSTab());
      }}
      onClose={() => {
        dispatch(hidePOSTab());
      }}
      PaperProps={{
        sx: {
          width: '98%',
          height: '98%',
          marginLeft: '1%',
          marginTop: '1%',
          borderRadius: '10px',
        },
      }}
    >
      <POS isDrawer={true} />
    </SwipeableDrawer>
  );
}

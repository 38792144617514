// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function WalkingCustomersIcon({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box
      sx={{
        width: 120,
        height: 120,
        lineHeight: 0,
        borderRadius: '50%',
        bgcolor: 'background.neutral',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...other}
    >
      <svg xmlns='http://www.w3.org/2000/svg' className='svg-icon' style={{ width: '60%', height: '60%', verticalAlign: 'middle' }} fill={PRIMARY_MAIN} overflow='hidden' viewBox='0 0 1024 1024'>
        <path d='M607.86 191.972c52.992 0 95.986-42.994 95.986-95.986S660.852 0 607.86 0s-95.986 42.994-95.986 95.986 42.994 95.986 95.986 95.986zm188.972 298.156l-46.593-23.596-19.397-58.792c-29.396-89.187-111.384-151.578-204.37-151.777-71.99-.2-111.784 20.197-186.573 50.392-43.193 17.398-78.588 50.393-99.385 92.387l-13.398 27.196c-15.598 31.595-3 69.99 28.396 85.787 31.195 15.798 69.19 3 84.987-28.596l13.398-27.196c7-13.998 18.597-24.996 32.995-30.795l53.593-21.597-30.396 121.382c-10.398 41.594.8 85.788 29.796 117.583l119.782 130.78c14.398 15.798 24.596 34.796 29.796 55.393l36.594 146.578c8.6 34.195 43.394 55.192 77.589 46.593 34.195-8.598 55.192-43.393 46.593-77.588l-44.393-177.974c-5.2-20.597-15.398-39.794-29.796-55.392l-90.987-99.386 34.395-137.38 10.999 32.996c10.598 32.195 33.395 58.791 63.39 73.989l46.594 23.596c31.195 15.798 69.19 3 84.987-28.595 15.398-31.396 2.8-70.19-28.596-85.988zM339.1 771.487c-6.399 16.198-15.997 30.795-28.395 42.994l-99.986 100.185c-24.996 24.996-24.996 65.59 0 90.587s65.39 24.996 90.387 0L419.888 886.47c12.198-12.198 21.796-26.796 28.395-42.994l26.996-67.59c-110.583-120.582-77.388-83.587-94.786-107.384L339.1 771.487z'></path>
      </svg>
    </Box>
  );
}

import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { fCurrency, fCurrencyWithoutSymbol } from '../../../../../../utils/formatNumber';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

export default function PosCart({ data, setData, onClickSound, onChangeTab }) {
  const tableHeaderStyle = {
    background: '#f2f2f2',
    padding: '10px',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
  };

  const tableFooterStyle = {
    background: '#f3fff3',
    padding: '10px',
    borderTop: '1px solid #ddd',
    zIndex: 1000,
  };

  const tableRowStyle = {
    borderBottom: '1px solid #ddd',
  };

  const tableCellStyle = {
    padding: '10px',
    textAlign: 'center',
  };

  const handleRemove = (cartItemId) => {
    onClickSound();
    setData((value) => {
      const updatedCart = value.orderInfo.cart.filter((item) => item.id !== cartItemId);
      return {
        ...value,
        orderInfo: { cart: updatedCart },
      };
    });
  };

  const handleIncrement = (cartItemId) => {
    onClickSound();
    setData((value) => {
      const updatedCart = value.orderInfo.cart.map((cartItem) =>
        cartItem.id === cartItemId
          ? {
              ...cartItem,
              quantity: cartItem.quantity + 1,
            }
          : cartItem
      );

      return {
        ...value,
        orderInfo: { cart: updatedCart },
      };
    });
  };

  const handleDecrement = (cartItemId) => {
    onClickSound();
    setData((value) => {
      const updatedCart = value.orderInfo.cart
        .map((cartItem) =>
          cartItem.id === cartItemId
            ? {
                ...cartItem,
                quantity: Math.max(cartItem.quantity - 1, 0),
              }
            : cartItem
        )
        .filter((cartItem) => cartItem.quantity > 0);

      return {
        ...value,
        orderInfo: { cart: updatedCart },
      };
    });
  };

  const totalQty = data?.orderInfo?.cart?.reduce((sum, cartItem) => sum + cartItem.quantity, 0);
  const totalAmount = data?.orderInfo?.cart?.reduce((sum, cartItem) => {
    const pricingData = cartItem?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id);
    return sum + pricingData?.pricing * cartItem?.quantity;
  }, 0);

  return (
    <div>
      <div style={{ height: '50vh', overflowY: 'auto', marginTop: '-20px' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>#</th>
              <th style={tableHeaderStyle}>Name</th>
              <th style={tableHeaderStyle}>Qty</th>
              <th style={tableHeaderStyle}>Price</th>
              <th style={tableHeaderStyle}>Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.orderInfo?.cart?.map((cartItem) => {
              const pricingData = cartItem?.pricingData?.find((pricingDataIndex) => pricingDataIndex?.saleTypeId === data?.deliveryInfo?.id);

              return (
                <tr key={cartItem?.id} style={tableRowStyle}>
                  <td>
                    <IconButton color='error' onClick={() => handleRemove(cartItem?.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </td>
                  <td style={tableCellStyle}>{cartItem?.name}</td>
                  <td style={{ ...tableCellStyle, minWidth: '110px' }}>
                    <IconButton color='error' onClick={() => handleDecrement(cartItem?.id)}>
                      <RemoveIcon />
                    </IconButton>
                    {cartItem?.quantity}
                    <IconButton color='success' onClick={() => handleIncrement(cartItem?.id)}>
                      <AddIcon />
                    </IconButton>
                  </td>
                  <td style={tableCellStyle}>{fCurrencyWithoutSymbol(pricingData?.pricing)}</td>
                  <td style={tableCellStyle}>{fCurrencyWithoutSymbol(pricingData?.pricing * cartItem?.quantity)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
        <tfoot>
          <tr>
            <th style={tableFooterStyle}></th>
            <th style={tableFooterStyle}></th>
            <th style={tableFooterStyle}>
              <span style={{ fontSize: '12px', color: 'darkgray' }}>Total QTY:</span> <span style={{ fontSize: '18px' }}>{totalQty}</span>
            </th>
            <th style={tableFooterStyle}></th>
            <th style={tableFooterStyle}>
              <span style={{ fontSize: '12px', color: 'darkgray' }}>Total Amount:</span> <span style={{ fontSize: '18px' }}>{fCurrency(totalAmount)}</span>
            </th>
          </tr>
        </tfoot>
      </table>
      <TextField
        style={{ width: '100%', marginTop: '20px' }}
        id='Table Number'
        label='Table Number'
        type={'number'}
        value={data?.orderInfo?.tableNumber}
        onClick={(e) => e.target.select()}
        onChange={(e) => {
          setData((value) => {
            return {
              ...value,
              orderInfo: { ...value.orderInfo, tableNumber: e.target.value },
            };
          });
        }}
      />
      <TextField
        style={{ width: '100%', marginTop: '20px' }}
        id='Order Note'
        label='Special Notes For Order'
        multiline
        rows={2}
        value={data?.orderInfo?.specialNote}
        onChange={(e) => {
          setData((value) => {
            return {
              ...value,
              orderInfo: { ...value.orderInfo, specialNote: e.target.value },
            };
          });
        }}
      />
      <LoadingButton
        onClick={() => {
          onClickSound();
          if (data?.orderInfo?.cart?.length > 0) {
            onChangeTab('customer_info');
          } else {
            toast.warning('Least 1 item required!');
          }
        }}
        variant='contained'
        size='large'
        style={{ width: '100%', marginTop: '20px' }}
      >
        Continue
      </LoadingButton>
    </div>
  );
}

import Label from '../../../../../components/Label';
import React, { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../../../../redux/store';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import AccountActivationDocumentStatus from './accountActivationDocumentStatus';
import { getSpecificUserData, updateUserState } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';

export default function AccountActivation({ userId }) {
  const [selectedUserData, setSelectedUserData] = useState({});
  const [documentTypeListWithStatus, setDocumentTypeListWithStatus] = useState([]);
  const { selectedUser, documentTypes, documents, addData } = useSelector((state) => state?.user);

  useEffect(() => {
    setSelectedUserData(selectedUser?.[0]);
  }, [selectedUser]);

  useEffect(() => {
    if (addData?.data?.affectedRows > 0) {
      dispatch(getSpecificUserData(userId));
    }
  }, [addData]);

  useEffect(() => {
    if (userId) dispatch(getSpecificUserData(userId));
  }, [userId]);

  useEffect(() => {
    setDocumentTypeListWithStatus(
      documentTypes?.map((value) => {
        return {
          ...value,
          status: Boolean(
            documents?.filter((value1) => {
              return value1?.documentTypeId === value?.id && value1?.status === 'approved';
            })?.[0]
          ),
        };
      })
    );
  }, [documentTypes]);

  const updateStatus = (status) => {
    const data = {
      id: userId,
      status: status,
    };
    dispatch(updateUserState(data));
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8}>
        <Stack spacing={3}>
          <Card sx={{ p: 3 }}>
            <Typography variant='overline' sx={{ mb: 3, display: 'block', color: 'text.secondary' }}>
              Your account activation status
            </Typography>

            <Label color={selectedUserData?.status === 'active' ? 'success' : selectedUserData?.status === 'deleted' ? 'error' : 'warning'} sx={{ textTransform: 'capitalize', padding: '5px 10px' }}>
              <Typography variant='h4' sx={{ display: 'block', margin: '10px' }}>
                {selectedUserData?.status}
              </Typography>
            </Label>
            <Box
              sx={{
                mt: { xs: 2, sm: 0 },
                position: { sm: 'absolute' },
                top: { sm: 24 },
                right: { sm: 24 },
              }}
            >
              <Button size='small' color='inherit' variant='outlined' sx={{ mr: 1 }} disabled={selectedUserData?.status !== 'active'} onClick={() => updateStatus('pending')}>
                Keep Pending
              </Button>
              <Button
                size='small'
                color='inherit'
                variant='outlined'
                sx={{ mr: 1 }}
                onClick={() => {
                  if (confirm("Are you sure you want to delete this user's account? There is no restoration option after deletion.")) {
                    updateStatus('deleted');
                  }
                }}
              >
                Delete User
              </Button>
              <Button
                size='small'
                variant='outlined'
                disabled={documentTypeListWithStatus?.filter((value) => value?.required === 'true')?.filter((value) => !value?.status)?.length > 0}
                onClick={() => updateStatus('active')}
              >
                Activate The Account
              </Button>
            </Box>
          </Card>
        </Stack>
      </Grid>

      <Grid item xs={12} md={4}>
        <AccountActivationDocumentStatus documentTypeListWithStatus={documentTypeListWithStatus} />
      </Grid>
    </Grid>
  );
}

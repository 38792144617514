import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../../store';
import { deleteMethod, get, post, put } from '../../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  loggedInRost: [],
  selectedRost: [],
  currentRost: [],
  documentTypes: [],
  documents: [],
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'Rost',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },
    getRostSuccess(state, action) {
      state.data = action.payload.data;
    },
    updateCurrentLoggedInRostData(state, action) {
      state.loggedInRost = action.payload;
    },
    getCurrentRostSuccess(state, action) {
      state.currentRost = action.payload.data;
      state.documentTypes = action.payload.documentTypes;
      state.documents = action.payload.documents;
    },
    getSpecificRostDataSuccess(state, action) {
      state.selectedRost = action.payload.data;
      state.documentTypes = action.payload.documentTypes;
      state.documents = action.payload.documents;
    },
    addOrUpdateRosts(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    clearAll(state) {
      state.addData = {};
      state.error = {};
    },
    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },
    onNextStep(state) {
      state.checkout.activeStep += 1;
    },
    onGotoStep(state, action) {
      state.checkout.activeStep = action.payload;
    },
  },
});

export default slice.reducer;
export const { filterCategories } = slice.actions;

export function getRost(locationId, monthDate) {
  return async () => {
    try {
      const response = await get(`rost?locationId=${locationId}&monthDate=${monthDate}`);
      if (response.status) {
        dispatch(slice.actions.getRostSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRostById(userId) {
  return async () => {
    try {
      const response = await get(`rostById?userId=${userId}`);
      if (response.status) {
        dispatch(slice.actions.getRostSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addRost(body) {
  return async () => {
    try {
      const response = await post(`rost`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateRosts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rostUserConfirmation(body) {
  return async () => {
    try {
      const response = await put(`rostUserConfirmation`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateRosts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rostHRConfirmation(body) {
  return async () => {
    try {
      const response = await put(`rostHRConfirmation`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateRosts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearRostHRConfirmation(body) {
  return async () => {
    try {
      const response = await put(`clearRostHRConfirmation`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateRosts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateRost(body) {
  return async () => {
    try {
      const response = await put(`rost`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateRosts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRost(body) {
  return async () => {
    try {
      const response = await deleteMethod(`rost`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateRosts(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#fdd6f2',
    light: '#f485d1',
    main: '#dc356f',
    dark: '#9e1a5c',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  {
    name: 'green',
    lighter: '#C6FACB',
    light: '#76E89F',
    main: '#2ECC71',
    dark: '#189F57',
    darker: '#0B5831',
    contrastText: '#fff',
  },
  // YELLOW
  {
    name: 'yellow',
    lighter: '#FFF3C2',
    light: '#FFD966',
    main: '#FFC300',
    dark: '#CC9E00',
    darker: '#806600',
    contrastText: '#fff',
  },
  // PINK
  {
    name: 'pink',
    lighter: '#FFD9E5',
    light: '#FF7BA7',
    main: '#FF2975',
    dark: '#B7154C',
    darker: '#7A073C',
    contrastText: '#fff',
  },
  // TEAL
  {
    name: 'teal',
    lighter: '#B3FAF8',
    light: '#4EF1E1',
    main: '#00B5A4',
    dark: '#008774',
    darker: '#004C42',
    contrastText: '#fff',
  },
  // INDIGO
  {
    name: 'indigo',
    lighter: '#C9DAF8',
    light: '#6381E8',
    main: '#364FC7',
    dark: '#1A288E',
    darker: '#0B145F',
    contrastText: '#fff',
  },
  // YELLOW-GREEN
  {
    name: 'yellowGreen',
    lighter: '#EAFAC5',
    light: '#BDF079',
    main: '#7CC242',
    dark: '#559B1A',
    darker: '#2D6E00',
    contrastText: '#fff',
  },
  // LILAC
  {
    name: 'lilac',
    lighter: '#F5E4FD',
    light: '#D69CFF',
    main: '#A23BEE',
    dark: '#7710B3',
    darker: '#4B0082',
    contrastText: '#fff',
  },
  // TANGERINE
  {
    name: 'tangerine',
    lighter: '#FFE2B1',
    light: '#FFB95E',
    main: '#FF942B',
    dark: '#E37400',
    darker: '#995900',
    contrastText: '#fff',
  },
  // AQUA
  {
    name: 'aqua',
    lighter: '#B4F6F0',
    light: '#63E1D2',
    main: '#0EB5A5',
    dark: '#007F7A',
    darker: '#004E49',
    contrastText: '#fff',
  },
  // MAROON
  {
    name: 'maroon',
    lighter: '#E7B6B6',
    light: '#D57F7F',
    main: '#B53636',
    dark: '#8E1818',
    darker: '#5A0000',
    contrastText: '#fff',
  },
  // Grey
  {
    name: 'grey',
    lighter: '#F4F4F4',
    light: '#D6D6D6',
    main: '#9E9E9E',
    dark: '#707070',
    darker: '#424242',
    contrastText: '#000',
  },
  // BROWN
  {
    name: 'brown',
    lighter: '#D7CCC8',
    light: '#A1887F',
    main: '#795548',
    dark: '#5D4037',
    darker: '#3E2723',
    contrastText: '#fff',
  },
];

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];
export const greenPreset = colorPresets[6];
export const yellowPreset = colorPresets[7];
export const pinkPreset = colorPresets[8];
export const tealPreset = colorPresets[9];
export const indigoPreset = colorPresets[10];
export const yellowGreenPreset = colorPresets[11];
export const lilacPreset = colorPresets[12];
export const tangerinePreset = colorPresets[13];
export const aquaPreset = colorPresets[14];
export const maroonPreset = colorPresets[15];
export const greyPreset = colorPresets[16];
export const brownPreset = colorPresets[17];

export default function getColorPresets(presetsKey) {
  return {
    purple: purplePreset,
    cyan: cyanPreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    green: greenPreset,
    yellow: yellowPreset,
    pink: pinkPreset,
    teal: tealPreset,
    indigo: indigoPreset,
    yellowGreen: yellowGreenPreset,
    lilac: lilacPreset,
    tangerine: tangerinePreset,
    aqua: aquaPreset,
    maroon: maroonPreset,
    grey: greyPreset,
    brown: brownPreset,
    default: defaultPreset,
  }[presetsKey];
}

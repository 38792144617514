import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Container,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { getUsers } from '../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import moment from 'moment/moment';
import { DatePicker, LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import {
  addRost,
  clearRostHRConfirmation,
  getRost,
  rostHRConfirmation,
  rostUserConfirmation,
} from '../../../redux/slices/hrRedux/UserManagementRedux/userRostManagement';
import { accessVerify, utcMoment } from '../../../utils/common';

export default function Rost() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const today = new Date();

  let usersListData;
  ({ data: usersListData } = useSelector((state) => state.user));

  let rostData;
  let rostAddData;
  ({ data: rostData, addData: rostAddData } = useSelector((state) => state.userRostManagement));

  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));
  const [dateRangeList, setDateRangeList] = useState([]);
  const [dateRange, setDateRange] = useState([moment(today).startOf('month'), moment(today).endOf('month')]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    const userTimeDataTemp = [];
    rostData.forEach((user) => {
      const userIndex = userTimeDataTemp.findIndex((value) => value.userId === user?.userId);
      if (userIndex !== -1) {
        userTimeDataTemp[userIndex].dates.push(standardDate(utcMoment(user?.date)));
        if (user?.confimedUserId !== -1) {
          userTimeDataTemp[userIndex].confirmedDates.push(standardDate(utcMoment(user?.date)));
        }
      } else {
        userTimeDataTemp.push({
          userId: user?.userId,
          dates: [standardDate(utcMoment(user?.date))],
          confirmedDates: user?.confimedUserId === -1 ? [] : [standardDate(utcMoment(user?.date))],
        });
      }
    });
    setUserTimeData(userTimeDataTemp);
  }, [rostData]);

  const standardDate = (date) => {
    return date.format('YYYY-MM-DD');
  };
  useEffect(() => {
    dispatch(getRost(selectedLocation?.id, standardDate(dateRange[0])));
  }, [selectedLocation?.id, dateRange, rostAddData]);

  const [userTimeData, setUserTimeData] = useState([]);

  const handleChange = (event) => {
    const selectedUserListTemp = event?.target?.value || [];
    setUserTimeData((prevState) => {
      const addedArray = selectedUserListTemp.filter((element) => !prevState.map((user) => user.userId).includes(element));
      const removedArray = prevState.filter((user) => !selectedUserListTemp.includes(user.userId));
      const updatedArray = addedArray.map((userId) => ({
        userId: userId,
        dates: [],
        confirmedDates: [],
      }));
      return [...prevState, ...updatedArray].filter((user) => !removedArray.map((user) => user.userId).includes(user.userId));
    });
  };

  const getDatesInRange = (startDate, endDate) => {
    const datesArray = [];
    let currentDate = moment(startDate);
    while (currentDate <= endDate) {
      datesArray.push(moment(currentDate));
      currentDate = moment(currentDate).add(1, 'days');
    }
    return datesArray;
  };

  useEffect(() => {
    setUserTimeData([]);
    setDateRangeList(getDatesInRange(dateRange[0], dateRange[1]));
  }, [dateRange]);

  const handleDateClick = (date, userId) => {
    setUserTimeData((prevState) => {
      return prevState.map((user) => {
        if (user.userId === userId) {
          const isActive = user.dates.includes(standardDate(date));
          if (isActive) {
            user.dates = user.dates.filter((d) => d !== standardDate(date));
            user.confirmedDates = [];
          } else {
            user.dates.push(standardDate(date));
          }
        }
        return user;
      });
    });
  };

  return (
    <Page title={`Rost Manager`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs heading={`Rost Manager`} links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Rost', href: '' }, { name: `Rost Manager` }]} action={[]} />
        <Accordion defaultExpanded slots={{ transition: Fade }} slotProps={{ transition: { timeout: 400 } }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Rost Configurations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label='Rost Month'
                    value={dateRange[0]}
                    onChange={(newValue) => {
                      setDateRange([moment(newValue).startOf('month'), moment(newValue).endOf('month')]);
                    }}
                    views={['year', 'month']}
                    openTo='month'
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </Grid>

              {selectedLocation?.id === 1 ? (
                <></>
              ) : (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id='user-selector-label'>User List</InputLabel>
                      <Select
                        labelId='user-selector-label'
                        id='user-selector'
                        multiple
                        value={userTimeData.map((user) => user.userId)}
                        onChange={handleChange}
                        input={<OutlinedInput id='user-selector' label='Chip' />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {selected.map((value) => {
                              const data = usersListData.find((user) => user.id === value);
                              return <Chip key={value} label={data?.name} />;
                            })}
                          </Box>
                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: '40%',
                            },
                          },
                        }}
                      >
                        {usersListData.map((value) => (
                          <MenuItem
                            key={value?.id}
                            value={value?.id}
                            style={{
                              fontWeight: theme.typography.fontWeightMedium,
                            }}
                          >
                            {value?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    {accessVerify('ROST_MANAGER_ADD') && (
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        size='large'
                        color={'success'}
                        style={{ width: '100%', marginTop: '8px' }}
                        onClick={() => {
                          const verifySingleUserOrDate = userTimeData.find((value) => value?.dates?.length > 0);
                          if (verifySingleUserOrDate) {
                            dispatch(
                              addRost({
                                data: userTimeData,
                                dateRange: dateRange,
                                selectedLocation: selectedLocation,
                              })
                            );
                          } else {
                            toast.error('Please select at least one user and one date to save the rost');
                          }
                        }}
                      >
                        Save Rost As Supervisor
                      </LoadingButton>
                    )}
                  </Grid>

                  <Grid item xs={3}>
                    {accessVerify('GENERAL_DATA') && (
                      <LoadingButton
                        type='button'
                        variant='contained'
                        size='large'
                        color={'info'}
                        style={{ width: '100%', marginTop: '8px' }}
                        onClick={() => {
                          const verifySingleUserOrDate = userTimeData.find((value) => value?.dates?.length > 0);
                          if (verifySingleUserOrDate) {
                            dispatch(
                              rostUserConfirmation({
                                date: standardDate(dateRange[0]),
                                locationId: selectedLocation?.id,
                              })
                            );
                          } else {
                            toast.error('Your Data Needed to confirm this first!');
                          }
                        }}
                      >
                        Accept My Rost Dates
                      </LoadingButton>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {accessVerify('ROST_HR_MANAGER_CONFIRM') && (
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        size='large'
                        color={'error'}
                        style={{ width: '100%', marginTop: '8px' }}
                        onClick={() => {
                          const verifySingleUserOrDate = userTimeData.find((value) => value?.dates?.length > 0);
                          if (verifySingleUserOrDate) {
                            dispatch(
                              rostHRConfirmation({
                                date: standardDate(dateRange[0]),
                                locationId: selectedLocation?.id,
                              })
                            );
                          } else {
                            toast.error('Please select at least one user and one date to save the rost');
                          }
                        }}
                      >
                        Confirm As HR
                      </LoadingButton>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {accessVerify('SUPER_ADMIN') && (
                      <LoadingButton
                        type='submit'
                        variant='contained'
                        size='large'
                        color={'warning'}
                        style={{ width: '100%', marginTop: '8px' }}
                        onClick={() => {
                          const verifySingleUserOrDate = userTimeData.find((value) => value?.dates?.length > 0);
                          if (verifySingleUserOrDate) {
                            dispatch(
                              clearRostHRConfirmation({
                                date: standardDate(dateRange[0]),
                                locationId: selectedLocation?.id,
                              })
                            );
                          } else {
                            toast.error('Please select at least one user and one date to save the rost');
                          }
                        }}
                      >
                        Clear HR Confirmation
                      </LoadingButton>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <TableContainer component={Paper} style={{ overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Users List</TableCell>
                {dateRangeList.map((date) => (
                  <TableCell align='right'>{date?.format('DD MMMM YYYY (dddd)')}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userTimeData.map((user) => (
                <TableRow key={user?.userId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {usersListData?.find((value) => value?.id === user?.userId)?.name}
                  </TableCell>
                  {dateRangeList.map((date) => {
                    const isActive = user?.dates?.includes(standardDate(date));
                    return (
                      <TableCell
                        align='right'
                        style={{
                          background: isActive ? '#02ff00' : '#ff0050',
                          border: `1px ${isActive ? 'black' : '#d1d1d1'} solid`,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleDateClick(date, user?.userId);
                        }}
                      >
                        <b>
                          <center>{isActive ? 'Working Day' : 'Holiday'}</center>
                        </b>
                        <b>{user?.confirmedDates?.includes(standardDate(date)) && '(Confirmed)'}</b>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}

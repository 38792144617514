import React, { useEffect, useRef, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Button, ButtonGroup } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { generateCsv, mkConfig } from 'export-to-csv';

const DataGridTable = ({
  name = '',
  data = [],
  column = [],
  isLoading = false,
  rowSelection = [],
  setRowSelection = () => {},
  renderRowActionItems = [],
  enablePinning = true,
  enableRowActions = false,
  enableRowSelection = true,
  isRowClickable: enableRowClick = false,
  maxHeight = `64vh`,
  rowsPerPage = 15,
  onRowClick = () => {},
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getRowSelection = () => {
    const columns = enablePinning ? ['mrt-row-pin'] : [];
    if (enableRowSelection) {
      columns.push('mrt-row-select');
    }
    if (enableRowActions) {
      columns.push('mrt-row-actions', 'mrt-row-expand');
    }
    return columns;
  };

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const [columnOrder, setColumnOrder] = useState(getRowSelection().concat(column.map((c) => c.accessorKey)));

  useEffect(() => {
    setColumnOrder(getRowSelection().concat(column.map((c) => c.accessorKey)));
  }, [column]);

  const handleExportRows = (rows, type) => {
    const rowData = rows.map((row) => {
      const exportedData = {};
      column.forEach((col) => {
        exportedData[col.accessorKey] = row.original[col.accessorKey];
      });
      return exportedData;
    });

    const csv = generateCsv(csvConfig)(rowData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${name} - ${type}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const columnVirtualizerInstanceRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);

  const table = useMaterialReactTable({
    columns: column,
    data,
    enableColumnOrdering: true,
    enablePinning: true,
    enableGrouping: true,
    isMenuOpen,
    setIsMenuOpen,
    enableRowSelection,
    defaultDisplayColumn: { enableResizing: true },
    enableBottomToolbar: true,
    enableGlobalFilterModes: true,
    enablePagination: true,
    enableColumnPinning: true,
    enableRowPinning: enablePinning,
    enableRowNumbers: true,
    enableColumnResizing: true,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 150 * renderRowActionItems.length,
      },
    },
    layoutMode: 'grid',
    muiTableContainerProps: { sx: { maxHeight: maxHeight } },
    enableColumnVirtualization: true,
    enableRowVirtualization: true,
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 5 },
    columnVirtualizerInstanceRef,
    columnVirtualizerOptions: { overscan: 4 },
    initialState: { density: 'compact', pagination: { pageSize: rowsPerPage } },
    muiPaginationProps: {
      rowsPerPageOptions: [rowsPerPage, 50, 100, 1000, 5000, 100000],
      showFirstButton: true,
      showLastButton: true,
    },
    paginationDisplayMode: 'pages',
    muiTableBodyProps: {
      sx: () => ({
        '& tr:nth-of-type(odd)': {
          backgroundColor: '#fafafc',
        },
      }),
    },
    muiTablePaperProps: {
      style: {
        zIndex: 9999999999,
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (enableRowClick) {
          onRowClick(row);
        }
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: enableRowClick ? 'pointer' : 'default',
      },
    }),
    onRowSelectionChange: setRowSelection,
    state: { columnOrder, rowSelection },
    onColumnOrderChange: setColumnOrder,
    enableRowActions,
    renderRowActions: ({ row }) => renderRowActionItems(row),
    renderTopToolbarCustomActions: ({ table }) => (
      <ButtonGroup aria-label='outlined button group'>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, 'Export All Data')}
          startIcon={<FileDownloadIcon />}
        >
          Export All
        </Button>
        <Button disabled={table.getRowModel().rows.length === 0} onClick={() => handleExportRows(table.getRowModel().rows, 'Export Page Data')} startIcon={<FileDownloadIcon />}>
          Export Page
        </Button>
        <Button
          disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
          onClick={() => handleExportRows(table.getSelectedRowModel().rows, 'Export Selected Data')}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected
        </Button>
      </ButtonGroup>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default DataGridTable;

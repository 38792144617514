// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function DinningIcon({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main; // Using primary main color for the icon fill

  return (
    <Box
      sx={{
        width: 120, // Adjust the size as necessary
        height: 120, // Adjust the size as necessary
        lineHeight: 0,
        borderRadius: '50%', // Adjust for circular shape or remove for a different shape
        bgcolor: 'background.neutral', // Adjust the background as necessary
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...other}
    >
      <svg xmlns='http://www.w3.org/2000/svg' className='svg-icon' style={{ width: '60%', height: '60%', verticalAlign: 'middle' }} fill={PRIMARY_MAIN} overflow='hidden' viewBox='0 0 1024 1024'>
        <path d='M238.485 276.711c-.032 0-.058.004-.089.005l-.083.082.172-.087zM236.28 277.965c-.27.562-.273 1.1-.273 1.1l2.306-2.267c-.81.395-1.487.792-2.032 1.167z'></path>
        <path d='M512.932 3.07C232.537 3.07 5.307 230.36 5.307 510.694c0 280.333 227.23 507.625 507.625 507.625 280.397 0 507.624-227.291 507.624-507.625 0-280.333-227.228-507.624-507.624-507.624zM199.323 312.775c4.896-4.834 12.207-4.834 17.103 0l110.051 110.424c2.479 2.354 12.269-2.479 14.624-4.834 4.957-4.833 9.79-14.375 7.373-16.854L238.485 293.566c-4.957-4.834-4.957-9.667-2.478-14.5-2.033 2.032-2.393.73.274-1.101.288-.598.886-1.218 2.115-1.249l12.235-12.027c4.957-4.709 12.27-4.709 17.164 0l109.99 108.07c2.478 4.833 12.27-2.355 17.164-4.834 2.416-2.355 4.895-12.022 2.416-14.376l-110.05-108.07c-4.835-4.708-4.835-12.02 0-16.73 4.956-4.833 12.269-4.833 17.163 0L424.26 344.006c19.581 19.21 22.06 52.795 14.687 76.714l39.1 40.898-43.996 43.253-41.516-40.898c-24.477 4.833-56.265 4.833-75.847-14.376L199.324 329.506c-4.896-4.71-4.896-12.021 0-16.731zM720.21 785.7L558.787 639.21l51.37-50.317 154.047 153.553c31.726 28.875-12.269 74.482-43.995 43.253zm100.26-480.113L363.161 778.51c-14.688 14.376-34.205 16.856-48.953 2.356-14.624-14.377 2.478-43.129 17.164-57.628l154.046-153.552c-14.686-14.5-26.893-36.065-12.208-50.441l102.678-100.88c53.848-52.795 107.635-115.257 144.318-136.822 24.414-16.855 68.41-36.064 85.512-33.585 9.79 2.355 7.374 4.71 12.268 12.022 4.836 2.355 7.313 2.355 9.79 7.188 4.838 11.898 2.422 28.753-7.307 38.419z'></path>
      </svg>
    </Box>
  );
}

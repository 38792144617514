import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import { SeverErrorIllustration } from '../../assets';
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function PaymentRequired() {
  const { selectedLocation } = useSelector((state) => state.location);

  return (
    <Page title='No Permission' sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant='h3' paragraph>
              {[1, 13].includes(selectedLocation?.id) ? 'Payments Required!' : 'Please call officials for support.'}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{[1, 13].includes(selectedLocation?.id) ? 'Opz... Waiting for the payments!' : 'Please call Officials for more data!'}</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img sx={{ height: 400 }} src={'https://res.cloudinary.com/dgkpzukqz/image/upload/v1721043800/bubble/springforward-sad.gif'} alt={'Cry emoji'} />
            </Box>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}

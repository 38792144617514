import React, { useEffect, useState } from 'react';
import { Container, TextField, Grid, Card } from '@mui/material';
import { dispatch, useSelector } from '../../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import useSettings from '../../../../../hooks/useSettings';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../../components/table/DataGridTable';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../../../utils/common';
import { getCashFlowMainCategories, getCashFlows, getCashFlowSubCategories } from '../../../../../redux/slices/financeRedux/cashFlowRedux';
import PermissionRequired from '../../../../errorPages/permissionRequired';
import { getPaymentTypes } from '../../../../../redux/slices/financeRedux/paymentTypeRedux';
import { fCurrency } from '../../../../../utils/formatNumber';
import { MobileDateRangePicker } from '@mui/lab';
import moment from 'moment';
import { getPurchaseNotes } from '../../../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import { getFoods } from '../../../../../redux/slices/stockManagementRedux/foodRedux';
import { ORDER_STATUS } from '../../../../../utils/constants';

export default function PurchaseLedger() {
  const { themeStretch } = useSettings();
  const title = 'Purchase Ledger';
  const [cashFlowList, setCashFlowList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [openPicker, setOpenPicker] = useState(false);

  let foodList;
  ({ data: foodList } = useSelector((state) => state.food));
  let tempPaymentTypeList;
  ({ data: tempPaymentTypeList } = useSelector((state) => state.paymentTypes));
  let locationList;
  let selectedLocation;
  ({ data: locationList, selectedLocation } = useSelector((state) => state.location));
  const { data, addData } = useSelector((state) => state.purchaseNotes);

  useEffect(() => {
    dispatch(getFoods('ingredient'));
    dispatch(getPaymentTypes());
    dispatch(getPurchaseNotes());
    dispatch(getCashFlowMainCategories());
    dispatch(getCashFlowSubCategories());
  }, []);

  useEffect(() => {
    setPaymentTypeList(tempPaymentTypeList);
  }, [tempPaymentTypeList]);

  useEffect(() => {
    if (!data) return;

    let startingAmount = 0;
    const filteredData = data
      .filter((value) => {
        const paymentDate = moment(utcMoment(value?.date).format('YYYY-MM-DD').toString());
        const [startDate, endDate] = dateRange.map((date) =>
          date ? utcMoment(date).utc().startOf('day') : null
        );
        const isInRange = startDate && endDate
          ? paymentDate.isBetween(startDate, endDate, null, '[]')
          : true;
        return  [ORDER_STATUS.PARTIALLY_PAID.code, ORDER_STATUS.PAID.code , ORDER_STATUS.RECEIVED.code ].includes(value?.status) && isInRange;
      })
      .sort((a, b) => (a.id || 0) - (b.id || 0));

    const updatedCashflowList = filteredData.map((value) => {
      startingAmount += value?.totalamount || 0;
      const extractedData = safeJSONParse(value?.supplierorder_foodsupplierpricing);
      return {
        date: utcMoment(value?.date).format('DD-MMMM-YYYY'),
        description: extractedData?.map(value=> foodList.find(food => food.id === value?.foodId)?.name)?.toString().replace(',', ' , '),
        mainCategory: 'Current Liabilities',
        ledger: value?.supplierName || `Supplier Not Found (${value?.supplierid})`,
        outlet: value?.locationName,
        dr: fCurrency(value?.totalamount),
        cr: '-',
        balance: fCurrency(startingAmount),
      };
    });

    setCashFlowList(updatedCashflowList);
  }, [data, selectedPaymentTypeId, paymentTypeList, locationList, dateRange]);


  useEffect(() => {
    if (addData?.data) {
      dispatch(getCashFlows(selectedLocation?.id));
    }
  }, [addData]);

  const columns = !cashFlowList?.[0]
    ? []
    : Object.keys(cashFlowList?.[0])
      .map((value) => {
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      })
      ?.filter((value) => value?.accessorKey);

  const formatDateRangeDisplay = (range) =>
    range[0] && range[1]
      ? `${moment(range[0]).format('DD-MMMM-YYYY')} - ${moment(range[1]).format('DD-MMMM-YYYY')}`
      : 'Select Date Range';

  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Finance', href: '' },
            { name: title },
          ]}
          action={<></>}
        />
        <Card style={{ padding: '16px', marginBottom: '24px' }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={3}> </Grid>
            <Grid item xs={12} md={6}>
              <MobileDateRangePicker
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                onOpen={() => setOpenPicker(true)}
                value={dateRange}
                onChange={(newValue) => {}}
                onAccept={(newValue) => {
                  setDateRange(
                    newValue.map((date, index) =>
                      date
                        ? index === 1
                          ? moment(date).local().endOf('day').toDate()
                          : moment(date).local().startOf('day').toDate()
                        : null
                    )
                  );
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <TextField
                    ref={inputRef}
                    {...inputProps}
                    InputProps={{
                      ...InputProps,
                    }}
                    fullWidth
                    label="Select Date Range"
                    value={formatDateRangeDisplay(dateRange)}
                    onClick={() => setOpenPicker(true)}
                    readOnly
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}> </Grid>
          </Grid>
        </Card>
        {accessVerify('CASHFLOW_VIEW') ? (
          <DataGridTable
            name={'Assets List'}
            data={cashFlowList}
            column={columns}
            isLoading={false}
            enableRowSelection={false}
            enableRowActions={false}
            isRowClickable={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import DataGridTable from '../../../components/table/DataGridTable';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { addFoodCategory, getFoodCategorys, updateFoodCategory } from '../../../redux/slices/stockManagementRedux/foodCategoryRedux';
import { accessVerify, capitalize } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';
export default function FoodCategoryManagement() {
  const { type } = useParams();
  const dataModel = {
    id: null,
    name: null,
    description: null,
  };
  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [foodList, setFoodList] = useState([]);

  const { data, addData } = useSelector((state) => state.foodCategory);

  useEffect(() => {
    dispatch(getFoodCategorys(type));
  }, [type]);

  useEffect(() => {
    setFoodList(data);
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedObject(dataModel);
      } else {
        setSelectedObject(modelData);
      }
    }
  };

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: selectedObject?.name || '',
      description: selectedObject?.description || '',
    }),
    [selectedObject]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isAdd && selectedObject) {
      reset(defaultValues);
    }
    if (!isAdd) {
      reset(defaultValues);
    }
  }, [isAdd, selectedObject]);

  const onSubmit = async (value) => {
    try {
      const tempDataObj = {
        name: value?.name || '',
        description: value?.description || '',
        type: type || '',
      };
      if (isAdd) {
        dispatch(addFoodCategory(tempDataObj));
      } else {
        dispatch(updateFoodCategory({ ...tempDataObj, id: selectedObject?.id }));
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      setValue('startTime', null);
      setValue('endTime', null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getFoodCategorys(type));
    }
  }, [addData]);

  const columns = !foodList?.[0]
    ? []
    : Object.keys(foodList?.[0]).map((value) => {
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      });

  const [rowSelection, setRowSelection] = useState(false);
  return (
    <Page title={`Food Categories - (${capitalize(type)})`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Food Categories - (${capitalize(type)})`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Stock',
              href: '',
            },
            { name: `Food Categories - (${capitalize(type)})` },
          ]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel(dataModel, 'add')}>
              New Food
            </Button>
          }
        />

        {accessVerify('FOOD_CATEGORY_VIEW') ? (
          <DataGridTable
            name={'Food Category'}
            data={foodList}
            column={columns}
            isRowClickable={true}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Category</h1>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Category Information
                    </Typography>
                    <Stack spacing={3}>
                      <RHFTextField name='name' label='Name' />
                      <RHFTextField name='description' label='Description' />
                    </Stack>
                  </Card>
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' loading={isSubmitting} style={{ width: '100%' }}>
                      {isAdd ? 'Add Category' : 'Save Changes'}
                    </LoadingButton>
                    <Button color='info' variant='outlined' size='large' loading={isSubmitting} style={{ width: '100%' }} onClick={() => manageModel(dataModel, 'add')}>
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

import React, { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../../utils/common';
import { Autocomplete, Box, Button, Card, Container, Grid, Slider, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { getInspection } from '../../../../redux/slices/hrRedux/inspection/inspectionRedux';
import { getInspectionType } from '../../../../redux/slices/hrRedux/inspection/inspectionTypeRedux';
import { getUsers } from '../../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { makeStyles } from '@material-ui/core';
import { addInspectionExamin, getInspectionExamin, updateInspectionExamin } from '../../../../redux/slices/hrRedux/inspection/inspectionExaminRedux';
import PermissionRequired from '../../../errorPages/permissionRequired';

const sweepableDrawerStyles = {
  sx: {
    width: '98%',
    height: '97%',
    marginLeft: '1%',
    marginTop: '3%',
    borderRadius: '10px',
  },
};
const useStyles = makeStyles({
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 15,
  },
  slider: {
    width: '90%',
  },
});

const marks = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
];

const textTitleStyle = {
  fontSize: '12px',
  color: 'grey',
  marginRight: '10px',
};

const textInformationStyle = {
  fontSize: '15px',
  fontWeight: 500,
  paddingLeft: '15px',
};

export default function InspectionExamin() {
  const classes = useStyles();
  const dataModel = {
    title: '',
    description: '',
    weight: '',
    inspectionTypeId: '',
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [rowSelection, setRowSelection] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [processedInspectionList, setProcessedInspectionList] = useState([]);
  const [processedInspectionExaminList, setProcessedInspectionExaminList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [autoCompleteInspectionSelectedValue, setAutoCompleteInspectionSelectedValue] = useState([]);
  const [inspectionSelectedValue, setInspectionSelectedValue] = useState([]);
  let inspectionExaminData;
  let inspectionExaminAddData;
  ({ data: inspectionExaminData, addData: inspectionExaminAddData } = useSelector((state) => state?.inspectionExamin));

  let inspectionData;
  let inspectionAddData;
  ({ data: inspectionData, addData: inspectionAddData } = useSelector((state) => state.inspection));

  let inspectionTypeDataList;
  ({ data: inspectionTypeDataList } = useSelector((state) => state.inspectionType));

  let usersData;
  ({ data: usersData } = useSelector((state) => state.user));
  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));
  const { loggedInUser } = useSelector((state) => state?.user);

  useEffect(() => {
    dispatch(getInspectionExamin());
    dispatch(getInspectionType());
    dispatch(getInspection());
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setProcessedInspectionList(
      inspectionData?.map((value) => {
        return { ...value, questions: safeJSONParse(value?.questions) || [] };
      })
    );
    setIsModelOpen(false);
  }, [inspectionData]);

  useEffect(() => {
    setProcessedInspectionExaminList(
      inspectionExaminData?.map((value) => ({
        ...value,
        questions: safeJSONParse(value?.questions) || [],
      }))
    );
    setIsModelOpen(false);
  }, [inspectionExaminData]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setProcessedInspectionExaminList([]);
        setSelectedUsers([]);
        setInspectionSelectedValue([]);
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (inspectionExaminAddData) {
      dispatch(getInspectionExamin());
      dispatch(getInspectionType());
      dispatch(getInspection());
    }
  }, [inspectionExaminAddData]);

  const columns = !processedInspectionExaminList?.[0]
    ? []
    : Object.keys(processedInspectionExaminList?.[0])
        .map((value) => {
          if (['inspectedBy', 'questions', 'userData', 'inspectedByUserId', 'locationId', 'userId', 'inspectionId', 'acceptedByUserName'].includes(value)) {
            return {};
          } else if (value === 'acceptedByUser') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return row.original?.acceptedByUser === -1 ? `Not Accepted` : `Accepted`;
              },
            };
          } else if (value === 'dateTime') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return `${utcMoment(row.original?.dateTime).format('DD MMM yyyy HH:mm')}`;
              },
            };
          } else if (value === 'inspectionTypeId') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return inspectionTypeDataList?.find((item) => item.id === row.original?.inspectionTypeId)?.title;
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const handleSubmit = () => {
    if (selectedUsers.length === 0) {
      toast.error('Please select user!');
    } else if (inspectionSelectedValue?.questions?.filter((value) => value?.markedScore === -1).length !== 0) {
      toast.error('All the questions are required to continue!');
    } else {
      const dataObj = { data: { ...inspectionSelectedValue, locationId: selectedLocation?.id }, usersData: selectedUsers };
      isAdd ? dispatch(addInspectionExamin(dataObj)) : toast.error('Update is not allowed yet');
    }
  };
  const acceptInspection = () => {
    dispatch(updateInspectionExamin({ id: selectedDataObj?.id }));
  };

  const handleSlider = (event) => {
    const { name, value } = event.target;
    const updatedQuestions = inspectionSelectedValue?.questions?.map((item) => {
      if (item?.id === name) {
        return { ...item, markedScore: value };
      } else {
        return item;
      }
    });
    setInspectionSelectedValue({ ...inspectionSelectedValue, questions: updatedQuestions });
  };

  return (
    <Page title={`Inspection Examin`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Inspection Examin`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Inspection',
              href: '',
            },
            { name: `Inspection Examin` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Inspection Examin
            </Button>
          }
        />
        {accessVerify('INSPECTION_EXAMIN_VIEW') ? (
          <DataGridTable
            name={'Inspection Template List'}
            data={processedInspectionExaminList}
            column={columns}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer anchor={'bottom'} open={isModelOpen} onOpen={() => setIsModelOpen(true)} onClose={() => setIsModelOpen(false)} PaperProps={sweepableDrawerStyles}>
          {isAdd ? (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 2 }}>
                    <h1>{isAdd ? 'Add' : 'View'} Inspection Examin</h1>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ p: 2 }}>
                    <Button
                      color='info'
                      variant='outlined'
                      size='large'
                      style={{ width: '48%', marginRight: '1%' }}
                      onClick={() => {
                        manageModel(dataModel, 'add');
                      }}
                    >
                      Close
                    </Button>
                    <LoadingButton type='submit' variant='contained' size='large' style={{ width: '48%', marginLeft: '1%' }} onClick={handleSubmit}>
                      {isAdd ? 'Add Inspection' : 'Save Changes'}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setSelectedUsers(newValue);
                  }}
                  options={usersData?.map((value) => ({
                    label: `${value?.name}`,
                    value: value?.id,
                  }))}
                  value={selectedUsers}
                  multiple={true}
                  renderInput={(params) => <TextField label='Select User' {...params} />}
                />
                <Autocomplete
                  onChange={(event, newValue) => {
                    const selectedInspectionList = processedInspectionList?.find((value) => value?.id === newValue?.value);
                    setInspectionSelectedValue({
                      ...selectedInspectionList,
                      questions: selectedInspectionList?.questions?.map((value) => ({ ...value, markedScore: -1 })) || [],
                    });
                    setAutoCompleteInspectionSelectedValue(newValue);
                  }}
                  options={processedInspectionList?.map((value) => ({
                    label: `${value?.title} - ${value?.description}`,
                    value: value?.id,
                  }))}
                  value={autoCompleteInspectionSelectedValue}
                  renderInput={(params) => <TextField label='Select Inspection Type' {...params} />}
                />
                <Card sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    {([...new Set(inspectionSelectedValue?.questions?.map((value) => value?.categoryId))] || []).map((categoryId) => {
                      const categoryQuestions = inspectionSelectedValue?.questions?.filter((value) => value?.categoryId === categoryId) || [];
                      const categoryName = categoryQuestions.length > 0 ? categoryQuestions[0]?.categoryTitle : '';
                      return (
                        <Grid item xs={12} key={categoryId}>
                          <Card sx={{ p: 3 }} style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}>
                            <Typography variant='h6' gutterBottom>
                              {categoryName}
                            </Typography>
                            <br />

                            <Grid container spacing={3}>
                              {categoryQuestions.map((value) => (
                                <>
                                  <Grid item xs={6} key={categoryId}>
                                    <Card sx={{ p: 3 }}>
                                      <Typography variant='body1' gutterBottom>
                                        {value?.title}
                                      </Typography>
                                      <br />
                                      <div className={classes.sliderContainer}>
                                        <Slider
                                          name={value?.id}
                                          className={classes.slider}
                                          aria-label='Temperature'
                                          defaultValue={0}
                                          step={1}
                                          min={0}
                                          max={10}
                                          valueLabelDisplay='on'
                                          aria-labelledby={value?.title}
                                          marks={marks}
                                          onChange={handleSlider}
                                        />
                                      </div>
                                    </Card>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Card>
              </Stack>
            </>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Box sx={{ p: 2 }}>
                    <h1>{capitalize(selectedDataObj?.inspectionTitle)}</h1>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  {loggedInUser?.id === selectedDataObj?.userId && selectedDataObj?.acceptedByUser === -1 && (
                    <Box sx={{ p: 2 }}>
                      <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={acceptInspection}>
                        Accept {selectedDataObj?.inspectionScore} % Inspection Score
                      </LoadingButton>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ p: 3, m: 2 }}>
                    <div sx={{ alignItems: 'center', mb: 2 }}>
                      <table>
                        <tbody>
                          <tr>
                            <td style={textTitleStyle}>Date Time</td>
                            <td style={textInformationStyle}>{utcMoment(selectedDataObj?.dateTime).format('DD MMM yyyy HH:mm')}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Inspected User</td>
                            <td style={textInformationStyle}>{selectedDataObj?.userName}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Inspected By Name</td>
                            <td style={textInformationStyle}>{selectedDataObj?.inspectedByName}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Location Name</td>
                            <td style={textInformationStyle}>{selectedDataObj?.locationName}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Marked Weight</td>
                            <td style={textInformationStyle}>{selectedDataObj?.markedWeight}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Total Weight</td>
                            <td style={textInformationStyle}>{selectedDataObj?.totalWeight}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Inspection Score</td>
                            <td style={textInformationStyle}>{selectedDataObj?.inspectionScore} %</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card sx={{ p: 3, m: 2 }}>
                    <div sx={{ alignItems: 'center', mb: 2 }}>
                      <table>
                        <tbody>
                          <tr>
                            <td style={textTitleStyle}>Inspection ID</td>
                            <td style={textInformationStyle}>{selectedDataObj?.inspectionId}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Inspection Title</td>
                            <td style={textInformationStyle}>{selectedDataObj?.inspectionTitle}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Inspection Description</td>
                            <td style={textInformationStyle}>{selectedDataObj?.inspectionDescription}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Inspection Type</td>
                            <td style={textInformationStyle}>{selectedDataObj?.inspectionType}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Inspection Type Description</td>
                            <td style={textInformationStyle}>{selectedDataObj?.inspectionTypeDescription}</td>
                          </tr>
                          <tr>
                            <td style={textTitleStyle}>Accept By User's Name</td>
                            <td style={textInformationStyle}>{selectedDataObj?.acceptedByUser === -1 ? 'Not Approved Yet' : selectedDataObj?.acceptedByUserName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3, m: 2 }}>
                    <DataGridTable
                      name={'Inspection Template List'}
                      data={selectedDataObj?.questions}
                      column={[
                        { accessorKey: 'id', header: 'Id' },
                        { accessorKey: 'categoryTitle', header: 'Category Title' },
                        { accessorKey: 'title', header: 'Title' },
                        { accessorKey: 'description', header: 'Description' },
                        { accessorKey: 'weight', header: 'Weight' },
                        { accessorKey: 'markedWeight', header: 'Marked Weight' },
                        { accessorKey: 'markedScore', header: 'Marked Score' },
                        { accessorKey: 'questionWeight', header: 'Question Weight' },
                        { accessorKey: 'isActive', header: 'Is Active' },
                      ]}
                      onRowClick={(row) => {}}
                      isRowClickable={true}
                      isLoading={false}
                      rowSelection={rowSelection}
                      setRowSelection={setRowSelection}
                      enableRowSelection={false}
                      enableRowActions={false}
                      renderRowActionItems={(value, closeMenu) => []}
                    />
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

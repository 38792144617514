import React from 'react';
import { Document, Font, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { fCurrency, fCurrencyWithoutSymbol } from '../../../../../utils/formatNumber';
import moment from 'moment/moment';
import { padNumber } from '../../../../../utils/common';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});
Font.register({
  family: 'arial',
  fonts: [{ src: 'https://ik.imagekit.io/bubblemania/arial.ttf' }],
});
Font.register({
  family: 'sfPro',
  fonts: [{ src: 'https://ik.imagekit.io/bubblemania/SFPRODISPLAYBOLD.ttf' }],
});
Font.register({
  family: 'sfProRegular',
  fonts: [{ src: 'https://ik.imagekit.io/bubblemania/SFPRODISPLAYREGULAR.ttf' }],
});

const styles = StyleSheet.create({
  page: { flexDirection: 'column', width: '80mm' },
  section: { flexGrow: 1 },
  image: { width: '40mm', height: '29.705mm', marginTop: '1mm', objectFit: 'cover', marginLeft: '15mm' },
  headingName: { textAlign: 'center', fontWeight: 600, fontSize: 20 },
  locationName: { textAlign: 'center', fontWeight: 300, fontSize: 14 },
  textCenter: { textAlign: 'center' },
  textRight: { textAlign: 'right' },
  textLeft: { textAlign: 'left' },
  sfPro: { fontFamily: 'sfPro' },
  sfProRegular: { fontFamily: 'sfProRegular' },
  arial: { fontFamily: 'arial' },
  bold: { fontWeight: 'bold' },
  divider: { marginTop: 7, marginBottom: 7, borderBottomWidth: 1, borderBottomColor: '#000', borderBottomStyle: 'solid' },
  orderNumberTopic: { fontFamily: 'sfPro', fontSize: 9, fontWeight: 'ultrabold', borderStyle: 'solid', textAlign: 'center' },
  orderNumber: { fontFamily: 'sfPro', fontWeight: 'ultrabold', borderStyle: 'solid', textAlign: 'center' },
  twoSides: { flexDirection: 'row' },
  twoSidesCell: { margin: 'auto', marginTop: 1, marginBottom: 1, fontSize: 8, padding: 1, fontFamily: 'arial', fontWeight: 500 },
  tableRow: { flexDirection: 'row', borderStyle: 'solid' },
  tableRowHeader: { flexDirection: 'row', color: '#FFF', borderTopWidth: 0, borderRadius: '4px', backgroundColor: '#000' },
  tableHeaderCell: { margin: 'auto', marginTop: 1, marginBottom: 1, fontSize: 8, padding: 2, fontFamily: 'arial', fontWeight: 700 },
  tableCell: { margin: 'auto', marginTop: 1, marginBottom: 1, fontSize: 8, padding: 2, fontFamily: 'arial', fontWeight: 700 },
  summeryRow: { flexDirection: 'row', borderRadius: '4px' },
  summeryData: { margin: 'auto', marginTop: '1px', marginBottom: '1px', fontSize: 9, padding: 3, fontFamily: 'arial', fontWeight: 500 },
  developedByBox: { padding: 5, backgroundColor: '#000', marginTop: 3, marginBottom: 7, borderRadius: '4px' },
});

const tableStyles = StyleSheet.create({
  details: { ...styles.tableCell, width: '50%' },
  qty: { ...styles.tableCell, width: '10%' },
  price: { ...styles.tableCell, width: '20%' },
  amount: { ...styles.tableCell, width: '20%' },
});
const tableHeaderStyles = StyleSheet.create({
  details: { ...styles.tableHeaderCell, width: '50%' },
  qty: { ...styles.tableHeaderCell, width: '10%' },
  price: { ...styles.tableHeaderCell, width: '20%' },
  amount: { ...styles.tableHeaderCell, width: '20%' },
});
const twoSideStyles = StyleSheet.create({
  details: { ...styles.twoSidesCell, width: '50%', textAlign: 'left' },
  price: { ...styles.twoSidesCell, width: '50%', textAlign: 'right' },
});

const summeryStyles = StyleSheet.create({
  dataBlock: { ...styles.summeryData, width: '66.6%', textAlign: 'left' },
  dataPrice: { ...styles.summeryData, width: '33.3%', textAlign: 'right' },
});

function TableHeader() {
  return (
    <View style={styles.tableRowHeader}>
      <Text style={[tableHeaderStyles.details, styles.textLeft, styles.bold]}>Product</Text>
      <Text style={[tableHeaderStyles.qty, styles.textCenter, styles.bold]}>Qty</Text>
      <Text style={[tableHeaderStyles.price, styles.textCenter, styles.bold]}>Price</Text>
      <Text style={[tableHeaderStyles.amount, styles.textCenter, styles.bold]}>Amount</Text>
    </View>
  );
}

function TableRow({ item }) {
  return (
    <View style={styles.tableRow}>
      <Text style={[tableStyles.details, styles.textLeft]}>{item.title}</Text>
      <Text style={[tableStyles.qty, styles.textLeft]}>{item.qty}</Text>
      <Text style={[tableStyles.price, styles.textLeft]}>{item?.price ? fCurrencyWithoutSymbol(item.price) : item.value}</Text>
      <Text style={[tableStyles.amount, styles.textRight]}>{item?.price ? fCurrencyWithoutSymbol(item.price) : item.value}</Text>
    </View>
  );
}
function SingleLineData({ item }) {
  return (
    <View style={styles.twoSides}>
      <Text style={[twoSideStyles.details, styles.textLeft]}>{item.title}</Text>
      <Text style={twoSideStyles.price}>{item?.price ? fCurrency(item?.price || 0) : item?.price === 0 ? fCurrency(0.0) : item.value}</Text>
    </View>
  );
}

function TableSummeryRow({ item, isBlack = false }) {
  return (
    <View style={[styles.summeryRow, { backgroundColor: isBlack ? '#000' : '#fff', color: isBlack ? '#fff' : '#000' }]}>
      <Text style={summeryStyles.dataBlock}>{item.title}</Text>
      <Text style={summeryStyles.dataPrice}>{item?.price ? fCurrency(item.price) : item.value}</Text>
    </View>
  );
}

function PosInvoicePdf({ data }) {
  return (
    <Document>
      <Page style={styles.page} size={[226.772, 'auto']}>
        <View style={{ ...styles.section, paddingRight: data?.selectedPrinter === 'epson' ? 0 : '5mm', paddingLeft: data?.selectedPrinter === 'epson' ? '10mm' : 0 }}>
          {!data?.isKOT && <Image style={styles.image} src={'https://ik.imagekit.io/bubblemania/output-onlinepngtools%20(2).png'} />}
          <Text style={[styles.sfPro, styles.headingName]}>
            {data?.isKOT ? `KOT` : `Bubble Mania`} {data?.isReprint ? ' - RePrint' : ''}
          </Text>
          {!data?.isKOT && (
            <>
              <Text style={[styles.sfProRegular, styles.locationName]}>{data?.locationName}</Text>
              <Text style={[styles.sfProRegular, { fontSize: 8 }, styles.textCenter]}>{data?.locationAddress}</Text>
              <Text style={[styles.sfProRegular, { fontSize: 8 }, styles.textCenter]}>Phone: {data?.phoneNumber}</Text>
            </>
          )}

          <View style={[{ margin: '2mm 6mm 2mm 6mm', padding: 5, border: '2px solid #000', borderRadius: '10px' }]}>
            <Text style={[styles.orderNumberTopic]}>Order Number</Text>
            <Text style={[styles.orderNumber]}>{padNumber(data?.orderNumber, 6)}</Text>
          </View>

          <SingleLineData item={{ title: 'Order Type', value: data?.orderType }} />
          <SingleLineData item={{ title: 'Cashier Name', value: data?.cashierName }} />
          <SingleLineData item={{ title: 'Cashier Drawer ID', value: data?.cashierDrawerId }} />
          <SingleLineData item={{ title: 'Customer Name', value: data?.customerName }} />
          {data?.loyaltyPoint && <SingleLineData item={{ title: 'Customer Locality Point', price: data?.loyaltyPoint }} />}
          <SingleLineData item={{ title: 'Date & Time', value: moment(data?.dateTime).format('HH:mm:ss DD MMMM YYYY') }} />
          {data?.tableNumber && <SingleLineData item={{ title: 'Table Number', value: data?.tableNumber }} />}
          {data?.specialNote && <SingleLineData item={{ title: 'Special Note', value: data?.specialNote }} />}
          <View style={styles.divider} />
          <View>
            <TableHeader />
            {data?.invoiceItems?.map((item, index) => (
              <TableRow key={index} item={item} />
            ))}
          </View>

          <TableSummeryRow item={{ title: 'Grand Total', price: data?.grandTotal }} isBlack={true} />
          <SingleLineData item={{ title: 'Given Amount', price: data?.givenAmount }} />
          <SingleLineData item={{ title: 'Balance Amount', price: data?.balanceAmount || 0 }} />
          <SingleLineData item={{ title: 'Payment Method', value: data?.paymentMethod }} />
          <SingleLineData item={{ title: 'Number Of Items', value: data?.totalItemCount }} />
          {!data?.isKOT && (
            <>
              <View style={styles.divider} />
              <Text style={[styles.arial, { fontSize: 8, fontWeight: 600, marginLeft: '5mm', marginRight: '5mm' }, styles.textCenter]}>We’re thankful for your visit.</Text>
              <Text style={[styles.arial, { fontSize: 8, fontWeight: 600, marginLeft: '5mm', marginRight: '5mm' }, styles.textCenter]}>Come again for another delicious delights.</Text>
              <Text style={[styles.arial, { fontSize: 8, fontWeight: 600, marginLeft: '5mm', marginRight: '5mm' }, styles.textCenter]}>Have a wonderful day!</Text>
              <View style={styles.divider} />
              <View style={styles.developedByBox}>
                <Text style={[styles.arial, { fontSize: 7, color: '#FFF' }, styles.textCenter]}>Developed By www.expergen.com</Text>
              </View>
            </>
          )}
        </View>
      </Page>
    </Document>
  );
}

export default PosInvoicePdf;

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 3),
}));

PieChartComponent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.shape({
    dataset: PropTypes.arrayOf(PropTypes.object),
  }),
  icon: PropTypes.element,
  shortenNumber: PropTypes.bool,
};

export default function PieChartComponent({ title, value, icon }) {
  const customize = {
    height: 300,
    legend: { hidden: true },
    padding: 5,
  };

  return (
    <RootStyle>
      {icon}
      <Typography variant='h6' sx={{ color: 'text.secondary', mb: 2 }}>
        {title}
      </Typography>
      <PieChart
        series={[
          {
            data: value,
            innerRadius: 30,
          },
        ]}
        width={400}
        height={200}
        {...customize}
      />
    </RootStyle>
  );
}

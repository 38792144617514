import { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Stack } from '@mui/material';
import MenuPopover from '../../../components/MenuPopover';
import { dispatch, useSelector } from '../../../redux/store';
import { selectGlobalLocation } from '../../../redux/slices/settingsManagementRedux/locationsRedux';
import useSettings from '../../../hooks/useSettings';
import { colorPresets } from '../../../utils/getColorPresets';
import { accessVerify } from '../../../utils/common';
export default function LocationChanger() {
  const { onChangeColorOnName } = useSettings();
  const { data, selectedLocation } = useSelector((state) => state.location);
  const { loggedInUser } = useSelector((state) => state?.user);

  const [locationData, setLocationData] = useState([]);
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState({});
  const [open, setOpen] = useState(null);

  useEffect(() => {
    setLocationData(data?.filter((value) => loggedInUser?.locationPermissions?.includes(value?.id) || accessVerify('SUPER_ADMIN')));
  }, [data, loggedInUser]);

  useEffect(() => {
    setCurrentSelectedLocation(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    if (locationData?.length > 0 && locationData?.[0] && !selectedLocation?.id) {
      setTimeout(() => {
        const tempLocationData = locationData?.[0];
        onChangeColorOnName(colorPresets.find((value) => value?.main === tempLocationData?.colorCode)?.name);
        handleClose();
        dispatch(selectGlobalLocation(tempLocationData));
      }, 2000);
    }
  }, [locationData]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeLocation = (value) => {
    dispatch(selectGlobalLocation(value));
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          height: 40,
          bgcolor: 'background.neutral',
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <Box
          sx={{
            width: 24,
            height: 14,
            borderRadius: '50%',
            bgcolor: currentSelectedLocation?.colorCode || '#FF3030',
            marginRight: '10px',
            transition: (theme) =>
              theme.transitions.create('all', {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
              }),
          }}
        />
        <p>{currentSelectedLocation?.name || 'Store'}</p>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          maxHeight: 220,
          overflowY: 'auto',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {locationData.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === currentSelectedLocation?.id}
              onClick={() => {
                onChangeColorOnName(colorPresets.find((value) => value?.main === option?.colorCode)?.name);
                handleClose();
                onChangeLocation(option);
              }}
            >
              <Box
                sx={{
                  width: 24,
                  height: 14,
                  borderRadius: '50%',
                  bgcolor: option?.colorCode,
                  transform: 'rotate(-45deg)',
                  marginRight: '10px',
                  transition: (theme) =>
                    theme.transitions.create('all', {
                      easing: theme.transitions.easing.easeInOut,
                      duration: theme.transitions.duration.shorter,
                    }),
                  ...(option.id === currentSelectedLocation?.id && {
                    transform: 'none',
                  }),
                }}
              />
              {option.name}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}

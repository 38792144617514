import { Autocomplete, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

export default function InspectionTemplateInformation({ selectedDataObj, updateEditingData, inspectionTypeData = [] }) {
  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={1} sx={{ pr: 3 }}>
        <Typography variant='subtitle1' padding='10px 0'>
          Inspection Template Information
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label='Title' value={selectedDataObj?.title} onChange={(e) => updateEditingData('title', e.target.value)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label='Score Weight for Inspection Category' type={'number'} value={selectedDataObj?.weight} onChange={(e) => updateEditingData('weight', e.target.value)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              onChange={(event, newValue) => {
                const inspectionTypeInfo = inspectionTypeData.find((value) => value?.id === newValue?.value);
                updateEditingData('inspectionTypeId', inspectionTypeInfo?.id);
              }}
              options={inspectionTypeData?.map((value) => ({
                label: `${value?.title} - ${value?.description}`,
                value: value.id,
              }))}
              value={{
                label: `${inspectionTypeData?.find((value) => value.id === selectedDataObj?.inspectionTypeId)?.title || 'Select Inspection Type'}`,
                value: selectedDataObj?.inspectionTypeId,
              }}
              renderInput={(params) => <TextField label='Category (Inspection Type)' {...params} />}
            />
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}

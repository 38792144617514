import React, { useEffect, useMemo, useState } from 'react';
import { Container, Grid, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { MobileDateRangePicker } from '@mui/lab';
import { getAllAssets } from '../../../redux/slices/assetsRedux/assetsRedux';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import { getPurchaseNotes } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import { getForPaymentDateRange, getPreviousCashBalance } from '../../../redux/slices/financeRedux/cashFlowRedux';
import { getPaymentTypes } from '../../../redux/slices/financeRedux/paymentTypeRedux';
import { getCashFlowCategories } from '../../../redux/slices/financeRedux/cashFlowCategoriesRedux';
import PermissionRequired from '../../errorPages/permissionRequired';
import { endOfDay, format, startOfDay } from 'date-fns';
import moment from 'moment';
import { getLocations } from '../../../redux/slices/settingsManagementRedux/locationsRedux';
import { fCurrency } from '../../../utils/formatNumber';

export default function GlobalCashbook() {
  const { themeStretch } = useSettings();

  let locationList;
  let selectedLocation;
  ({ data: locationList, selectedLocation } = useSelector((state) => state.location));

  const [cashflowList, setCashflowList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);

  const [openPicker, setOpenPicker] = useState(false);
  const [dateRange, setDateRange] = useState([startOfDay(new Date()), endOfDay(new Date())]);
  const [listOfAccounts, setListOfAccounts] = useState([]);

  const formatDateRangeDisplay = ([start, end]) => {
    if (!start || !end) return '';
    return `${format(start, 'dd/MM/yyyy')} - ${format(end, 'dd/MM/yyyy')}`;
  };

  const { data, cashBalance } = useSelector((state) => state.cashFlow);

  let paymentDataList;
  ({ data: paymentDataList } = useSelector((state) => state.paymentTypes));

  useEffect(() => {
    const startDate = moment(dateRange[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(dateRange[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    dispatch(getForPaymentDateRange(selectedLocation?.id, startDate, endDate));
    dispatch(getPreviousCashBalance(selectedLocation?.id, startDate));
  }, [selectedLocation, dateRange]);

  useEffect(() => {
    dispatch(getPaymentTypes());
    dispatch(getAllAssets());
    dispatch(getPurchaseNotes());
    dispatch(getCashFlowCategories());
    dispatch(getLocations());
  }, []);

  const cleanCashflowList = (data) => {
    const keysToRemove = Object.keys(data[0]).filter((key) => data.every((item) => item[key] === fCurrency(0)));

    return data.map((item) => {
      const newItem = { ...item };
      keysToRemove.forEach((key) => delete newItem[key]);
      return newItem;
    });
  };

  useEffect(() => {
    const startingBalance = {
      date: '',
      transactionType: 'Starting Balance',
      documentType: '-',
      companyDocumentNumber: '-',
      issuedBy: '-',
      transactionCategory: '-',
      paymentToPaymentFrom: '',
      description: 'Initial cash balance',
    };

    const previousBalances = {};

    cashBalance
      ?.filter((value) => selectedLocation?.id === 1 || value?.locationId === selectedLocation?.id)
      .forEach((balance) => {
        const location = listOfAccounts.find((account) => account.locationData?.id === balance.locationId);
        const paymentType = paymentDataList.find((payment) => payment.id === balance.paymentTypeId);

        if (location && paymentType) {
          const debitColumnName = `${location.locationData?.id}_${paymentType?.id}_debit_${location.locationData?.name}_${paymentType?.name}`;
          startingBalance[debitColumnName] = fCurrency(balance.total_debits);

          const creditColumnName = `${location.locationData?.id}_${paymentType?.id}_credit_${location.locationData?.name}_${paymentType?.name}`;
          startingBalance[creditColumnName] = fCurrency(balance.total_credits);

          const balanceColumnName = `${location.locationData?.id}_${paymentType?.id}_balance_${location.locationData?.name}_${paymentType?.name}`;
          startingBalance[balanceColumnName] = fCurrency(balance.balance);

          // Initialize previous balance for this location and payment type
          previousBalances[balanceColumnName] = balance.balance;
        }
      });

    const allKeys = new Set(Object.keys(startingBalance));

    const convertedData = data?.map((value) => {
      const linkedInformation = safeJSONParse(value?.linkedInfo);
      const cashflowItem = {
        date: utcMoment(value?.paidDate).format('DD MMMM YYYY'),
        transactionType: linkedInformation?.cashFlowPaymentType?.[0]?.name,
        documentType: '-',
        companyDocumentNumber: value?.checkNo,
        issuedBy: linkedInformation?.userData?.[0]?.name,
        transactionCategory: linkedInformation?.cashFlowCategories?.[0]?.name,
        paymentToPaymentFrom: linkedInformation?.cashFlowPaymentType?.[0]?.name,
        description: value?.description,
        amount: value?.amount,
      };

      listOfAccounts?.forEach((account) => {
        const debitAccountFieldName = `${account.locationData?.id}_${account.accountData?.id}_debit_${account.locationData?.name}_${account.accountData?.name}`;
        cashflowItem[debitAccountFieldName] = value?.isDebit
          ? value?.locationId === account.locationData?.id && value?.paymentTypeId === account.accountData?.id
            ? fCurrency(value?.amount)
            : fCurrency(0)
          : fCurrency(0);
        allKeys.add(debitAccountFieldName);

        const creditAccountFieldName = `${account.locationData?.id}_${account.accountData?.id}_credit_${account.locationData?.name}_${account.accountData?.name}`;
        cashflowItem[creditAccountFieldName] = !value?.isDebit
          ? value?.locationId === account.locationData?.id && value?.paymentTypeId === account.accountData?.id
            ? fCurrency(value?.amount)
            : fCurrency(0)
          : fCurrency(0);
        allKeys.add(creditAccountFieldName);

        const balanceAccountFieldName = `${account.locationData?.id}_${account.accountData?.id}_balance_${account.locationData?.name}_${account.accountData?.name}`;

        if (value?.locationId === account.locationData?.id && value?.paymentTypeId === account.accountData?.id) {
          const amount = value?.isDebit ? value.amount : -value.amount; // Positive for debit, negative for credit
          previousBalances[balanceAccountFieldName] = (previousBalances[balanceAccountFieldName] || 0) + amount; // Update balance
          cashflowItem[balanceAccountFieldName] = fCurrency(previousBalances[balanceAccountFieldName]); // Set the current balance
        } else {
          previousBalances[balanceAccountFieldName] = previousBalances[balanceAccountFieldName] || 0; // Update balance
          cashflowItem[balanceAccountFieldName] = fCurrency(previousBalances[balanceAccountFieldName]); // Set the current balance
        }

        allKeys.add(balanceAccountFieldName);
      });
      return cashflowItem;
    });
    const finalData = [startingBalance, ...convertedData].map((item) => {
      const fullItem = {};
      allKeys.forEach((key) => {
        fullItem[key] = item[key] !== undefined ? item[key] : fCurrency(0);
      });
      return fullItem;
    });

    // Identify keys that need to be kept based on non-zero values
    const keysToKeep = new Set();

    finalData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        const value = item[key];
        const isZeroValue = value === fCurrency(0);

        if (!isZeroValue) {
          const keyParts = key.split('_');
          const prefix = keyParts.slice(0, 2).join('_'); // e.g., "7_3"
          keysToKeep.add(prefix);
        }
      });
    });

    // Filter out items based on the identified keys
    const filteredData = finalData
      .map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          const keyParts = key.split('_');
          const prefix = keyParts.slice(0, 2).join('_');

          if (keysToKeep.has(prefix) || item[key] !== fCurrency(0)) {
            newItem[key] = item[key];
          }
        });
        return newItem;
      })
      .filter((item) => Object.keys(item).length > 0);

    setCashflowList(filteredData);
  }, [data, listOfAccounts, cashBalance]);

  useEffect(() => {
    const tempPaymentTypeList = paymentDataList?.filter((value) => value?.isAddToCashflow === 1);
    const tempListOfAccounts = locationList?.map((locationData) => {
      return tempPaymentTypeList?.map((accountData) => ({
        name: `${locationData?.id}_${accountData?.id}_${locationData?.name}_${accountData?.name}`,
        locationData: safeJSONParse(locationData),
        accountData: safeJSONParse(accountData),
        value: 0,
      }));
    });
    const flattenedAccounts = tempListOfAccounts.reduce((acc, curr) => [...acc, ...curr], []);

    setListOfAccounts(flattenedAccounts);
  }, [locationList, paymentDataList]);

  const columns = useMemo(() => {
    if (!cashflowList?.[0]) return [];

    return Object.keys(cashflowList[0])
      .map((value) => {
        const splitValue = value.split('_');

        if (['linkedInfo', 'linkedDocuments', 'cashFlowLog'].includes(value)) {
          return {};
        } else if (splitValue.length > 2) {
          return {
            accessorKey: value,
            header: capitalize(`( ${splitValue[2]} ) ${splitValue[3]} - ${splitValue[4]}`),
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      .filter((value) => value?.accessorKey);
  }, [cashflowList]);

  return (
    <Page title={`Global Cash Book`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Global Cash Book`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Finance',
              href: '',
            },
            { name: `Global Cash Book` },
          ]}
          action={<></>}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MobileDateRangePicker
              open={openPicker}
              onClose={() => setOpenPicker(false)}
              onOpen={() => setOpenPicker(true)}
              value={dateRange}
              onChange={(newValue) => {}}
              onAccept={(newValue) => {
                setDateRange(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <TextField
                  ref={inputRef}
                  {...inputProps}
                  InputProps={{
                    ...InputProps,
                  }}
                  fullWidth
                  label='Select Date Range'
                  value={formatDateRangeDisplay(dateRange)}
                  onClick={() => setOpenPicker(true)}
                  readOnly
                />
              )}
            />
          </Grid>
        </Grid>
        {accessVerify('CASHFLOW_VIEW') ? (
          <DataGridTable
            name={'Global Cashbook'}
            data={cashflowList}
            column={[...columns]}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            enablePinning={false}
            isRowClickable={false}
            onRowClick={(row) => {}}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}

import { Card, Stack, Typography } from '@mui/material';
import React from 'react';
import Label from '../../../../../components/Label';
import { capitalize } from '../../../../../utils/common';

export default function AccountActivationDocumentStatus({ documentTypeListWithStatus }) {
  const uniqueCategories = [...new Set(documentTypeListWithStatus?.map((value) => value?.category))];

  return (
    <Stack spacing={4} alignItems='flex-end'>
      <Typography variant='subtitle1' sx={{ width: 1 }}>
        Document Status
      </Typography>
      {uniqueCategories?.map((category) => (
        <Stack spacing={1} sx={{ width: 1 }}>
          <Typography variant='subtitle2' sx={{ width: 1 }}>
            {capitalize(category)}
          </Typography>

          {documentTypeListWithStatus
            ?.filter((value) => value?.category === category)
            .map((documentType) => (
              <Stack key={documentType.id} direction='row' justifyContent='space-between' sx={{ width: 1 }}>
                <Typography variant='body2' sx={{ minWidth: 160 }}>
                  {documentType?.name}
                </Typography>
                <Label color={documentType?.status ? 'success' : 'warning'} sx={{ textTransform: 'capitalize' }}>
                  {documentType?.status ? 'Uploaded' : 'Not Uploaded'}
                </Label>
              </Stack>
            ))}
        </Stack>
      ))}
    </Stack>
  );
}

import React, { useEffect, useState } from 'react';
import { Autocomplete, Card, Grid, Stack, TextField, Tooltip, Switch, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { dispatch, useSelector } from '../../../../redux/store';
import { addCustomer, searchCustomers, updateCustomer } from '../../../../redux/slices/customerRedux/customerRedux';
import moment from 'moment';
import { toast } from 'react-toastify';
import CustomerDetailsBlock from './subcomponents/customerInfo/customerDetailsBlock';

export const CustomerMobileNumber = ({ data, setData, onClickSound, onChangeTab }) => {
  let customer;
  let addCustomerData;
  ({ data: customer, addData: addCustomerData } = useSelector((state) => state?.customers));

  const months = [
    { value: 0, label: '0 - Not Selected' },
    { value: 1, label: '1 - January' },
    { value: 2, label: '2 - February' },
    { value: 3, label: '3 - March' },
    { value: 4, label: '4 - April' },
    { value: 5, label: '5 - May' },
    { value: 6, label: '6 - June' },
    { value: 7, label: '7 - July' },
    { value: 8, label: '8 - August' },
    { value: 9, label: '9 - September' },
    { value: 10, label: '10 - October' },
    { value: 11, label: '11 - November' },
    { value: 12, label: '12 - December' },
  ];

  const days = Array.from({ length: 32 }, (_, index) => ({
    value: index,
    label: `${index}`,
  }));

  useEffect(() => {
    dispatch(searchCustomers(data?.name, data?.phone));
  }, [addCustomerData]);

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12} md={3}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={3}>
            <TextField
              type='text'
              label={`Contact Number`}
              sx={{ width: '100%' }}
              placeholder={`Customer Contact Number`}
              value={data?.phone}
              onChange={(event) => {
                onClickSound();
                dispatch(searchCustomers(data?.name, data?.phone));
                setData((value) => ({
                  ...value,
                  customerInfo: {
                    ...value?.customerInfo,
                    phone: event.target.value,
                  },
                }));
              }}
            />
            <TextField
              type='text'
              sx={{ width: '100%' }}
              label={`Name`}
              placeholder={`Customer Name`}
              value={data?.name}
              onChange={(event) => {
                onClickSound();
                dispatch(searchCustomers(data?.name, data?.phone));
                setData((value) => ({
                  ...value,
                  customerInfo: {
                    ...value?.customerInfo,
                    name: event.target.value
                      .split(/\s+/) // Updated regular expression to split on spaces
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                      .join(' '),
                  },
                }));
              }}
              onClick={(event) => {
                event.target.select();
              }}
            />

            <Autocomplete
              id='birth_month'
              options={months}
              sx={{ width: '100%' }}
              value={months.find((value) => value?.value === data?.birthdayMonth)}
              renderInput={(params) => <TextField {...params} label='Birth Month' />}
              onChange={(_, value) => {
                onClickSound();
                setData((tempData) => ({
                  ...tempData,
                  customerInfo: {
                    ...tempData?.customerInfo,
                    birthdayMonth: value?.value,
                  },
                }));
              }}
            />
            <Autocomplete
              id='birth_day'
              options={days}
              sx={{ width: '100%' }}
              value={days.find((value) => value?.value === data?.birthdayDay)}
              renderInput={(params) => <TextField {...params} label='Birth Day' />}
              onChange={(_, value) => {
                onClickSound();
                setData((tempData) => ({
                  ...tempData,
                  customerInfo: {
                    ...tempData?.customerInfo,
                    birthdayDay: value?.value,
                  },
                }));
              }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={data?.sendSMS}
                  onChange={(event) => {
                    setData((tempData) => ({
                      ...tempData,
                      customerInfo: {
                        ...tempData?.customerInfo,
                        sendSMS: event.target.checked,
                      },
                    }));
                  }}
                />
              }
              label='Send SMS'
            />

            <LoadingButton
              variant={'outlined'}
              size='large'
              style={{
                width: '100%',
              }}
              onClick={() => {
                if (data?.name && data?.phone && data?.phone?.length === 11) {
                  if (data?.isEdit) {
                    dispatch(
                      updateCustomer({
                        id: data?.id,
                        name: data?.name,
                        phone: data?.phone,
                        birthday: `${moment().year()}-${data?.birthdayMonth}-${data?.birthdayDay}`,
                        sendSMS: data?.sendSMS,
                      })
                    );
                  } else {
                    dispatch(
                      addCustomer({
                        name: data?.name,
                        phone: data?.phone,
                        birthday: `${moment().year()}-${data?.birthdayMonth}-${data?.birthdayDay}`,
                        sendSMS: data?.sendSMS,
                      })
                    );
                  }
                } else {
                  toast.warning('All the data required accordingly to add!');
                }
              }}
            >
              {data?.isEdit ? 'Update Now' : 'Create Now'}
            </LoadingButton>
            <LoadingButton
              variant={'outlined'}
              color={'info'}
              size='small'
              style={{
                width: '100%',
              }}
              onClick={() => {
                setData((tempData) => ({
                  ...tempData,
                  customerInfo: {
                    id: 0,
                    name: '',
                    phone: '94',
                    birthdayMonth: null,
                    birthdayDay: null,
                    sendSMS: true,
                  },
                }));
                dispatch(searchCustomers('', '94'));
              }}
            >
              Clear
            </LoadingButton>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12} md={9} justifyContent='center'>
        <Card sx={{ p: 3 }}>
          <Grid container justifyContent='left'>
            {customer?.map((value, index) => (
              <Grid item key={index}>
                <Tooltip title={value?.name} placement={'top'}>
                  <CustomerDetailsBlock value={value} data={data} setData={setData} onClickSound={onClickSound} onChangeTab={onChangeTab} />
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts';

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 3),
  minHeight: '300px',
}));

export default function BarChartComponent({ title, value }) {
  return (
    <RootStyle>
      <Typography variant='h6' sx={{ color: 'text.secondary', mb: 2 }}>
        {title}
      </Typography>
      {value?.xAxis?.[0]?.data?.length > 0 && <BarChart xAxis={value?.xAxis} series={value?.series} height={300} />}
    </RootStyle>
  );
}

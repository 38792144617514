import React, { useEffect, useState } from 'react';
import { Box, Card, Chip, Container, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { getStocks } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import DataGridTable from '../../../../components/table/DataGridTable';
import { capitalize, safeJSONParse } from '../../../../utils/common';
import { addStockTransferSender } from '../../../../redux/slices/stockManagementRedux/stockTransferRedux';
import { toast } from 'react-toastify';
import useSound from 'use-sound';
import successFx from '../../../../assets/sounds/success.wav';
import errorFx from '../../../../assets/sounds/error.wav';

const Sender = () => {
  const { themeStretch } = useSettings();
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState({
    name: '',
    phoneNumber: '94',
    note: '',
    barcode: [],
    stockIdList: [],
  });
  const [rowSelection, setRowSelection] = useState(false);
  const { data: stocks } = useSelector((state) => state?.stock);
  const { addData: stockAddedStatus } = useSelector((state) => state?.stockTransfer);
  const [initiated, setInitiated] = useState(false);

  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  const [playErrorSound] = useSound(errorFx, {
    volume: 0.8,
    playbackRate: 1.2,
  });

  const [playSuccessSound] = useSound(successFx, {
    volume: 0.8,
    playbackRate: 1.2,
  });

  useEffect(() => {
    if (data?.barcode?.length > 0) {
      dispatch(getStocks(-1, -1, -1, data?.barcode));
    }
  }, [data?.barcode]);

  const columns = tableData?.[0]
    ? Object.keys(tableData?.[0])
        .map((value) => {
          if (value === 'status') {
            return {
              accessorKey: 'status',
              header: 'Status',
              Cell: ({ cell, row }) => (row?.original?.status === 'Eligible' ? <Chip label={'Eligible'} color={'success'} /> : <Chip label={'Not Eligible'} color={'error'} />),
            };
          }
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        })
        .filter(Boolean)
    : [];

  useEffect(() => {
    const reversedData = [...data.barcode].reverse();
    setData((prevData) => ({
      ...prevData,
      stockIdList: [],
    }));
    setTableData(
      reversedData.map((barcodeIndex) => {
        const stockInfo = stocks.find((stock) => stock?.stockCode === barcodeIndex);
        const stockLocation = safeJSONParse(stockInfo?.locationInfo);
        const sameLocation = stockLocation?.locationId === selectedLocation?.id;

        setData((prevData) => ({
          ...prevData,
          stockIdList: [
            ...prevData.stockIdList,
            {
              stockId: stockInfo?.stockId,
              currentQty: stockInfo?.currentQty,
              costPerUnit: stockInfo?.costPerUnit,
            },
          ],
        }));
        return {
          barCode: barcodeIndex,
          foodInfo: stockInfo ? safeJSONParse(stockInfo.foodInfo)?.foodName : 'No Data',
          transferingQty: stockInfo ? `${stockInfo.currentQty} ${safeJSONParse(stockInfo.unitInfo)?.unitName}` : '-',
          status: stockInfo?.stockStatus === 4 || !stockInfo || !sameLocation ? 'Not Eligible' : 'Eligible',
        };
      })
    );
  }, [data.barcode, stocks]);

  useEffect(() => {
    if (stockAddedStatus?.data?.affectedRows > 0) {
      setInitiated(false);
      setData({
        name: '',
        phoneNumber: '94',
        note: '',
        barcode: [],
        stockIdList: [],
      });
      setTableData([]);
    }
  }, [stockAddedStatus]);

  const initiateTransfer = () => {
    setInitiated(true);
    if (!initiated) {
      const invalidBarcodes = tableData?.filter((value) => value?.transferingQty === '-' || value?.foodName === 'No Data');
      const barcodesInWrongStatus = tableData?.filter((value) => value?.status === 'Not Eligible');
      if (invalidBarcodes.length > 0) {
        setInitiated(false);
        toast.error(`${invalidBarcodes?.length} Invalid Barcodes Identified! Please remove them and try again.`);
      } else if (barcodesInWrongStatus.length > 0) {
        setInitiated(false);
        toast.error(`${barcodesInWrongStatus?.length} Barcodes are in current delivery status.`);
      } else if (data.name?.length === 0) {
        setInitiated(false);
        toast.error(`Responsible person name is required!`);
      } else if (data.phoneNumber?.length !== 11) {
        setInitiated(false);
        toast.error(`Please enter valid phone number!`);
      } else {
        dispatch(addStockTransferSender(data));
      }
    }
  };

  return (
    <Page title='Sender - Transfer Stock'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Sender - Transfer Stock'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.transfer.sender }, { name: 'Sender - Transfer Stock' }]}
          action={[]}
        />
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card
                  sx={{
                    p: 3,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eee',
                    },
                  }}
                >
                  <TextField
                    type='text'
                    label={`Enter Barcode`}
                    sx={{ width: '100%' }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.target.select();
                        if (!data?.barcode?.includes(event.target.value)) {
                          playSuccessSound();
                          setData({
                            ...data,
                            barcode: [].concat(data.barcode, [event.target.value]),
                          });
                        }
                      }
                    }}
                  />
                  <br />
                  <br />

                  <DataGridTable
                    name={'Entered Items'}
                    data={tableData}
                    column={columns}
                    isLoading={false}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    isRowClickable={true}
                    enableRowSelection={false}
                    keyField={'stockId'}
                    enablePinning={false}
                    enableRowActions={true}
                    maxHeight={`40vh`}
                    rowsPerPage={8}
                    renderRowActionItems={(row) => [
                      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }} key={`view-stock-${row.original.stockId}`}>
                        <IconButton
                          color='primary'
                          onClick={() => {
                            setData({
                              ...data,
                              barcode: data.barcode.filter((barcode) => barcode !== row.original.barCode),
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>,
                    ]}
                  />
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Responsible Person Details
                    </Typography>
                    <TextField type='text' label={`Name`} value={data?.name} onChange={(event) => setData({ ...data, name: event.target.value })} />

                    <TextField
                      type='text'
                      label={`Contact Number`}
                      placeholder={`Enter your phone number (+94)`}
                      value={data?.phoneNumber}
                      onChange={(event) => {
                        setData({ ...data, phoneNumber: event.target.value });
                      }}
                    />

                    <TextField
                      type='text'
                      label={`Note`}
                      placeholder={`Address / Vehicle Number etc`}
                      value={data?.note}
                      onChange={(event) => {
                        setData({ ...data, note: event.target.value });
                      }}
                    />

                    <LoadingButton
                      variant='contained'
                      size='large'
                      style={{ width: '100%' }}
                      onClick={() => {
                        initiateTransfer();
                      }}
                    >
                      Initiate Transfer
                    </LoadingButton>
                  </Stack>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default Sender;

import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, SwipeableDrawer } from '@mui/material';
import { closePOSDrawer, getPOSDrawerDetails, hidePOSCloseTab, hidePOSTab, viewPOSCloseTab } from '../../../redux/slices/posRedux/posRedux';
import { useSelector, dispatch } from '../../../redux/store';
import { PieChart } from '@mui/x-charts/PieChart';
import { getPaymentTypes } from '../../../redux/slices/financeRedux/paymentTypeRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { tableCellStyle, tableHeaderStyle, tableRowStyle, topicStyling } from '../../../utils/cssStyles';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';

export default function POSClose() {
  let paymentTypes;
  ({ data: paymentTypes } = useSelector((state) => state.paymentTypes));
  const { viewPosCloseStatus, posDrawerData } = useSelector((state) => state?.pos);
  const { loggedInUser } = useSelector((state) => state?.user);
  const [paymentDataForPieChart, setPaymentDataForPieChart] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState([]);
  const [posDataList, setPosDataList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const { selectedLocation } = useSelector((state) => state?.location);

  useEffect(() => {
    if (viewPosCloseStatus) {
      dispatch(getPOSDrawerDetails(loggedInUser?.posCashDrawerID));
      dispatch(getPaymentTypes());
      setPaymentDetails([]);
      setSelectedPaymentType([]);
    }
  }, [viewPosCloseStatus]);

  useEffect(() => {
    if (posDrawerData?.posData?.length > 0) {
      setPosDataList({
        ...posDrawerData,
        posData: [].concat(...posDrawerData?.posData, [
          {
            id: 'Starting Amount',
            status: 'Opening',
            posPayments: [
              {
                amount: posDrawerData?.startingAmount,
                paymentTypesId: 1,
                id: 1,
                posId: 0,
              },
            ],
          },
        ]),
      });
    } else {
      setPosDataList([]);
    }
  }, [posDrawerData]);

  const sumPosPaymentsByType = (posData) => {
    const sumByType = {};
    posData?.forEach((data) => {
      if (!['Refunded', 'Canceled'].includes(data?.status)) {
        data?.posPayments?.forEach((payment) => {
          const typeId = payment.paymentTypesId;
          if (sumByType[typeId]) {
            sumByType[typeId] += payment.amount;
          } else {
            sumByType[typeId] = payment.amount;
          }
        });
      }
    });
    return sumByType;
  };

  const getPosDataBasedOnPaymentType = (posData) => {
    const posDataBasedOnPaymentType = {};

    posData?.forEach((data) => {
      data?.posPayments?.forEach((payment) => {
        const typeId = payment.paymentTypesId;
        if (!posDataBasedOnPaymentType[typeId]) {
          posDataBasedOnPaymentType[typeId] = {
            totalAmount: 0,
            details: [],
          };
        }
        posDataBasedOnPaymentType[typeId].totalAmount += payment.amount;
        posDataBasedOnPaymentType[typeId].details.push({
          posId: data?.id,
          posStatus: data?.status,
          amount: payment.amount,
          paymentTypeId: payment?.paymentTypesId,
          paymentTypeName: paymentTypes?.find((value) => value?.id === payment?.paymentTypesId)?.name,
        });
      });
    });
    return posDataBasedOnPaymentType;
  };

  useEffect(() => {
    const sumByType = sumPosPaymentsByType(posDataList?.posData);
    setPaymentDataForPieChart(
      paymentTypes
        ?.map((paymentType) => {
          return {
            id: paymentType?.id,
            value: sumByType[paymentType?.id] || 0,
            label: `${paymentType?.name} ( ${fCurrency(sumByType[paymentType?.id] || 0)} )`,
          };
        })
        ?.filter((paymentType) => sumByType[paymentType?.id])
    );
  }, [paymentTypes, posDataList]);

  const handleClick = (event, itemIdentifier, item) => {
    setPaymentDetails(getPosDataBasedOnPaymentType(posDataList?.posData)[item?.id]?.details || []);
    setSelectedPaymentType(paymentDataForPieChart[itemIdentifier?.dataIndex]);
  };

  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={viewPosCloseStatus}
      onOpen={() => {
        dispatch(viewPOSCloseTab());
      }}
      onClose={() => {
        dispatch(hidePOSCloseTab());
      }}
      PaperProps={{
        sx: {
          width: '98%',
          height: '98%',
          marginLeft: '1%',
          marginTop: '1%',
          borderRadius: '10px',
        },
      }}
    >
      <Grid container spacing={3} p={3}>
        <Grid item xs={12} md={12}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <h1>Registry Status</h1>
            <Box sx={{ fontSize: 30, ml: 2, cursor: 'pointer' }}>
              <a
                onClick={() => {
                  dispatch(hidePOSCloseTab());
                }}
              >
                x
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <p style={topicStyling}>Payment Chart</p>
          <PieChart
            series={[
              {
                data: paymentDataForPieChart,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30 },
              },
            ]}
            height={250}
            onClick={handleClick}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '75vh', overflowY: 'auto', p: 3 }}>
            <p style={topicStyling}>Payment Details {selectedPaymentType?.label ? `of ${selectedPaymentType?.label}` : `(Click on pie)`}</p>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={tableHeaderStyle}>POS ID</th>
                  <th style={tableHeaderStyle}>Status</th>
                  <th style={tableHeaderStyle}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {paymentDetails?.map((posData) => (
                  <tr key={posData?.posId} style={tableRowStyle}>
                    <td style={tableCellStyle}>{posData?.posId}</td>
                    <td style={tableCellStyle}>{posData?.posStatus}</td>
                    <td style={tableCellStyle}>{posData?.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          {' '}
        </Grid>
        <Grid item xs={12} md={4}>
          <LoadingButton
            type='submit'
            variant='contained'
            size='large'
            style={{ width: '100%' }}
            onClick={() => {
              if (
                posDataList?.posData?.filter((value) => {
                  return value?.status === 'Preparing';
                }).length > 0
              ) {
                toast.error('Preparing orders are available. Please complete them before closing the register.');
              } else {
                if (confirm('Are you sure you want to close the register?')) {
                  dispatch(hidePOSCloseTab());
                  dispatch(
                    closePOSDrawer({
                      drawerId: posDrawerData?.posCashDrawersId,
                      locationId: selectedLocation?.id,
                      locationName: selectedLocation?.name,
                    })
                  );
                }
              }
            }}
          >
            Close Register
          </LoadingButton>
        </Grid>
        <Grid item xs={12} md={4}>
          {' '}
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}

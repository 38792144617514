import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Button, Card, Container, Grid, IconButton, MenuItem, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import DataGridTable from '../../../components/table/DataGridTable';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import { LoadingButton, TimePicker } from '@mui/lab';
import { addLocation, getLocations, updateLocation } from '../../../redux/slices/settingsManagementRedux/locationsRedux';
import { colorPresets } from '../../../utils/getColorPresets';
import MenuPopover from '../../../components/MenuPopover';
import { accessVerify, capitalize } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function LocationManagement() {
  const dataModel = {
    id: null,
    priority: null,
    name: null,
    address: null,
    colorCode: null,
    isPosAvailable: false,
    startTime: null,
    endTime: null,
    contactNumber: null,
    monthSalesTarget: null,
    invoiceName: null,
  };

  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [locationList, setLocationList] = useState([]);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const { data, addData } = useSelector((state) => state.location);

  useEffect(() => {
    setLocationList(data);
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setSelectedObject(dataModel);
      } else {
        setSelectedObject(modelData);
      }
    }
  };

  const NewProductSchema = Yup.object().shape({
    priority: Yup.number().required('Priority is required'),
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    colorCode: Yup.string().required('Color Code is required'),
    startTime: Yup.string().required('Start Time is required'),
    endTime: Yup.string().required('End Time is required'),
    contactNumber: Yup.string().required('Contact Number is required'),
    invoiceName: Yup.string().required('Invoice Name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      priority: selectedObject?.priority || '',
      name: selectedObject?.name || '',
      address: selectedObject?.address || '',
      colorCode: selectedObject?.colorCode || '',
      isPosAvailable: selectedObject?.isPosAvailable || '',
      startTime: moment(selectedObject?.startTime, 'HH:mm:ss') || null,
      endTime: moment(selectedObject?.endTime, 'HH:mm:ss') || null,
      contactNumber: selectedObject?.contactNumber || '',
      invoiceName: selectedObject?.invoiceName || '',
      monthSalesTarget: selectedObject?.monthSalesTarget || '',
    }),
    [selectedObject]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isAdd && selectedObject) {
      setValue('startTime', null);
      setValue('endTime', null);
      reset(defaultValues);
    }
    if (!isAdd) {
      setValue('startTime', null);
      setValue('endTime', null);
      reset(defaultValues);
    }
  }, [isAdd, selectedObject]);

  const onSubmit = async (value) => {
    try {
      const tempDataObj = {
        priority: value?.priority || '',
        name: value?.name || '',
        address: value?.address || '',
        colorCode: value?.colorCode || '',
        contactNumber: value?.contactNumber || '',
        invoiceName: value?.invoiceName || '',
        monthSalesTarget: value?.monthSalesTarget || '',
        isPosAvailable: value?.isPosAvailable || false,
        startTime: moment(value?.startTime).format('HH:mm:ss') || '',
        endTime: moment(value?.endTime).format('HH:mm:ss') || '',
      };
      if (isAdd) {
        dispatch(addLocation(tempDataObj));
      } else {
        dispatch(updateLocation({ ...tempDataObj, id: selectedObject?.id }));
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      setValue('startTime', null);
      setValue('endTime', null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getLocations());
    }
  }, [addData]);

  const columns = !locationList?.[0]
    ? []
    : Object.keys(locationList?.[0]).map((value) => {
        if (value === 'colorCode') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) =>
              colorPresets?.filter((colorPresent) => {
                return colorPresent?.main === cell.getValue();
              })[0]?.name,
          };
        } else if (value === 'isPosAvailable') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => (cell.getValue() === 1 ? 'Yes' : 'No'),
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      });

  const [rowSelection, setRowSelection] = useState(false);
  return (
    <Page title='Location List'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Location List'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Settings',
              href: PATH_DASHBOARD.settings.root,
            },
            { name: 'Location List' },
          ]}
          action={
            <Button variant='contained' startIcon={<Iconify icon='eva:plus-fill' />} onClick={() => manageModel(dataModel, 'add')}>
              New Location
            </Button>
          }
        />

        {accessVerify('BUSINESS_LOCATIONS_VIEW') ? (
          <DataGridTable
            name={'Locations'}
            data={locationList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={false}
            isRowClickable={true}
            onRowClick={(value) => {
              manageModel(value?.original, 'edit');
            }}
            renderRowActionItems={(value) => []}
          />
        ) : (
          <PermissionRequired />
        )}
        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Location</h1>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Location Info
                    </Typography>
                    <Stack spacing={3}>
                      <RHFTextField name='priority' label='Priority' type={'number'} />
                      <RHFTextField name='name' label='Location Name' />
                      <RHFTextField name='invoiceName' label='Invoice Name' />
                      <RHFTextField name='contactNumber' label='Location Contact Number' />
                      <RHFTextField name='address' label='Location Address' />
                      <RHFTextField name='monthSalesTarget' label='Monthly Sale Target' />
                      <Controller
                        name='startTime'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TimePicker
                            label='Start Time'
                            value={field.value}
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
                          />
                        )}
                      />
                      <Controller
                        name='endTime'
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TimePicker
                            label='Close Time'
                            value={field.value}
                            onChange={(newValue) => {
                              field.onChange(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
                          />
                        )}
                      />
                      <RHFCheckbox name='isPosAvailable' label='Is POS Available?' />

                      <Button
                        onClick={handleOpen}
                        sx={{
                          height: 40,
                          bgcolor: 'background.neutral',
                          ...(open && { bgcolor: 'action.selected' }),
                        }}
                      >
                        <Box
                          sx={{
                            width: 24,
                            height: 14,
                            borderRadius: '50%',
                            bgcolor: colorPresets?.find((value) => value?.main === selectedObject?.colorCode)?.main,
                            marginRight: '10px',
                            transition: (theme) =>
                              theme.transitions.create('all', {
                                easing: theme.transitions.easing.easeInOut,
                                duration: theme.transitions.duration.shorter,
                              }),
                          }}
                        />
                        <p>{colorPresets?.find((value) => value?.main === selectedObject?.colorCode)?.name || 'Select Color Code'}</p>
                      </Button>
                      <MenuPopover
                        open={Boolean(open)}
                        anchorEl={open}
                        onClose={handleClose}
                        sx={{
                          mt: 1.5,
                          ml: 0.75,
                          width: 180,
                          maxHeight: 220,
                          overflowY: 'auto',
                          '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                          },
                        }}
                      >
                        {colorPresets.map((option) => (
                          <MenuItem
                            key={option.id}
                            selected={option.name === selectedObject?.main}
                            onClick={() => {
                              setSelectedObject({
                                ...getValues(),
                                id: selectedObject?.id,
                                colorCode: option?.main,
                              });
                              setValue('colorCode', option?.main);
                              handleClose();
                            }}
                          >
                            <Box
                              sx={{
                                width: 24,
                                height: 14,
                                borderRadius: '50%',
                                bgcolor: option?.main,
                                transform: 'rotate(-45deg)',
                                marginRight: '10px',
                                transition: (theme) =>
                                  theme.transitions.create('all', {
                                    easing: theme.transitions.easing.easeInOut,
                                    duration: theme.transitions.duration.shorter,
                                  }),
                                ...(option.name === selectedObject?.name && {
                                  transform: 'none',
                                }),
                              }}
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </MenuPopover>
                    </Stack>
                  </Card>
                </Stack>
              </Grid>

              <Grid item xs={12} md={4}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' loading={isSubmitting} style={{ width: '100%' }}>
                      {isAdd ? 'Create Location' : 'Save Changes'}
                    </LoadingButton>
                    <Button color='info' variant='outlined' size='large' loading={isSubmitting} style={{ width: '100%' }} onClick={() => manageModel(dataModel, 'add')}>
                      Close
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function getDirection(value = 'bottom') {
  return {
    top: 'to top',
    right: 'to right',
    bottom: 'to bottom',
    left: 'to left',
  }[value];
}

// ----------------------------------------------------------------------

export default function cssStyles(theme) {
  return {
    bgBlur: (props) => {
      const color = props?.color || theme?.palette.background.default || '#000000';

      const blur = props?.blur || 6;
      const opacity = props?.opacity || 0.8;

      return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`, // Fix on Mobile
        backgroundColor: alpha(color, opacity),
      };
    },
    bgGradient: (props) => {
      const direction = getDirection(props?.direction);
      const startColor = props?.startColor || `${alpha('#000000', 0)} 0%`;
      const endColor = props?.endColor || '#000000 75%';

      return {
        background: `linear-gradient(${direction}, ${startColor}, ${endColor});`,
      };
    },
    bgImage: (props) => {
      const url = props?.url || 'https://minimal-assets-api.vercel.app/assets/images/bg_gradient.jpg';
      const direction = getDirection(props?.direction);
      const startColor = props?.startColor || alpha(theme?.palette.grey[900] || '#000000', 0.88);
      const endColor = props?.endColor || alpha(theme?.palette.grey[900] || '#000000', 0.88);

      return {
        background: `linear-gradient(${direction}, ${startColor}, ${endColor}), url(${url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      };
    },
  };
}

export const tableHeaderStyle = {
  fontSize: '14px',
  background: '#f9f4ff',
  padding: '10px',
  borderBottom: '1px solid #ddd',
  zIndex: 1000,
};

export const tableRowStyle = {
  borderBottom: '1px solid #ddd',
};

export const tableCellStyle = {
  fontSize: '13px',
  padding: '6px',
  textAlign: 'center',
};

export const topicStyling = {
  textAlign: 'left',
  fontSize: '17px',
  fontWeight: 600,
  borderBottom: '1px solid #bbbbbb',
  paddingBottom: '2px',
  marginBottom: '10px',
  color: '#616161',
};

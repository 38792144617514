import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { processKaleBinda } from '../../../../redux/slices/customerRedux/customerRedux';
import { useSelector } from '../../../../redux/store';
export default function Game({ setCurrentPage, phone }) {
  const dispatch = useDispatch();
  const [clickedKale, setClickedKale] = useState(0);
  let auruduGift;
  ({ auruduGift } = useSelector((state) => state?.customers));

  useEffect(() => {
    if (clickedKale !== 0) {
      dispatch(processKaleBinda({ clickedKale: clickedKale, phone: phone }));
    }
  }, [clickedKale]);

  useEffect(() => {
    if (auruduGift?.data?.uuid) {
      const uuid = auruduGift.data.uuid;
      let currentUrl = window.location.href;

      if (!currentUrl.endsWith('/')) {
        currentUrl += '/';
      }

      const newUrl = `${currentUrl}${uuid}`;
      window.location.href = newUrl;
    }
  }, [auruduGift]);

  return (
    <div className='fixedMobileSizeForGame'>
      <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712235296/aurudu/aththa.png'} className={'aththa'} alt='aththa' />
      <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712235036/aurudu/sun.png'} className={'sun'} alt='sun' />
      <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712234705/aurudu/LANUWA.png'} className={'lanuwa'} alt='aurudu' />
      {clickedKale === 1 ? (
        <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712237247/aurudu/kadunaKale.png'} className={'kale kadunaKale1'} alt='aurudu' />
      ) : (
        <a
          onClick={() => {
            setClickedKale(1);
          }}
        >
          <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712234380/aurudu/kale.png'} className={'kale kale1'} alt='aurudu' />
        </a>
      )}
      {clickedKale === 2 ? (
        <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712237247/aurudu/kadunaKale.png'} className={'kale kadunaKale2'} alt='aurudu' />
      ) : (
        <a
          onClick={() => {
            setClickedKale(2);
          }}
        >
          <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712234380/aurudu/kale.png'} className={'kale kale2'} alt='aurudu' />
        </a>
      )}
      {clickedKale === 3 ? (
        <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712237247/aurudu/kadunaKale.png'} className={'kale kadunaKale3'} alt='aurudu' />
      ) : (
        <a
          onClick={() => {
            setClickedKale(3);
          }}
        >
          <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712234380/aurudu/kale.png'} className={'kale kale3'} alt='aurudu' />
        </a>
      )}
    </div>
  );
}

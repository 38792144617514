import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import merge from 'lodash/merge';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Button, DialogActions, IconButton, List, ListItem, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import { useDispatch, useSelector } from '../../../../../redux/store';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import moment from 'moment';
import { addLeave, deleteLeave, updateLeave } from '../../../../../redux/slices/hrRedux/UserManagementRedux/userLeaveRedux';
import { dayLeaveType, leaveStatus, leaveTypes } from '../../../../../utils/constants';
import UploadMultiFile from '../../../../../components/upload/UploadMultiFile';
import { imageUpload } from '../../../../../inteceptor';
import { AnimatePresence, m } from 'framer-motion';
import Image from '../../../../../components/Image';
import { alpha } from '@mui/material/styles';
import { safeJSONParse } from '../../../../../utils/common';
import { addDays } from 'date-fns';

// ----------------------------------------------------------------------
const getInitialValues = (event, range) => {
  const _event = {
    title: '',
    description: '',
    textColor: '#FFC107',
    allDay: false,
    leaveType: null,
    dayLeaveType: 1,
    start: range ? new Date(range.start) : new Date(),
    end: range ? new Date(range.end) : new Date(),
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

LeaveForm.propTypes = {
  event: PropTypes.object,
  locationData: PropTypes.object,
  departmentData: PropTypes.object,
  userId: PropTypes.string,
  range: PropTypes.object,
  onCancel: PropTypes.func,
};

export default function LeaveForm({ event, range, onCancel, userId }) {
  const dispatch = useDispatch();
  let userListData;
  let selectedUser;
  let loggedInUser;
  ({ data: userListData, loggedInUser } = useSelector((state) => state.user));

  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setUploadedFiles(safeJSONParse(event?.documents) || []);
  }, [event]);

  const EventSchema = Yup.object().shape({
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (submittedData) => {
    const status = submittedData?.status || leaveStatus?.[0]?.id;
    const leaveType = leaveTypes?.find((value) => value.id === submittedData?.leaveType);
    const selectedLeaveStatus = leaveStatus?.find((value) => value?.id === status) || leaveStatus?.[0];
    const selectedUser = userListData?.find((value) => value?.id === (userId === 'own' ? loggedInUser?.id : userId === 'all' ? submittedData?.userId : userId));
    try {
      const newEvent = {
        id: submittedData?.id || null,
        userId: selectedUser?.id,
        title: `(${selectedLeaveStatus?.name}) - ${selectedUser?.name || 'Name not found'} || ${leaveType?.name || 'Leave Type'} || ${moment(submittedData.start).format('DD/MM/YYYY hh:mm A')} - ${moment(submittedData.end).format('DD/MM/YYYY 23:59:59')}`,
        description: submittedData.description,
        textColor: selectedLeaveStatus.color,
        allDay: submittedData.allDay,
        leaveType: submittedData?.leaveType,
        dayLeaveType: submittedData?.dayLeaveType,
        approvedBy: submittedData?.approvedBy,
        status: status,
        start: moment(submittedData.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(submittedData.end).format('YYYY-MM-DD 23:59:59'),
        documents: uploadedFiles,
      };
      if (event.id) {
        dispatch(updateLeave(newEvent));
      } else {
        dispatch(addLeave(newEvent));
      }
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (!event.id) return;
    try {
      onCancel();
      dispatch(deleteLeave({ id: event.id }));
    } catch (error) {
      console.error(error);
    }
  };

  const values = watch();
  const handleStartDateChange = (newStartDate) => {
    const newEndDate = new Date(newStartDate);
    methods.setValue('start', newStartDate);
    methods.setValue('end', newEndDate);
  };

  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `hr/leave/documents/User_${loggedInUser?.id}`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };

  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: true,
              };
            }
            return fileValue;
          });
        });

        const data = await uploadFiles(file);

        setFiles((value) => {
          return value.filter((fileValue, index) => file?.id !== fileValue?.id);
        });
        if (data?.status) {
          setUploadedFiles((value) => {
            return [...value, { id: -1, url: data?.url }];
          });
        }
      } catch (error) {
        console.error(error);
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: false,
              };
            }
            return fileValue;
          });
        });
      }
    }
    setIsUploading(false);
  };

  const getImageList = () => uploadedFiles?.map((value) => value?.url);

  const isHr = () => userId === 'all';

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <Controller
          name='userId'
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={userListData?.find((value) => value.id === field.value)?.name || null}
              onChange={(event, newValue) => {
                field.onChange(newValue?.id || null);
              }}
              options={userListData?.map((value) => ({
                ...value,
                label: value?.name,
                value: value?.id,
              }))}
              disabled={true}
              renderInput={(params) => <TextField label='Leave Applying User' {...params} />}
            />
          )}
        />

        <RHFTextField name='title' label='Auto Generated Title' disabled={true} />

        <RHFTextField name='description' label='Description' multiline rows={4} disabled={isHr()} />

        <Controller
          name='leaveType'
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              disabled={isHr()}
              value={leaveTypes?.find((value) => value.id === field.value)?.name || null}
              onChange={(event, newValue) => {
                field.onChange(newValue?.id || null);
              }}
              options={leaveTypes?.map((value) => ({
                ...value,
                label: value?.name,
                value: value?.id,
              }))}
              renderInput={(params) => <TextField label='Leave Type' {...params} />}
            />
          )}
        />

        <Controller
          name='dayLeaveType'
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={dayLeaveType?.find((value) => value.id === field.value)?.name || null}
              onChange={(event, newValue) => {
                field.onChange(newValue?.id || null);
              }}
              options={dayLeaveType?.map((value) => ({
                ...value,
                label: value?.name,
                value: value?.id,
              }))}
              disabled={isHr()}
              renderInput={(params) => <TextField label='Day Leave Type' {...params} />}
            />
          )}
        />

        <Controller
          name='start'
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              {...field}
              label='Start date'
              inputFormat={dayLeaveType?.find((value) => value?.id === values.dayLeaveType)?.startTime || 'dd/MM/yyyy HH:mm:ss'}
              renderInput={(params) => <TextField {...params} fullWidth />}
              onChange={handleStartDateChange}
              disabled={isHr()}
              minDate={values.leaveType === 1 ? addDays(new Date(), 5) : undefined} // Set minDate conditionally
            />
          )}
        />
        <Controller
          name='end'
          control={control}
          render={({ field }) => (
            <MobileDatePicker
              {...field}
              label='End date'
              disabled={true}
              inputFormat={dayLeaveType?.find((value) => value?.id === values.dayLeaveType)?.endTime || 'dd/MM/yyyy HH:mm:ss'}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          )}
        />

        <Controller
          name='status'
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              value={leaveStatus?.find((value) => value.id === field.value)?.name || null}
              onChange={(event, newValue) => {
                if (isHr()) {
                  field.onChange(newValue?.id || null);
                }
              }}
              options={leaveStatus?.map((value) => ({
                ...value,
                label: value?.name,
                value: value?.id,
              }))}
              disabled={!isHr()}
              renderInput={(params) => <TextField label='Status' {...params} />}
            />
          )}
        />
        <UploadMultiFile
          error={false}
          showPreview={true}
          files={files}
          onRemove={(removedFile) => {
            setFiles(files.filter((file) => file !== removedFile));
          }}
          onRemoveAll={() => {
            setFiles([]);
          }}
          helperText={''}
          onDrop={(uploadedFiles) => {
            setFiles((value) => [
              ...value,
              ...uploadedFiles.map((file, index) =>
                Object.assign(file, {
                  id: index,
                  preview: URL.createObjectURL(file),
                  isUploading: false,
                })
              ),
            ]);
          }}
          onUpload={() => {
            handleImageUpload();
          }}
          isUploading={isUploading}
        />

        {getImageList()?.length > 0 && (
          <Typography variant='subtitle1' padding='10px 0'>
            Uploaded Images
          </Typography>
        )}
        <List disablePadding sx={{ my: 3 }}>
          <AnimatePresence>
            {getImageList().map((src, index) => (
              <>
                <ListItem
                  key={index}
                  component={m.div}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'inline-flex',
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <Image alt='preview' src={src} ratio='1/1' />
                  <IconButton
                    size='small'
                    sx={{
                      top: 6,
                      p: '2px',
                      right: 6,
                      position: 'absolute',
                      color: 'common.white',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      },
                    }}
                  ></IconButton>
                </ListItem>
              </>
            ))}
          </AnimatePresence>
        </List>
        {/*<Controller name='textColor' control={control} render={({ field }) => <ColorSinglePicker value={field.value} onChange={field.onChange} colors={COLOR_OPTIONS} />} />*/}
      </Stack>

      <DialogActions>
        {/*{!isCreating && (*/}
        {/*  <Tooltip title='Delete Event'>*/}
        {/*    <IconButton onClick={handleDelete}>*/}
        {/*      <Iconify icon='eva:trash-2-outline' width={20} height={20} />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*)}*/}
        <Box sx={{ flexGrow: 1 }} />

        <Button variant='outlined' color='inherit' onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
          {event.id ? 'Save Changes' : 'Create Rost'}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}

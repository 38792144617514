import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from '../../../../../../utils/formatNumber';
import { truncateStringValue } from '../../../../../../utils/common';

export default function MenuItemBlock({ title, image, code, price, onClickSound, isVariation, variationMethod, addToCart }) {
  const handleOnClick = () => {
    if (isVariation) {
      onClickSound();
      variationMethod();
    } else {
      onClickSound();
      addToCart();
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        height: '100%',
      }}
    >
      <Card
        sx={{
          width: '100%',
          margin: '5px',
          border: '0.5px solid #E7E7E7',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onClick={() => {
          handleOnClick();
        }}
      >
        <CardMedia component='img' height='150' image={image} />
        <h4
          style={{
            fontSize: '16px',
            padding: '10px 10px',
          }}
        >
          {title}
        </h4>
        <p
          style={{
            fontSize: '12px',
            color: '#9E9E9E',
            padding: '0px 10px',
          }}
        >
          {truncateStringValue(code, 30)}
        </p>

        <LoadingButton
          variant='outlined'
          size='small'
          style={{
            width: '95%',
            margin: '2.5%',
          }}
        >
          {isVariation ? <>Select Variation Item</> : <>Add ({fCurrency(price)})</>}
        </LoadingButton>
      </Card>
    </div>
  );
}

import { TextField } from '@mui/material';
import React from 'react';

export default function ExField({ code, label, dataModel, handleUpdate, ...other }) {
  const internalValue = other?.value ? other?.value || null : dataModel?.[code] || null;
  return (
    <TextField
      label={label}
      value={dataModel?.[code]}
      onChange={(e) => handleUpdate(code, e.target.value)}
      fullWidth
      InputLabelProps={{ shrink: Boolean(internalValue) }}
      notched={{ shrink: Boolean(internalValue) }}
      {...other}
    />
  );
}

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Card, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { fCurrency, fShortenNumber } from '../../../../utils/formatNumber';
import React, { useEffect, useState } from 'react';
import { getLocationBasedSalesPerMonth } from '../../../../redux/slices/saleRedux/salesRedux';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../redux/store';
import moment from 'moment';
import DashboardStatBox from './DashboardStatBox';

const RootStyle = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff8f8',
  padding: theme.spacing(2, 2, 2, 3),
}));

SalesTargetViewer.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function SalesTargetViewer({ title }) {
  const dispatch = useDispatch();

  let locationsList;
  let selectedLocation;
  ({ data: locationsList, selectedLocation } = useSelector((state) => state.location));

  const { locationBasedSalesPerMonth } = useSelector((state) => state?.sales);

  const [currentSale, setCurrentSale] = useState(0);
  const [salesTarget, setSalesTarget] = useState(0);

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    dispatch(getLocationBasedSalesPerMonth(currentYear, currentMonth));
  }, [dispatch]);

  useEffect(() => {
    setCurrentSale(locationBasedSalesPerMonth?.filter((value) => selectedLocation?.id === 1 || selectedLocation?.id === Number(value.locationId))?.reduce((acc, value) => acc + value.soldAmount, 0));

    setSalesTarget(locationsList?.filter((value) => selectedLocation?.id === 1 || selectedLocation?.id === Number(value.id))?.reduce((acc, value) => acc + value.monthSalesTarget, 0));
  }, [locationBasedSalesPerMonth, selectedLocation, locationsList]);

  const getNumberOfDaysLeft = () => {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const daysLeft = endOfMonth.getDate() - now.getDate();
    return daysLeft;
  };

  useEffect(() => {}, [currentSale, salesTarget, selectedLocation]);
  const getCompletedPercentage = () => {
    const currentSaleNumber = parseFloat(currentSale);
    const salesTargetNumber = parseFloat(salesTarget);
    if (!isNaN(currentSaleNumber) && !isNaN(salesTargetNumber) && salesTargetNumber !== 0) {
      return (currentSaleNumber / salesTargetNumber) * 100;
    } else {
      return 0;
    }
  };

  return (
    <RootStyle>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: 120 }}>
              <Typography variant='h6' sx={{ color: 'text.secondary' }}>
                {title}
              </Typography>
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant='determinate' value={getCompletedPercentage()} color={'error'} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant='body2' color='text.secondary'>{`${Math.round(getCompletedPercentage())}%`}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={2}>
          <DashboardStatBox title='Monthly Sales Target' isIconVisible={false} value={salesTarget} titleOnTop={true} />
        </Grid>
        <Grid item xs={12} md={2}>
          <DashboardStatBox title='Average Monthly Target Per Day' isIconVisible={false} value={fCurrency(salesTarget / moment().daysInMonth())} titleOnTop={true} />
        </Grid>
        <Grid item xs={12} md={2}>
          <DashboardStatBox title='Month to Date Gross Sale' isIconVisible={false} value={fCurrency(currentSale)} titleOnTop={true} />
        </Grid>
        <Grid item xs={12} md={2}>
          <DashboardStatBox title='Balance of Monthly Target' isIconVisible={false} value={salesTarget - currentSale} titleOnTop={true} />
        </Grid>
        <Grid item xs={12} md={2}>
          <DashboardStatBox title='Days Left' isIconVisible={false} value={getNumberOfDaysLeft()} titleOnTop={true} />
        </Grid>
        <Grid item xs={12} md={2}>
          <DashboardStatBox title='Balance of Average Monthly Target Per Day' isIconVisible={false} value={fCurrency((salesTarget - currentSale) / getNumberOfDaysLeft())} titleOnTop={true} />
        </Grid>
      </Grid>
    </RootStyle>
  );
}

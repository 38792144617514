import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  addData: {},
  aurudu: {},
  auruduGift: {},
  addDataCustomerItem: {},
  addCustomerData: {},
  drawerOpeningStatus: {},
  CustomerDrawerData: {},
  customer: [],
  allData: [],
  sorted: [],
  saleTypes: [],
  sendSMS: [],
  viewCustomerStatus: false,
  viewCustomerCloseStatus: false,
};

const slice = createSlice({
  name: 'Customer',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },
    updateCustomerStatus(state, value) {
      state.viewCustomerStatus = value.payload;
    },
    updateCustomerCloseStatus(state, value) {
      state.viewCustomerCloseStatus = value.payload;
    },
    getAllSaleTypesSuccess(state, action) {
      state.saleTypes = action.payload.data;
    },
    getDrawerOpeningStatus(state, action) {
      state.drawerOpeningStatus = action.payload.data;
    },
    getCustomerDrawerData(state, action) {
      state.CustomerDrawerData = action.payload.data;
    },
    getCustomerSuccess(state, action) {
      state.data = action.payload.data;
    },
    getAllCustomerData(state, action) {
      state.allData = action.payload.data;
    },
    auruduCustomer(state, action) {
      state.aurudu = {
        status: action.payload.status,
        time: moment.now().toString(),
      };
    },
    processKaleBinda(state, action) {
      state.auruduGift = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    addOrUpdateCustomer(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    sendSMSSuccess(state, action) {
      state.sendSMS = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    addOrUpdateCustomerItemStatus(state, action) {
      state.addDataCustomerItem = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },
    clearAddOrUpdateCustomer(state) {
      state.addData = { data: null, time: moment.now().toString() };
    },
    clearAll(state) {
      state.addData = {};
      state.error = {};
    },
    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },
    onNextStep(state) {
      state.checkout.activeStep += 1;
    },
    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },
  },
});

export default slice.reducer;

export function getCustomers() {
  return async () => {
    try {
      const response = await get(`customers`);
      if (response.status) {
        dispatch(slice.actions.getAllCustomerData(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function searchCustomers(name, phone) {
  return async () => {
    try {
      const response = await get(`customer_search?name=${name}&phone=${phone}`);
      if (response.status) {
        dispatch(slice.actions.getCustomerSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCustomer(body) {
  return async () => {
    try {
      const response = await post(`addCustomer`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateCustomer(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addAuruduCustomer(body) {
  return async () => {
    try {
      const response = await post(`addAuruduCustomer`, body);
      if (response.status) {
        dispatch(slice.actions.auruduCustomer(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomerFromUUID(id) {
  return async () => {
    try {
      const response = await get(`getCustomerFromUUID?uuid=${id}`);
      if (response.status) {
        dispatch(slice.actions.getCustomerSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function processKaleBinda(body) {
  return async () => {
    try {
      const response = await post(`processKaleBinda`, body);
      if (response.status) {
        dispatch(slice.actions.processKaleBinda(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addLoyaltyRecord(body) {
  return async () => {
    try {
      const response = await post(`addLoyaltyRecord`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateCustomer(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendSMS(body) {
  return async () => {
    try {
      const response = await post(`bulkSend`, body, 'https://api-sms.expergen.com/');
      if (response.status) {
        dispatch(slice.actions.sendSMSSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCustomer(body) {
  return async () => {
    try {
      const response = await put(`updateCustomer`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateCustomer(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

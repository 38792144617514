import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import Iconify from '../../../../components/Iconify';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getAllAssets } from '../../../../redux/slices/assetsRedux/assetsRedux';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../../utils/common';
import {
  ASSET_PAYMENT_STATUS,
  CASHFLOW_PAYMENT_STATUS,
  CASHFLOW_TYPES,
  ORDER_STATUS,
} from '../../../../utils/constants';
import DatePicker from '@mui/lab/DatePicker';
import { getPurchaseNotes } from '../../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import {
  addCashFlows,
  getCashFlowMainCategories,
  getCashFlows, getCashFlowSubCategories,
  updateCashFlows,
} from '../../../../redux/slices/financeRedux/cashFlowRedux';
import { fCurrency } from '../../../../utils/formatNumber';
import { getPaymentTypes } from '../../../../redux/slices/financeRedux/paymentTypeRedux';
import { imageUpload } from '../../../../inteceptor';
import ImageViewer from 'react-simple-image-viewer';
import PermissionRequired from '../../../errorPages/permissionRequired';
import { getCashFlowDocumentType } from 'src/redux/slices/financeRedux/cashFlowDocumentTypeRedux';
import { getAllSuppliers } from '../../../../redux/slices/stockManagementRedux/supplierRedux';
import { getColumnsList } from './components/columns';
import CashFlowImageUploader from './components/cashflowImageUploader';
import CashFlowHistoryTimeline from './components/cashFlowHistoryTimeline';
import { getExpensesByStatus } from '../../../../redux/slices/expenseRedux/expensesRedux';

export default function Cashflow() {
  const { themeStretch } = useSettings();
  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  const dataModel = {
    id: null,
    isDebit: null,
    dateTime: null,
    paymentDate: null,
    scheduledDate: null,
    mainCategoryId: null,
    subCategoryId: null,
    paidDate: null,
    title: '',
    documentType: null,
    description: '',
    checkNo: '',
    type: 1,
    paymentTypeId: 1,
    status: null,
    amount: 0,
    purchaseNoteId: null,
    expenseId: null,
    posCashDrawersID: null,
    assetId: null,
    userId: null,
    locationId: selectedLocation?.id,
    linkedDocuments: [],
    isTransfer: 0,
    transferId: null,
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [cashflowList, setCashflowList] = useState([]);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [documentGallery, setDocumentGallery] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [documentTypeOptionList, setDocumentTypeOptionList] = useState([]);
  const [purchaseNoteList, setPurchaseNoteList] = useState([]);

  const { data, addData, cashFlowMainCategories, cashFlowSubCategories  } = useSelector((state) => state.cashFlow);
  const { loggedInUser } = useSelector((state) => state?.user);

  let rawPurchaseNoteList;
  ({ data: rawPurchaseNoteList } = useSelector((state) => state.purchaseNotes));

  let assetsList;
  ({ allData: assetsList } = useSelector((state) => state.assets));

  let expensesList;
  ({ data: expensesList } = useSelector((state) => state.expenses));

  let tempPaymentTypeList;
  ({ data: tempPaymentTypeList } = useSelector((state) => state.paymentTypes));

  let documentTypeList;
  ({ data: documentTypeList } = useSelector((state) => state.cashFlowDocumentType));

  let supplierList;
  ({ allData: supplierList } = useSelector((state) => state.supplier));

  useEffect(() => {
    setPaymentTypeList(tempPaymentTypeList?.filter((value) => value?.isCashFlowOption === 1));
  }, [tempPaymentTypeList]);

  useEffect(() => {
    dispatch(getCashFlows(selectedLocation?.id));
  }, [selectedLocation]);

  useEffect(() => {
    setDocumentTypeOptionList(documentTypeList?.map((value) => ({ label: value.name, value: value.id })));
  }, [documentTypeList]);

  useEffect(() => {
    setPurchaseNoteList(rawPurchaseNoteList?.filter((value) => value?.status === ORDER_STATUS.RECEIVED.code && value?.paymentforreceivings > value?.paidamount));
  }, [rawPurchaseNoteList]);

  useEffect(() => {
    dispatch(getAllAssets());
    dispatch(getExpensesByStatus(2));
    dispatch(getPurchaseNotes());
    dispatch(getAllSuppliers());
    dispatch(getPaymentTypes());
    dispatch(getCashFlowDocumentType());
    dispatch(getCashFlowMainCategories());
    dispatch(getCashFlowSubCategories());
  }, []);

  useEffect(() => {
    setCashflowList(
      data?.map((value) => {
        return {
          ...value,
          linkedInfo: safeJSONParse(value?.linkedInfo),
          originalStatus: value?.status,
          linkedDocuments: safeJSONParse(value?.linkedInfo)?.cashFlowDocuments?.filter((value) => value?.url) || [],
          cashFlowLog: safeJSONParse(value?.linkedInfo)?.cashFlowLog?.filter((value) => value?.title) || [],
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsViewerOpen(false);
      setIsModelOpen(false);
      setSelectedDataObj(dataModel);
      setDocumentGallery([]);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      setIsViewerOpen(false);
      if (type === 'add') {
        setDocumentGallery([]);
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getCashFlows(selectedLocation?.id));
      dispatch(getAllAssets());
      dispatch(getExpensesByStatus(2));
      dispatch(getPurchaseNotes());
    }
  }, [addData]);

  useEffect(() => {
    setDocumentGallery(selectedDataObj?.linkedDocuments?.map((value) => value?.url));
  }, [selectedDataObj]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.title?.length === 0) {
      toast.error('Title is required!');
    } else if (selectedDataObj.description?.length === 0) {
      toast.error('Description is required!');
    } else if (selectedDataObj.amount === 0) {
      toast.error('Amount is required!');
    } else if (selectedDataObj.type === null) {
      toast.error('Type is required!');
    } else if (files?.length > 0) {
      toast.error('Related documents needs to be uploaded first!');
    } else {
      dispatch(isAdd ? addCashFlows(selectedDataObj) : updateCashFlows(selectedDataObj));
    }
  };

  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `Finance/Cashflow/Loc_${selectedLocation?.id}/User_${loggedInUser?.id}`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };

  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: true,
              };
            }
            return fileValue;
          });
        });

        const data = await uploadFiles(file);

        setFiles((value) => {
          return value.filter((fileValue, index) => file?.id !== fileValue?.id);
        });
        if (data?.status) {
          setSelectedDataObj((prevState) => ({
            ...prevState,
            linkedDocuments: [...prevState.linkedDocuments, { name: file?.name, url: data?.url }],
          }));
        }
      } catch (error) {
        console.error(error);
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: false,
              };
            }
            return fileValue;
          });
        });
      }
    }
    setIsUploading(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Page title={`Cashflow`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Cashflow`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Finance',
              href: '',
            },
            { name: `Cashflow` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Record
            </Button>
          }
        />

        {accessVerify('CASHFLOW_VIEW') ? (
          <DataGridTable
            name={'Assets List'}
            data={cashflowList}
            column={[...getColumnsList(cashflowList, documentTypeOptionList)]}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            isRowClickable={true}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'bottom'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '95%',
              height: '95%',
              marginLeft: '2.5%',
              borderRadius: '10px',
            },
          }}
        >
          <Grid container spacing={1} padding={2}>
            <Grid item xs={12} md={8}>
              <Box sx={{ p: 2 }}>
                <h1>{isAdd ? 'Add' : 'Edit'} Record</h1>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color='info'
                variant='outlined'
                size='large'
                style={{ width: '100%' }}
                onClick={() => {
                  manageModel(dataModel, 'add');
                }}
              >
                Close
              </Button>
              <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%', marginLeft: '8px' }} onClick={handleSubmit}>
                {isAdd ? 'Add Record' : 'Save Changes'}
              </LoadingButton>
            </Grid>

            <Grid item xs={12} md={6} lg={selectedDataObj?.cashFlowLog?.length > 0 ? 4 : 6}>
              <Card sx={{ p: 3 }}>
                <Typography variant='subtitle1' padding='10px 0'>
                  Payment Information
                </Typography>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <DatePicker
                        label='Payment Date'
                        value={selectedDataObj?.paymentDate}
                        onChange={(newValue) => {
                          updateEditingData('paymentDate', newValue);
                        }}
                        disabled={[2, 3, 4].includes(selectedDataObj?.originalStatus)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <Autocomplete
                        onChange={(event, newValue) => {
                          if (isAdd) {
                            updateEditingData('paymentTypeId', paymentTypeList.find((value) => value?.id === newValue?.value)?.id);
                          }
                        }}
                        options={paymentTypeList?.map((value) => ({
                          label: value.name,
                          value: value.id,
                        }))}
                        value={
                          selectedDataObj.paymentTypeId && {
                            label: paymentTypeList?.find((val) => val.id === selectedDataObj.paymentTypeId)?.name || 'Transactional mediums',
                            value: selectedDataObj.paymentTypeId,
                          }
                        }
                        renderInput={(params) => <TextField label='Select Transactional mediums' {...params} />}
                        disabled={!isAdd}
                      />
                      <Autocomplete
                        onChange={(event, newValue) => {
                            setSelectedDataObj({
                              ...selectedDataObj,
                              mainCategoryId: cashFlowMainCategories.find((value) => value?.id === newValue?.value)?.id,
                              subCategoryId: null,
                            });

                        }}
                        options={cashFlowMainCategories?.map((value) => ({
                          label: value.name,
                          value: value.id,
                        }))}
                        value={
                          selectedDataObj.mainCategoryId && {
                            label: cashFlowMainCategories?.find((val) => val.id === selectedDataObj.mainCategoryId)?.name || 'Main Category',
                            value: selectedDataObj.mainCategoryId,
                          }
                        }
                        renderInput={(params) => <TextField label='Select Main Category' {...params} />}
                      />

                      {selectedDataObj.mainCategoryId && <Autocomplete
                        onChange={(event, newValue) => {
                            updateEditingData('subCategoryId', cashFlowSubCategories.find((value) => value?.id === newValue?.value)?.id);

                        }}
                        options={cashFlowSubCategories?.filter(value=> value?.mainCategoryId === selectedDataObj.mainCategoryId)?.map((value) => ({
                          label: value.name,
                          value: value.id,
                        }))}
                        value={
                          selectedDataObj.subCategoryId && {
                            label: cashFlowSubCategories?.find((val) => val.id === selectedDataObj.subCategoryId)?.name || 'Sub Category',
                            value: selectedDataObj.subCategoryId,
                          }
                        }
                        renderInput={(params) => <TextField label="Ledger Type" {...params} />}
                      />}

                      <Autocomplete
                        onChange={(event, newValue) => {
                          updateEditingData('documentType', newValue?.value);
                        }}
                        options={documentTypeOptionList}
                        value={documentTypeOptionList?.find((value) => value?.value === Number(selectedDataObj?.documentType)) || null}
                        renderInput={(params) => <TextField label='Document Type' {...params} />}
                      />
                      <TextField fullWidth label='Document No' value={selectedDataObj?.checkNo} onChange={(e) => updateEditingData('checkNo', e.target.value)} />

                      <Autocomplete
                        onChange={(event, newValue) => {
                          if (isAdd) {
                            updateEditingData('type', CASHFLOW_TYPES.find((value) => value?.id === newValue?.value)?.id);
                          }
                        }}
                        options={CASHFLOW_TYPES.filter((value) => value?.isShow)?.map((value) => ({
                          label: `${value?.name}`,
                          value: value?.id,
                        }))}
                        value={
                          CASHFLOW_TYPES?.find((value) => value.id === selectedDataObj?.type)?.name && {
                            label: `${CASHFLOW_TYPES?.find((value) => value.id === selectedDataObj?.type)?.name || 'Payment Type'}`,
                            value: selectedDataObj?.type,
                          }
                        }
                        renderInput={(params) => <TextField label='Select Payment Type' {...params} />}
                        disabled={!isAdd}
                      />

                      {selectedDataObj?.type === 3 && (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (isAdd) {
                              const selectedPurchaseNote = purchaseNoteList.find((value) => value?.id === newValue?.value);
                              setSelectedDataObj({
                                ...selectedDataObj,
                                purchaseNoteId: selectedPurchaseNote?.id,
                                supplierId: selectedPurchaseNote?.supplierid,
                              });
                            }
                          }}
                          options={purchaseNoteList?.map((value) => {
                            return {
                              label: `${value?.id} - (${fCurrency(Number(value?.paymentforreceivings) - value?.paidamount)}) - (${value?.supplierName} ${value?.supplierCode} - ${value?.supplierCompanyPhone})`,
                              value: value?.id,
                            };
                          })}
                          value={
                            purchaseNoteList?.find((value) => value.id === selectedDataObj?.purchaseNoteId) && {
                              label: `${purchaseNoteList.find((value) => value.id === selectedDataObj.purchaseNoteId).id} - (${fCurrency(Number(purchaseNoteList.find((value) => value.id === selectedDataObj.purchaseNoteId)?.paymentforreceivings) - purchaseNoteList.find((value) => value.id === selectedDataObj.purchaseNoteId)?.paidamount)}) - (${purchaseNoteList.find((value) => value.id === selectedDataObj.purchaseNoteId)?.supplierName} ${purchaseNoteList.find((value) => value.id === selectedDataObj.purchaseNoteId)?.supplierCode} - ${purchaseNoteList.find((value) => value.id === selectedDataObj.purchaseNoteId)?.supplierCompanyPhone})`,
                              value: selectedDataObj?.purchaseNoteId,
                            }
                          }
                          renderInput={(params) => <TextField label='Select Purchase Note' {...params} />}
                          disabled={!isAdd}
                        />
                      )}
                      {selectedDataObj?.type === 8 && (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (isAdd) {
                              const selectedExpense = expensesList.find((value) => value?.id === newValue?.value);
                              setSelectedDataObj({
                                ...selectedDataObj,
                                expenseId: selectedExpense?.id,
                                supplierId: selectedExpense?.supplierId,
                              });
                            }
                          }}
                          options={expensesList?.map((value) => ({
                            label: `${value?.id}) ${value?.title} - (${fCurrency(value?.amount - value?.paidAmount)})`,
                            value: value?.id,
                          }))}
                          value={
                            expensesList?.find((value) => value.id === selectedDataObj?.expenseId)?.name && {
                              label: `${expensesList?.find((value) => value.id === selectedDataObj?.expenseId)?.title || 'Expense is not found'}`,
                              value: selectedDataObj?.id,
                            }
                          }
                          renderInput={(params) => <TextField label='Select Expense Note' {...params} />}
                          disabled={!isAdd}
                        />
                      )}

                      {selectedDataObj?.type === 4 && (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            if (isAdd) {
                              const selectedAsset = assetsList.find((value) => value?.id === newValue?.value);
                              setSelectedDataObj({
                                ...selectedDataObj,
                                assetId: selectedAsset?.id,
                                supplierId: selectedAsset?.supplierId,
                              });
                            }
                          }}
                          options={assetsList
                            ?.filter((value) => [ASSET_PAYMENT_STATUS.PENDING, ASSET_PAYMENT_STATUS.PARTIALLY_PAID].includes(value?.paymentStatus))
                            ?.map((value) => ({
                              label: `${value?.id}) ${value?.name} (${fCurrency(value?.totalValue - value?.paidAmount)})`,
                              value: value?.id,
                            }))}
                          value={
                            assetsList?.find((value) => value.id === selectedDataObj?.assetId)?.name && {
                              label: `${assetsList?.find((value) => value.id === selectedDataObj?.assetId)?.name || 'Purchase Note is not found'}`,
                              value: selectedDataObj?.id,
                            }
                          }
                          renderInput={(params) => <TextField label='Select Linked Asset' {...params} />}
                          disabled={!isAdd}
                        />
                      )}
                      {![1, 2].includes(selectedDataObj?.type) && (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            updateEditingData('supplierId', supplierList.find((value) => value?.id === newValue?.value)?.id);
                          }}
                          options={supplierList?.map((value) => ({
                            label: `${value?.type} - ${value?.name}`,
                            value: value?.id,
                          }))}
                          groupBy={(option) => capitalize(option.label.split(' - ')[0])}
                          renderOption={(props, option) => (
                            <Box component='li' {...props}>
                              {option.label.split(' - ')[1]}
                            </Box>
                          )}
                          value={{
                            label: supplierList.find((value) => value.id === selectedDataObj?.supplierId)?.name || 'Select Supplier',
                            value: supplierList?.supplierId,
                          }}
                          renderInput={(params) => <TextField label='Select Supplier' {...params} variant='outlined' fullWidth />}
                          style={{ width: '100%' }}
                          disabled={!isAdd}
                        />
                      )}

                      <TextField fullWidth label='Title' value={selectedDataObj?.title} onChange={(e) => updateEditingData('title', e.target.value)} />

                      <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />

                      <Autocomplete
                        onChange={(event, newValue) => {
                          if (selectedDataObj?.status !== 3) {
                            updateEditingData('status', CASHFLOW_TYPES.find((value) => value?.id === newValue?.value)?.id);
                          }
                        }}
                        options={CASHFLOW_PAYMENT_STATUS?.filter((value) => (value?.visibleOnAdd && isAdd) || (value?.visibleOnEdit && !isAdd))?.map((value) => ({
                          label: `${value?.name}`,
                          value: value?.id,
                        }))}
                        value={{
                          label: `${CASHFLOW_PAYMENT_STATUS?.find((value) => value.id === selectedDataObj?.status)?.name || 'Payment Status'}`,
                          value: selectedDataObj?.status,
                        }}
                        renderInput={(params) => <TextField label='Payment Status' {...params} />}
                        disabled={selectedDataObj?.originalStatus === 3}
                      />

                      {selectedDataObj?.status === 2 && (
                        <DatePicker
                          label='Scheduled Date'
                          value={selectedDataObj?.scheduledDate}
                          onChange={(newValue) => {
                            updateEditingData('scheduledDate', newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={[2, 3, 4].includes(selectedDataObj?.originalStatus)}
                        />
                      )}

                      {selectedDataObj?.status === 3 && (
                        <DatePicker
                          label='Paid Date'
                          value={selectedDataObj?.paidDate}
                          onChange={(newValue) => {
                            updateEditingData('paidDate', newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={[3, 4].includes(selectedDataObj?.originalStatus === 3)}
                        />
                      )}

                      <TextField
                        fullWidth
                        type='number'
                        label='Amount'
                        value={selectedDataObj?.amount}
                        onChange={(e) => {
                          if (isAdd) {
                            updateEditingData('amount', e.target.value);
                          }
                        }}
                        disabled={!isAdd}
                      />
                    </Stack>
                  </Card>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={selectedDataObj?.cashFlowLog?.length > 0 ? 4 : 6}>
              <CashFlowImageUploader
                files={files}
                setFiles={setFiles}
                handleImageUpload={handleImageUpload}
                documentGallery={documentGallery}
                openImageViewer={openImageViewer}
                isUploading={isUploading}
              />
            </Grid>

            {selectedDataObj?.cashFlowLog?.length > 0 && (
              <Grid item xs={12} md={6} lg={4}>
                <CashFlowHistoryTimeline cashFlowLog={selectedDataObj?.cashFlowLog} />
              </Grid>
            )}

            {isViewerOpen && <ImageViewer src={documentGallery} currentIndex={currentImage} disableScroll={false} closeOnClickOutside={true} onClose={closeImageViewer} />}
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

export default function Conditions({ setCurrentPage }) {
  return (
    <div className='fixedMobileSize'>
      <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712456946/aurudu/Conditions-Recovered.jpg'} className={'welcome'} alt='welcomePage' />

      <a
        onClick={() => {
          setCurrentPage(4);
        }}
      >
        <img src={'https://res.cloudinary.com/bubblemania/image/upload/w_300/aurudu/11.png'} className={'welcomeButton'} alt='button' />
      </a>
    </div>
  );
}

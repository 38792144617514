import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, IconButton, List, ListItem, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getAllAssets } from '../../../redux/slices/assetsRedux/assetsRedux';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import { EXPENSE_STATUS_TYPES } from '../../../utils/constants';
import { getPurchaseNotes } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { getPaymentTypes } from '../../../redux/slices/financeRedux/paymentTypeRedux';
import UploadMultiFile from '../../../components/upload/UploadMultiFile';
import { imageUpload } from '../../../inteceptor';
import ImageViewer from 'react-simple-image-viewer';
import { AnimatePresence, m } from 'framer-motion';
import Image from '../../../components/Image';
import { alpha } from '@mui/material/styles';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import PermissionRequired from '../../errorPages/permissionRequired';
import { getAllSuppliers } from '../../../redux/slices/stockManagementRedux/supplierRedux';
import { addExpenses, getExpenses, updateExpenses } from '../../../redux/slices/expenseRedux/expensesRedux';
import { getExpenseCategories } from '../../../redux/slices/expenseRedux/expenseCategoriesRedux';
import { useParams } from 'react-router-dom';

export default function ViewExpenses() {
  const { mainCategory } = useParams();
  const { themeStretch } = useSettings();
  let selectedLocation;
  ({ selectedLocation } = useSelector((state) => state.location));

  const dataModel = {
    id: null,
    paymentDate: null,
    dateTime: null,
    paidDate: null,
    title: '',
    description: '',
    status: 1,
    amount: 0,
    userId: null,
    locationId: selectedLocation?.id,
    linkedDocuments: [],
    expenseCategoriesId: null,
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [expenseList, setexpenseList] = useState([]);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [documentGallery, setDocumentGallery] = useState([]);
  const [sortedexpenseCategories, setSortedexpenseCategories] = useState([]);

  const { data, addData } = useSelector((state) => state.expenses);
  const { loggedInUser } = useSelector((state) => state?.user);

  let expenseCategories;
  ({ data: expenseCategories } = useSelector((state) => state.expenseCategories));

  let supplierList;
  ({ allData: supplierList } = useSelector((state) => state.supplier));

  useEffect(() => {
    dispatch(getExpenses(selectedLocation?.id));
  }, [selectedLocation]);

  useEffect(() => {
    if (expenseCategories) {
      const sortedCategories = expenseCategories.slice().sort((a, b) => {
        const mainCategoryCompare = a.mainCategory.localeCompare(b.mainCategory, undefined, {
          numeric: true,
          sensitivity: 'base',
        });

        if (mainCategoryCompare === 0) {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        }

        return mainCategoryCompare;
      });
      setSortedexpenseCategories(sortedCategories?.filter((value) => value?.mainCategory === mainCategory));
    }
  }, [expenseCategories]);

  useEffect(() => {
    dispatch(getAllSuppliers());
    dispatch(getExpenseCategories());
  }, []);

  useEffect(() => {
    setexpenseList(
      data
        ?.map((value) => {
          return {
            ...value,
            linkedInfo: safeJSONParse(value?.linkedInfo),
            originalStatus: value?.status,
            linkedDocuments: safeJSONParse(value?.linkedInfo)?.expenseDocuments?.filter((value) => value?.url) || [],
            expenseLog: safeJSONParse(value?.linkedInfo)?.expenseLog?.filter((value) => value?.title) || [],
          };
        })
        ?.filter((value) => {
          return value?.linkedInfo?.expenseCategory?.mainCategory === mainCategory;
        })
    );
    setIsModelOpen(false);
  }, [data, mainCategory]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsViewerOpen(false);
      setIsModelOpen(false);
      setSelectedDataObj(dataModel);
      setDocumentGallery([]);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      setIsViewerOpen(false);
      if (type === 'add') {
        setDocumentGallery([]);
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getExpenses(selectedLocation?.id));
    }
  }, [addData]);

  useEffect(() => {
    setDocumentGallery(selectedDataObj?.linkedDocuments?.map((value) => value?.url));
  }, [selectedDataObj]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const columns = !expenseList?.[0]
    ? []
    : Object.keys(expenseList?.[0])
        .map((value) => {
          if (['linkedInfo', 'linkedDocuments', 'expenseLog'].includes(value)) {
            return {};
          } else if (['isDebit'].includes(value)) {
            return {
              accessorKey: 'isDebit',
              header: 'Debit/Credit',
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.isDebit;
                return (
                  <span key={`${row?.original?.id}_${row?.index}`} style={{ color: rowValue ? 'green' : 'red', fontWeight: 800, align: 'center', letterSpacing: '2px' }}>
                    {rowValue ? 'Debit' : 'Credit'}
                  </span>
                );
              },
            };
          } else if (['dateTime', 'paymentDate', 'paidDate'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.[value];
                const isValidDate = utcMoment(rowValue).isValid();
                return rowValue ? (isValidDate ? utcMoment(rowValue).format('YYYY-MM-DD HH:mm:ss') : 'Not Paid Yet') : 'Not Paid Yet';
              },
            };
          } else if (['status'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                const rowValue = row?.original?.[value];
                const status = EXPENSE_STATUS_TYPES?.find((value) => value?.id === rowValue)?.name;
                return (
                  <span
                    key={`${row?.original?.id}_${row?.index}`}
                    style={{
                      width: '100%',
                      color: ['Completed'].includes(status) ? 'green' : ['Pending'].includes(status) ? 'orange' : ['Approved'].includes(status) ? 'darkcyan' : 'red',
                      fontWeight: 800,
                      align: 'center',
                      letterSpacing: '2px',
                    }}
                  >
                    {status}
                  </span>
                );
              },
            };
          } else if (['userId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Added User',
              Cell: ({ cell, row }) => {
                return row?.original?.linkedInfo?.userData?.[0]?.name || 'Not Found';
              },
            };
          } else if (['amount'].includes(value)) {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return fCurrency(row?.original?.[value]);
              },
            };
          } else if (['expenseCategoriesId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Expense Category',
              Cell: ({ cell, row }) => {
                return expenseCategories?.find((value) => value?.id === row?.original?.expenseCategoriesId)?.name || '-';
              },
            };
          } else if (['locationId'].includes(value)) {
            return {
              accessorKey: value,
              header: 'Location',
              Cell: ({ cell, row }) => {
                return row?.original?.linkedInfo?.locationData?.[0] ? `${row?.original?.linkedInfo?.locationData?.[0]?.name}` : 'Not Found';
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.title?.length === 0) {
      toast.error('Title is required!');
    } else if (selectedDataObj.description?.length === 0) {
      toast.error('Description is required!');
    } else if (selectedDataObj.amount === 0) {
      toast.error('Amount is required!');
    } else if (selectedDataObj.type === null) {
      toast.error('Type is required!');
    } else if (files?.length > 0) {
      toast.error('Related documents needs to be uploaded first!');
    } else {
      dispatch(isAdd ? addExpenses(selectedDataObj) : updateExpenses(selectedDataObj));
    }
  };

  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `Finance/expense/Loc_${selectedLocation?.id}/User_${loggedInUser?.id}`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };

  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: true,
              };
            }
            return fileValue;
          });
        });

        const data = await uploadFiles(file);

        setFiles((value) => {
          return value.filter((fileValue, index) => file?.id !== fileValue?.id);
        });
        if (data?.status) {
          setSelectedDataObj((prevState) => ({
            ...prevState,
            linkedDocuments: [...prevState.linkedDocuments, { name: file?.name, url: data?.url }],
          }));
        }
      } catch (error) {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: false,
              };
            }
            return fileValue;
          });
        });
      }
    }
    setIsUploading(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Page title={`View Expenses`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Expenses`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Expenses',
              href: '',
            },
            { name: `View Expenses` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Record
            </Button>
          }
        />

        {accessVerify('expense_VIEW') ? (
          <DataGridTable
            name={'Assets List'}
            data={expenseList}
            column={[...columns]}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            isRowClickable={true}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'bottom'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '95%',
              height: '95%',
              marginLeft: '2.5%',
              borderRadius: '10px',
            },
          }}
        >
          <Grid container spacing={1} padding={2}>
            <Grid item xs={12} md={8}>
              <Box sx={{ p: 2 }}>
                <h1>{isAdd ? 'Add' : 'Edit'} Record</h1>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color='info'
                variant='outlined'
                size='large'
                style={{ width: '100%' }}
                onClick={() => {
                  manageModel(dataModel, 'add');
                }}
              >
                Close
              </Button>
              <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%', marginLeft: '8px' }} onClick={handleSubmit}>
                {isAdd ? 'Add Record' : 'Save Changes'}
              </LoadingButton>
            </Grid>

            <Grid item xs={12} md={6} lg={selectedDataObj?.expenseLog?.length > 0 ? 4 : 6}>
              <Card sx={{ p: 3 }}>
                <Typography variant='subtitle1' padding='10px 0'>
                  Payment Information
                </Typography>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <TextField fullWidth label='Title' value={selectedDataObj?.title} onChange={(e) => updateEditingData('title', e.target.value)} />

                      <TextField fullWidth multiline={true} rows={4} label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />
                      <Autocomplete
                        onChange={(event, newValue) => {
                          updateEditingData('expenseCategoriesId', sortedexpenseCategories.find((value) => value?.id === newValue?.value)?.id);
                        }}
                        options={sortedexpenseCategories?.map((value) => ({
                          label: `${value?.mainCategory} - ${value?.name}`,
                          value: value?.id,
                        }))}
                        groupBy={(option) => option.label.split(' - ')[0]}
                        renderOption={(props, option) => (
                          <Box component='li' {...props}>
                            {option.label.split(' - ')[1]}
                          </Box>
                        )}
                        value={{
                          label: sortedexpenseCategories.find((value) => value.id === selectedDataObj?.expenseCategoriesId)?.name || 'expense Sub Categories',
                          value: selectedDataObj?.expenseCategoriesId,
                        }}
                        renderInput={(params) => <TextField label='expense Sub Categories' {...params} variant='outlined' fullWidth />}
                        style={{ width: '100%' }}
                      />
                      <Autocomplete
                        onChange={(event, newValue) => {
                          updateEditingData('supplierId', supplierList.find((value) => value?.id === newValue?.value)?.id);
                        }}
                        options={supplierList?.map((value) => ({
                          label: `${value?.type} - ${value?.name}`,
                          value: value?.id,
                        }))}
                        groupBy={(option) => capitalize(option.label.split(' - ')[0])}
                        renderOption={(props, option) => (
                          <Box component='li' {...props}>
                            {option.label.split(' - ')[1]}
                          </Box>
                        )}
                        value={{
                          label: supplierList.find((value) => value.id === selectedDataObj?.supplierId)?.name || 'Select Supplier',
                          value: supplierList?.supplierId,
                        }}
                        renderInput={(params) => <TextField label='Select Supplier' {...params} variant='outlined' fullWidth />}
                        style={{ width: '100%' }}
                        disabled={!isAdd}
                      />

                      <Autocomplete
                        onChange={(event, newValue) => {
                          if (selectedDataObj?.status !== 3) {
                            updateEditingData('status', newValue?.value);
                          }
                        }}
                        options={EXPENSE_STATUS_TYPES?.filter((value) => (value?.visibleOnAdd && isAdd) || (value?.visibleOnEdit && !isAdd))?.map((value) => ({
                          label: `${value?.name}`,
                          value: value?.id,
                        }))}
                        value={{
                          label: `${EXPENSE_STATUS_TYPES?.find((value) => value.id === selectedDataObj?.status)?.name || 'Payment Status'}`,
                          value: selectedDataObj?.status,
                        }}
                        renderInput={(params) => <TextField label='Payment Status' {...params} />}
                        disabled={[2, 3, 4].includes(selectedDataObj?.originalStatus) || isAdd}
                      />

                      <TextField
                        fullWidth
                        type='number'
                        label='Amount'
                        value={selectedDataObj?.amount}
                        onChange={(e) => {
                          if (isAdd) {
                            updateEditingData('amount', e.target.value);
                          }
                        }}
                        disabled={!isAdd}
                      />
                    </Stack>
                  </Card>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={selectedDataObj?.expenseLog?.length > 0 ? 4 : 6}>
              <Card sx={{ p: 3 }}>
                <Typography variant='subtitle1' padding='10px 0'>
                  Related Documents
                </Typography>
                <UploadMultiFile
                  error={false}
                  showPreview={true}
                  files={files}
                  onRemove={(removedFile) => {
                    setFiles(files.filter((file) => file !== removedFile));
                  }}
                  onRemoveAll={() => {
                    setFiles([]);
                  }}
                  helperText={''}
                  onDrop={(uploadedFiles) => {
                    setFiles((value) => [
                      ...value,
                      ...uploadedFiles.map((file, index) =>
                        Object.assign(file, {
                          id: index,
                          preview: URL.createObjectURL(file),
                          isUploading: false,
                        })
                      ),
                    ]);
                  }}
                  onUpload={() => {
                    handleImageUpload();
                  }}
                  isUploading={isUploading}
                />

                {documentGallery?.length > 0 && (
                  <Typography variant='subtitle1' padding='10px 0'>
                    Uploaded Images
                  </Typography>
                )}
                <List disablePadding sx={{ my: 3 }}>
                  <AnimatePresence>
                    {documentGallery
                      ?.filter((value) => value)
                      .map((src, index) => (
                        <>
                          <ListItem
                            key={index}
                            component={m.div}
                            onClick={() => {
                              openImageViewer(index);
                            }}
                            sx={{
                              p: 0,
                              m: 0.5,
                              width: 80,
                              height: 80,
                              borderRadius: 1.25,
                              overflow: 'hidden',
                              position: 'relative',
                              cursor: 'pointer',
                              display: 'inline-flex',
                              border: (theme) => `solid 1px ${theme.palette.divider}`,
                            }}
                          >
                            <Image alt='preview' src={src} ratio='1/1' />
                            <IconButton
                              size='small'
                              sx={{
                                top: 6,
                                p: '2px',
                                right: 6,
                                position: 'absolute',
                                color: 'common.white',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                '&:hover': {
                                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                },
                              }}
                            ></IconButton>
                          </ListItem>
                        </>
                      ))}
                  </AnimatePresence>
                </List>
              </Card>
            </Grid>

            {selectedDataObj?.expenseLog?.length > 0 && (
              <Grid item xs={12} md={6} lg={4}>
                <Card>
                  <Typography variant='subtitle1' sx={{ p: 3 }}>
                    History Information
                  </Typography>
                  <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <Timeline position='alternate'>
                      {selectedDataObj?.expenseLog?.map((value) => (
                        <TimelineItem key={value.id}>
                          <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                            {utcMoment(value?.dateTime).format('YYYY-MM-DD HH:mm:ss')}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                              <Iconify icon='eva:checkmark-circle-2-fill' />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant='body1' component='span'>
                              <b>{value?.title}</b>
                            </Typography>
                            <Typography>{value?.description}</Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </div>
                </Card>
              </Grid>
            )}

            {isViewerOpen && <ImageViewer src={documentGallery} currentIndex={currentImage} disableScroll={false} closeOnClickOutside={true} onClose={closeImageViewer} />}
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

import * as React from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from '../../../../../../../utils/formatNumber';

export default function NormalCollection({ getPaymentTypeTotalAmount, handlePriceChange, setIsModelOpen, data, getTotalPaidAmount, selectedPaymentType, currencyList, handlePaymentAuthenticator }) {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <h2>Enter your collection in {selectedPaymentType?.name},</h2>
      </Box>
      <Grid container justifyContent='left' p={3}>
        <Grid item md={4}>
          <h6>Payable Amount</h6>
          <h1>{fCurrency(data?.paymentRecords?.totalPayable)}</h1>
        </Grid>
        <Grid item md={4}>
          <h6>Total Entered Amount</h6>
          <h1>{fCurrency(getTotalPaidAmount)}</h1>
        </Grid>
        <Grid item md={4}>
          <h6>Amount Left to Pay</h6>
          <h1>{fCurrency(data?.paymentRecords?.totalPayable - getTotalPaidAmount)}</h1>
        </Grid>
      </Grid>

      <Grid container justifyContent='left' p={3}>
        <Grid item md={3}>
          <TextField
            type='number'
            variant='outlined'
            label={`Amount`}
            sx={{ width: '90%', marginTop: '20px', height: '16px' }}
            placeholder={`Amount in LKR`}
            value={getPaymentTypeTotalAmount()?.amount}
            onChange={(event) => {
              handlePriceChange(true, event?.target?.value);
            }}
          />
        </Grid>

        <Grid item md={9}>
          <LoadingButton
            onClick={() => {
              handlePriceChange(false, data?.paymentRecords?.totalPayable - getTotalPaidAmount);
            }}
            variant='outlined'
            size='large'
            style={{ width: '90%', marginTop: '20px' }}
          >
            Add {fCurrency(data?.paymentRecords?.totalPayable - getTotalPaidAmount)}
          </LoadingButton>
        </Grid>
        {Boolean(getPaymentTypeTotalAmount()?.verifierIdRequired) && (
          <Grid item md={12}>
            <TextField
              variant='outlined'
              label={`Verifier Id`}
              sx={{ width: '90%', marginTop: '20px', height: '16px' }}
              placeholder={`Verifier Id`}
              value={getPaymentTypeTotalAmount()?.verifierId}
              onChange={(event) => {
                handlePaymentAuthenticator(event?.target?.value);
              }}
            />
          </Grid>
        )}
        <br />
        <br />
        <br />
        <LoadingButton
          onClick={() => {
            setIsModelOpen(false);
          }}
          variant='contained'
          size='large'
          color={'info'}
          style={{ width: '100%', marginTop: '50px', marginLeft: '2.5%', minHeight: '70px', fontWeight: 800, fontSize: '1.6em' }}
        >
          Confirm & Close
        </LoadingButton>
      </Grid>
    </>
  );
}

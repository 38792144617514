import { capitalize, utcMoment } from '../../../../../utils/common';
import { CASHFLOW_PAYMENT_STATUS, CASHFLOW_TYPES } from '../../../../../utils/constants';
import { fCurrency } from '../../../../../utils/formatNumber';
import React from 'react';
import { useSelector } from '../../../../../redux/store';

export const getColumnsList = (cashflowList, documentTypeOptionList) => {
  const { cashFlowMainCategories, cashFlowSubCategories  } = useSelector((state) => state.cashFlow);

  return !cashflowList?.[0]
    ? []
    : Object.keys(cashflowList?.[0])
      .map((value) => {
        if (['linkedInfo', 'linkedDocuments', 'cashFlowLog', 'cashflowCategoriesId'].includes(value)) {
          return {};
        } else if (['isDebit'].includes(value)) {
          return {
            accessorKey: 'isDebit',
            header: 'Debit/Credit',
            Cell: ({ cell, row }) => {
              const rowValue = row?.original?.isDebit;
              return (
                <span key={`${row?.original?.id}_${row?.index}`} style={{
                  color: rowValue ? 'green' : 'red',
                  fontWeight: 800,
                  align: 'center',
                  letterSpacing: '2px'
                }}>
                    {rowValue ? 'Debit' : 'Credit'}
                  </span>
              );
            },
          };
        } else if (['mainCategoryId'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return cashFlowMainCategories?.find(value=> value?.id === row?.original?.mainCategoryId)?.name || '-';
            },
          };
        } else if (['subCategoryId'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return cashFlowSubCategories?.find(value=> value?.id === row?.original?.subCategoryId)?.name || '-';
            },
          };
        } else if (['dateTime', 'scheduledDate', 'paymentDate', 'paidDate'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              const rowValue = row?.original?.[value];
              const isValidDate = utcMoment(rowValue).isValid();
              return rowValue ? (isValidDate ? utcMoment(rowValue).format('YYYY-MM-DD HH:mm:ss') : '-') : '-';
            },
          };
        } else if (['status'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              const rowValue = row?.original?.[value];
              const status = CASHFLOW_PAYMENT_STATUS?.find((value) => value?.id === rowValue)?.name;
              return (
                <span
                  key={`${row?.original?.id}_${row?.index}`}
                  style={{
                    width: '100%',
                    color: ['Paid'].includes(status) ? 'green' : ['Pending'].includes(status) ? 'orange' : 'red',
                    fontWeight: 800,
                    align: 'center',
                    letterSpacing: '2px'
                  }}
                >
                    {status}
                  </span>
              );
            },
          };
        } else if (['userId'].includes(value)) {
          return {
            accessorKey: value,
            header: 'Added User',
            Cell: ({ cell, row }) => {
              return row?.original?.linkedInfo?.userData?.[0]?.name || 'Not Found';
            },
          };
        } else if (['type'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return row?.original?.type ? CASHFLOW_TYPES?.find((value) => value?.id === row?.original?.type)?.name : 'Not Found';
            },
          };
        } else if (['documentType'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return row?.original?.type ? documentTypeOptionList?.find((value) => value?.value === row?.original?.documentType)?.label : 'Not Found';
            },
          };
        } else if (['assetId'].includes(value)) {
          return {
            accessorKey: value,
            header: 'Asset',
            Cell: ({ cell, row }) => {
              return row?.original?.linkedInfo?.asset?.id ? `${row?.original?.linkedInfo?.asset?.id} - ${row?.original?.linkedInfo?.asset?.name}` : 'Not Found';
            },
          };
        } else if (['amount'].includes(value)) {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return fCurrency(row?.original?.[value]);
            },
          };
        } else if (['purchaseNoteId'].includes(value)) {
          return {
            accessorKey: value,
            header: 'Purchase Note Id',
            Cell: ({ cell, row }) => {
              return row?.original?.purchaseNoteId ? `${row?.original?.purchaseNoteId}` : 'Not Found';
            },
          };
        } else if (['locationId'].includes(value)) {
          return {
            accessorKey: value,
            header: 'Location',
            Cell: ({ cell, row }) => {
              return row?.original?.linkedInfo?.locationData?.[0] ? `${row?.original?.linkedInfo?.locationData?.[0]?.name}` : 'Not Found';
            },
          };
        } else if (['paymentTypeId'].includes(value)) {
          return {
            accessorKey: value,
            header: 'Transactional mediums',
            Cell: ({ cell, row }) => {
              return row?.original?.linkedInfo?.cashFlowPaymentType?.[0] ? `${row?.original?.linkedInfo?.cashFlowPaymentType?.[0]?.name}` : 'Not Found';
            },
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      ?.filter((value) => value?.accessorKey);
}

import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { getMisplaceStockList, getStocks } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../../utils/common';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { fCurrency } from '../../../../utils/formatNumber';

const MisplaceStockList = () => {
  const { showPOSOnly } = useParams();
  const { themeStretch } = useSettings();
  const [purchaseNotesList, setPurchaseNotesList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);

  const { misplaceData: stocks } = useSelector((state) => state?.stock);
  const { selectedLocation } = useSelector((state) => state?.location);

  useEffect(() => {
    dispatch(getMisplaceStockList(selectedLocation?.id));
  }, [selectedLocation, showPOSOnly]);

  useEffect(() => {
    setPurchaseNotesList(
      stocks?.map((value) => {
        return {
          ...value,
        };
      })
    );
  }, [stocks]);

  const columns = purchaseNotesList?.[0]
    ? Object.keys(purchaseNotesList?.[0])
        .map((value) => {
          if (value === 'foodInfo' || value === 'locationInfo' || value === 'supplierInfo' || value === 'unitInfo' || value === 'foodSupplierInfo' || value === 'parentId') {
            return null;
          } else if (value === 'datetime') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return utcMoment(row.original[value]).format('DD MMMM YYYY');
              },
            };
          } else if (value === 'costPerUnit') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return fCurrency(row.original[value]);
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter(Boolean)
    : [];

  return (
    <Page title='View Misplace Stock List'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Misplace Stock List`}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.root }, { name: `Misplace Stock List` }]}
          action={[]}
        />

        {accessVerify('VIEW_STOCK_VIEW') && (
          <DataGridTable
            name={'View Stock'}
            data={purchaseNotesList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            keyField={'stockId'}
            isRowClickable={true}
            onRowClick={(row) => {
              const url = `/dashboard/stock/single-stock-item/${row.original.stockCode}`;
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
            enableRowActions={false}
            renderRowActionItems={(row) => []}
          />
        )}
      </Container>
    </Page>
  );
};

export default MisplaceStockList;

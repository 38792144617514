export default function DnBidimu({ setCurrentPage }) {
  return (
    <div className='fixedMobileSize'>
      <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712474374/aurudu/bidimu.jpg'} className={'welcome'} alt='welcomePage' />

      <a
        onClick={() => {
          setCurrentPage(4);
        }}
      >
        <img src={'https://res.cloudinary.com/bubblemania/image/upload/w_300/aurudu/11.png'} className={'welcomeButton'} alt='button' />
      </a>
    </div>
  );
}

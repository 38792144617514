import React, { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../../../redux/store';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import { useParams } from 'react-router-dom';
import { Container, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
// import html2pdf from "html2pdf.js";
import Logo from '../../../../components/Logo';
import moment from 'moment';
import { getSalaryById } from '../../../../redux/slices/hrRedux/UserManagementRedux/salaryRedux';
import { safeJSONParse } from '../../../../utils/common';

export default function PaySheetIndex() {
  const { token } = useParams();
  const { themeStretch } = useSettings();
  const [sheetData, setSheetData] = useState({});

  const { data } = useSelector((state) => state.salary);

  useEffect(() => {
    dispatch(getSalaryById(token));
  }, [token]);

  useEffect(() => {
    const tempData = data?.[0]?.data;
    if (tempData) {
      setSheetData({ ...safeJSONParse(tempData), month: data?.[0]?.month });
    }
  }, [data]);

  const handleDownload = () => {
    // const element = document.getElementById("paySheet");
    // html2pdf().set({ filename: "paySheet.pdf" }).from(element).save();
  };

  const styling = {
    amountStyles: {
      fontWeight: '600',
      fontSize: '1.2em',
      textAlign: 'right',
    },
    finalAmountStyles: {
      fontWeight: '800',
      fontSize: '1.5em',
      textAlign: 'right',
    },
  };
  return (
    <Page title='Invoice: View'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/*<Tooltip title="Print" onClick={handleDownload}>*/}
        {/*  <IconButton>*/}
        {/*    <Iconify icon={"eva:download-fill"} />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}
        <div id='paySheet' style={{ padding: '20px', width: '100%' }}>
          <Logo sx={{ width: 100, height: 100 }} />
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <p>
                  <b>Bubble Mania (PVT) Ltd</b>
                  <br />
                  No. 101/A5,
                  <br />
                  New Kandy Road,
                  <br />
                  Kothalawala,
                  <br />
                  Kaduwela
                </p>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <p>
                  <b>{sheetData?.username}</b>
                  <br />
                  {sheetData?.address},<br />
                  {sheetData?.city},<br />
                  {sheetData?.phoneNumber}
                  <br />
                  {sheetData?.email}
                </p>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ mb: 5 }}>
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '1.5em',
                  textDecoration: 'underline',
                  fontWeight: '700',
                }}
              >
                Salary Pay Sheet for {moment(sheetData?.month).format('MMMM YYYY')}
              </p>

              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Total Basic Salary
                        <br />
                        {sheetData?.thisMonthTimeLogData?.map((value) => (
                          <span
                            color={'secondary'}
                            style={{
                              color: '#0009c8',
                              fontSize: '12px',
                              margin: '0px 5px',
                            }}
                            sx={{ mr: 1 }}
                          >
                            ({moment(value?.firstLog).format('DD MMMM')} || Minutes: {value?.timeDifference} || Amount: {value?.paymentForTheDay?.dayPayment.toFixed(2)})
                          </span>
                        ))}
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.basicSalary?.amount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Overtime Payment
                        <br />
                        {sheetData?.thisMonthTimeLogData?.map((value) => (
                          <span
                            color={'secondary'}
                            style={{
                              color: '#0009c8',
                              fontSize: '12px',
                              margin: '0px 5px',
                            }}
                            sx={{ mr: 1 }}
                          >
                            ( {moment(value?.firstLog).format('DD MMMM')} || Minutes: {value?.paymentForTheDay?.overTimeDifference.toFixed(2)} || Amount:{' '}
                            {value?.paymentForTheDay?.overtimePayment.toFixed(2)} )
                          </span>
                        ))}
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.overtimePayment?.amount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Inspection Allowance
                        <br />
                        {sheetData?.inspectionAllowance?.data?.map((value) => (
                          <span
                            color={'secondary'}
                            style={{
                              color: '#0009c8',
                              fontSize: '12px',
                              margin: '0px 5px',
                            }}
                            sx={{ mr: 1 }}
                          >
                            ( {moment(value?.date).format('DD MMMM YYYY')} || Percentage: {value?.percentage}% )
                          </span>
                        ))}
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.inspectionAllowance?.amount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Roast Deduction (Absent Days)
                        <br />
                        {sheetData?.roastDeduction?.processedRostList?.map((value) => {
                          if (value?.status) {
                            return null;
                          } else {
                            return (
                              <span
                                color={'secondary'}
                                style={{
                                  color: '#0009c8',
                                  fontSize: '12px',
                                  margin: '0px 5px',
                                }}
                                sx={{ mr: 1 }}
                              >
                                ( {moment(value?.date).format('DD MMMM YYYY')} )
                              </span>
                            );
                          }
                        })}
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.roastDeduction?.amount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>Sale Bonus</TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.saleBonus?.amount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>
                        Time Allowance (Is Elegible: {sheetData?.timeAllowance?.isEligible === 'true' ? 'Yes' : 'No'}
                        )<br />
                        {sheetData?.timeAllowance?.processedLastMonthTimeData?.map((value) => {
                          if (sheetData?.timeAllowance?.isEligible !== 'true' || !value?.startLater) {
                            return null;
                          } else {
                            return (
                              <span
                                color={'secondary'}
                                style={{
                                  color: '#0009c8',
                                  fontSize: '12px',
                                  margin: '0px 5px',
                                }}
                                sx={{ mr: 1 }}
                              >
                                ( {moment(value?.date).format('DD MMMM YYYY')} Delayed )
                              </span>
                            );
                          }
                        })}
                      </TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.timeAllowance?.amount}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: '800' }}>EPF Employee Contribution</TableCell>
                      <TableCell style={styling.amountStyles}>{sheetData?.epfDeduction?.employeeContribution}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ fontWeight: '800', fontSize: '1.5em' }}>Total Salary</TableCell>
                      <TableCell style={styling.finalAmountStyles}>{sheetData?.totalSalary}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
}

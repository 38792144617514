import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 3),
}));

LineChartComponent.propTypes = {
  title: PropTypes.string,
  value: PropTypes.shape({
    dataset: PropTypes.arrayOf(PropTypes.object),
  }),
  icon: PropTypes.element,
  shortenNumber: PropTypes.bool,
};

export default function LineChartComponent({ title, value, icon }) {
  const customize = {
    height: 300,
    legend: { hidden: true },
    padding: 5,
  };

  return (
    <RootStyle>
      {icon}
      <Typography variant='h6' sx={{ color: 'text.secondary', mb: 2 }}>
        {title}
      </Typography>
      <LineChart
        xAxis={[{ scaleType: 'point', data: [...value?.xAxis] }]}
        series={value?.series}
        // dataset={dataset}
        {...customize}
      />
    </RootStyle>
  );
}

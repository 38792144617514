import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { fCurrency } from '../../../../../../../utils/formatNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoupon } from '../../../../../../../redux/slices/marketingRedux/couponRedux';
import { safeJSONParse } from '../../../../../../../utils/common';
import { toast } from 'react-toastify';
import { post } from '../../../../../../../inteceptor';

export default function Discounts({ getPaymentTypeTotalAmount, handlePriceChange, setIsModelOpen, data, getTotalPaidAmount, selectedPaymentType, currencyList }) {
  const dispatch = useDispatch();
  let allLocations;
  let selectedLocation;
  ({ data: allLocations, selectedLocation } = useSelector((state) => state.location));

  let tempCouponList;
  ({ data: tempCouponList } = useSelector((state) => state.coupon));
  const [couponList, setCouponList] = useState([]);

  useEffect(() => {
    setCouponList(
      tempCouponList?.map((value) => {
        return {
          ...value,
          couponCustomers: safeJSONParse(value?.couponCustomers),
          couponLog: safeJSONParse(value?.couponLog),
        };
      })
    );
  }, [tempCouponList]);

  useEffect(() => {
    dispatch(getCoupon(selectedLocation?.id, selectedPaymentType?.name, true, true, true));
  }, [selectedPaymentType?.id]);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <h2>Add {selectedPaymentType?.name},</h2>
      </Box>
      <Grid container justifyContent='left' p={3}>
        <Grid item md={4}>
          <h6>Payable Amount</h6>
          <h1>{fCurrency(data?.paymentRecords?.totalPayable)}</h1>
        </Grid>
        <Grid item md={4}>
          <h6>Total Entered Amount</h6>
          <h1>{fCurrency(getTotalPaidAmount)}</h1>
        </Grid>
        <Grid item md={4}>
          <h6>Amount Left to Pay</h6>
          <h1>{fCurrency(data?.paymentRecords?.totalPayable - getTotalPaidAmount)}</h1>
        </Grid>
      </Grid>

      <Grid container justifyContent='left' p={3}>
        {couponList
          ?.filter((value) => {
            const previousPurchasedCustomerIdList = Array.isArray(value?.couponLog) ? value.couponLog?.map((value) => value?.customerId) : [];
            const couponCustomers = Array.isArray(value?.couponCustomers)
              ? value.couponCustomers
                  ?.filter((couponCustomerIndex) => {
                    if (value?.canSameUserRedeemMulti === 1) {
                      return true;
                    } else {
                      return !Boolean(couponCustomerIndex?.redeemed) && !previousPurchasedCustomerIdList.includes(couponCustomerIndex?.customerId);
                    }
                  })
                  ?.map((value) => value?.customerId)
              : [];
            return !value?.linkedCustomers || couponCustomers.includes(data?.customerInfo?.id);
          })
          ?.filter((value) => {
            return value?.currentCouponCount === null || value?.currentCouponCount > 0;
          })
          ?.map((value) => {
            const totalPayableAmount = data?.paymentRecords?.totalPayable;
            let discountAmount = 0;
            if (value?.type === 'Percentage') {
              discountAmount = (totalPayableAmount * value?.discountAmountOrPercentage) / 100;
            } else {
              discountAmount = value?.discountAmountOrPercentage;
            }

            if (data?.paymentRecords?.totalPayable < discountAmount) {
              discountAmount = data?.paymentRecords?.totalPayable;
            }

            return (
              <Grid item md={6} key={value.id}>
                {' '}
                <LoadingButton
                  onClick={async () => {
                    if (value?.linkedCustomers === 1) {
                      const response = await post(`sendOTPForCoupon`, { customerId: data?.customerInfo?.id });
                      if (response?.status) {
                        const enteredOTP = prompt('OTP Sent. Ask it from the customer!');
                        if (Number(enteredOTP) === Number(response?.otp)) {
                          toast.success('OTP Verified For Coupon!');
                          handlePriceChange(false, discountAmount, value);
                          setIsModelOpen(false);
                        } else {
                          toast.error('Wrong OTP!');
                        }
                      } else {
                        toast.error('Error in sending message!');
                      }
                    } else {
                      handlePriceChange(false, discountAmount, value);
                    }
                  }}
                  variant='outlined'
                  size='large'
                  style={{ width: '90%', marginTop: '20px' }}
                  disabled={value?.minSaleRequired > data?.paymentRecords?.totalPayable}
                >
                  {value?.name} <br />
                  Add {fCurrency(discountAmount)}
                </LoadingButton>
              </Grid>
            );
          })}
        <br />
        <br />
        <br />
        <LoadingButton
          onClick={() => setIsModelOpen(false)}
          variant='contained'
          size='large'
          color={'info'}
          style={{ width: '100%', marginTop: '50px', marginLeft: '2.5%', minHeight: '70px', fontWeight: 800, fontSize: '1.6em' }}
        >
          Confirm & Close
        </LoadingButton>
      </Grid>
    </>
  );
}

// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function DeliveryIcon({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main; // Using primary main color for the icon fill

  return (
    <Box
      sx={{
        width: 120, // Adjust the size as necessary
        height: 120, // Adjust the size as necessary
        lineHeight: 0,
        borderRadius: '50%', // Adjust for circular shape or remove for a different shape
        bgcolor: 'background.neutral', // Adjust the background as necessary
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...other}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='svg-icon'
        style={{ width: '60%', height: '60%', verticalAlign: 'middle' }}
        fill={PRIMARY_MAIN} // Fill color from theme
        overflow='hidden'
        viewBox='0 0 1024 1024' // ViewBox adjusted to fit the icon
      >
        <path d='M489.852 564.115c-31.34 0-56.807 25.417-56.807 56.773 0 31.406 25.467 56.84 56.807 56.84 31.389 0 56.806-25.434 56.806-56.84 0-31.356-25.417-56.773-56.806-56.773zM785.406 564.115c-31.372 0-56.84 25.417-56.84 56.773 0 31.406 25.468 56.84 56.84 56.84 31.358 0 56.772-25.434 56.772-56.84 0-31.356-25.415-56.773-56.772-56.773z'></path>
        <path d='M468.717 550.798H358.353v62.829h58.668c2.995-29.933 23.706-54.385 51.696-62.83zM971.393 550.798c-1.437-12.522-3.722-27.971-17.978-47.077-11.871-15.906-97.478-128.266-106.032-140.365-22.852-32.32-64.785-26.905-64.785-26.905-21.389 0-38.684 17.327-38.684 38.734v175.613H510.987c27.989 8.444 48.7 32.895 51.697 62.829h149.893c3.689-37.125 34.655-66.281 72.83-66.281 38.108 0 69.11 29.156 72.798 66.28h70.48s17.902.408 31.135-12.825c13.231-13.232 13.027-37.261 11.573-50.003z'></path>
        <path d='M730.614 375.185c0-21.407-17.328-38.734-38.682-38.734H397.02c-21.338 0-38.667 17.327-38.667 38.734v160.603h372.26V375.185zm-99.567-6.397c0-1.134.914-1.98 1.996-1.98h14.13c1.1 0 1.998.845 1.998 1.98v14.146c0 1.067-.898 1.98-1.998 1.98h-14.13c-1.083 0-1.996-.913-1.996-1.98v-14.146zm9.052 110.651c-35.079 0-63.744-27.193-66.248-61.56-.034-1.828 1.404-3.165 3.13-3.165h11.829c3.046-.067 3.25 2.81 3.25 2.81 2.284 24.586 22.878 43.81 48.04 43.81 25.112 0 45.706-19.224 47.990-43.81 0 0 .22-2.877 3.249-2.81h11.828c1.727 0 3.147 1.337 3.147 3.164-2.471 34.368-31.169 61.561-66.215 61.561zM241.822 445.968c.101 0 .16-.093.254-.101v.101h89.118v-15.026h-89.118c-20.255-1.895-36.112-18.885-36.112-39.63s15.857-37.736 36.112-39.631c0 0 2.386-.153 2.369-2.674v-9.747a2.576 2.576 0 00-2.623-2.59c-28.395 2.048-50.799 25.722-50.816 54.641.017 28.919 22.421 52.592 50.816 54.657zM15.925 481.977h315.59v15.01H15.925v-15.01zM119.165 533.825h212.35v15.027h-212.35v-15.027zM201.472 585.69h130.044v15.028H201.472V585.69z'></path>
      </svg>
    </Box>
  );
}

// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function SalesCountIcon({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box
      sx={{
        width: 120,
        height: 120,
        lineHeight: 0,
        borderRadius: '50%',
        bgcolor: 'background.neutral',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...other}
    >
      <svg xmlns='http://www.w3.org/2000/svg' className='svg-icon' style={{ width: '60%', height: '60%', verticalAlign: 'middle' }} fill={PRIMARY_MAIN} overflow='hidden' viewBox='0 0 1024 1024'>
        <path d='M439.424 576h17.152a64 64 0 01110.848 0h17.152a64 64 0 01110.848 0H736V448H567.424a64 64 0 01-110.848 0h-17.152a64 64 0 01-110.848 0H288v128h40.576a64 64 0 01110.848 0zm0 64a64 64 0 01-110.848 0H288v96h448v-96h-40.576a64 64 0 01-110.848 0h-17.152a64 64 0 01-110.848 0h-17.152zm17.152-256a64 64 0 01110.848 0H736v-96H288v96h40.576a64 64 0 01110.848 0h17.152zM288 224h448a64 64 0 0164 64v448a64 64 0 01-64 64H288a64 64 0 01-64-64V288a64 64 0 0164-64z'></path>
      </svg>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Checkbox, Chip, Container, Grid, Link, Stack, SwipeableDrawer, Tooltip, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { styled } from '@mui/material/styles';
import { getForKitchenDisplay, markPOSAsSold } from '../../../redux/slices/posRedux/posRedux';
import moment from 'moment-timezone';
import { fCurrency, fCurrencyWithoutSymbol } from '../../../utils/formatNumber';
import { tableCellStyle, tableHeaderStyle, topicStyling } from '../../../utils/cssStyles';
import { utcMoment, safeJSONParse, getTwoDigitsNumber } from '../../../utils/common';
import Image from '../../../components/Image';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { getAllFoods } from '../../../redux/slices/stockManagementRedux/foodRedux';
import { addCooking } from '../../../redux/slices/cookingRedux/cookingRedux';
import { capitalCase } from 'change-case';
import { getTablesRowCount } from '../../../redux/slices/commonRedux/commonRedux';

export default function KitchenDisplay() {
  const dataModel = {
    id: null,
    name: null,
    description: null,
  };
  const tableRowStyle = {
    borderBottom: '1px solid #ddd',
  };

  const textTitleStyle = {
    fontSize: '12px',
    color: 'grey',
    marginRight: '10px',
  };

  const textInformationStyle = {
    fontSize: '15px',
    fontWeight: 500,
    paddingLeft: '15px',
  };

  const posLogStyle = {
    fontSize: '11px',
    borderBottom: '1px solid #bbbbbb',
    padding: '3px',
  };

  const hrLine = {
    borderBottom: '1px solid #555555',
    margin: '0px auto 20px auto',
    width: '100%',
  };

  const { themeStretch } = useSettings();
  const [selectedObject, setSelectedObject] = useState(dataModel);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [posOrderList, setPosOrderList] = useState([]);
  const [foodList, setFoodList] = useState([]);
  const { selectedLocation } = useSelector((state) => state?.location);
  const { data, addData } = useSelector((state) => state.pos);
  const [lastTableUpdatedTime, setLastTableUpdatedTime] = useState(null);
  let allFoodData;
  ({ allData: allFoodData } = useSelector((state) => state?.food));
  let cookingUpdateStatus;
  ({ addData: cookingUpdateStatus } = useSelector((state) => state?.cooking));
  let updatedTimeLog;
  ({ data: updatedTimeLog } = useSelector((state) => state?.common?.latestTableUpdate || []));

  useEffect(() => {
    setFoodList(
      allFoodData?.map((value) => {
        return {
          ...value,
          recipeData: safeJSONParse(value.recipeData),
          pricingData: safeJSONParse(value.pricingData),
          variation: safeJSONParse(value.variation),
          locationData: safeJSONParse(value.locationData),
          unitData: safeJSONParse(value.unitData),
        };
      }) || []
    );
  }, [allFoodData]);

  useEffect(() => {
    setLastTableUpdatedTime(updatedTimeLog?.filter((value) => value?.table_name === 'pos')?.[0]?.count || null);
  }, [updatedTimeLog]);

  useEffect(() => {
    dispatch(getForKitchenDisplay(selectedLocation?.id));
  }, [selectedLocation, cookingUpdateStatus, lastTableUpdatedTime]);

  useEffect(() => {
    dispatch(getAllFoods());
  }, []);

  useEffect(() => {
    let isMounted = true;
    const updateTableUpdateStatus = () => {
      setTimeout(() => {
        if (isMounted) {
          dispatch(getTablesRowCount());
          updateTableUpdateStatus();
        }
      }, 5000);
    };
    updateTableUpdateStatus();
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    if (data?.length > 0) {
      setPosOrderList(
        data
          ?.filter((value) => value?.pos_status === 'Preparing')
          ?.map((value) => {
            return {
              ...value,
              pos_items: safeJSONParse(value?.pos_items),
              pos_payments: safeJSONParse(value?.pos_payments),
              pos_logs: safeJSONParse(value?.pos_logs),
            };
          })
      );
    } else {
      setPosOrderList([]);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getForKitchenDisplay(selectedLocation?.id));
  }, [addData]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setSelectedObject(modelData);
    }
  };

  const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
  });

  const [countdowns, setCountdowns] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      const newCountdowns = {};
      posOrderList?.forEach((value) => {
        const nowString = moment().tz('Asia/Colombo').format('YYYY-MM-DDTHH:mm:ss').toString();
        const endTimeString = utcMoment(value?.pos_dateTime).add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss').toString();
        const now = utcMoment(nowString);
        const endTime = utcMoment(endTimeString);

        if (endTime.isValid()) {
          const diff = moment.duration(endTime.diff(now));
          const hours = getTwoDigitsNumber(Math.abs(diff.hours()));
          const minutes = getTwoDigitsNumber(Math.abs(diff.minutes()));
          const seconds = getTwoDigitsNumber(Math.abs(diff.seconds()));
          if (diff > 0) {
            newCountdowns[value?.pos_id] = `${hours} : ${minutes} : ${seconds}`;
          } else {
            newCountdowns[value?.pos_id] = `- ${hours} : ${minutes} : ${seconds}`;
          }
        } else {
          newCountdowns[value?.pos_id] = 'Invalid date';
        }
      });
      setCountdowns(newCountdowns);
    }, 500);

    return () => clearInterval(timer);
  }, [posOrderList]);

  const handleItemVariableName = (orderId, name, itemId) => {
    const updatedList = posOrderList.map((order) => {
      if (order.pos_id === orderId) {
        return {
          ...order,
          pos_items: order.pos_items.map((item) => {
            if (item.id === itemId) {
              return { ...item, [name]: item[name] === 1 ? 0 : 1 };
            } else {
              return { ...item, [name]: 0 };
            }
          }),
        };
      }
      return order;
    });
    setPosOrderList(updatedList);
  };

  const setAsCookedFood = (value, items) => {
    let cookingType = 'dining';
    if ([3, 4].includes(value?.pos_saleTypeId)) {
      cookingType = 'delivery';
    } else if ([2].includes(value?.pos_saleTypeId)) {
      cookingType = 'takeAway';
    }

    const selectedFood = foodList?.find((food) => food?.id === items?.foodId);
    const obj = {
      barcode: `POS_${value?.pos_id}_${items?.id}`,
      cookingType: cookingType,
      expiryDate: '2000-01-01',
      selectedFood: selectedFood,
      unit: items?.qty,
      posItemId: items?.id,
    };
    dispatch(addCooking({ locationId: selectedLocation?.id, data: [obj] }));
  };

  const isUpdateServeButtonDisabled = (value) => {
    const codeList = value?.pos_items?.map((item) => item?.posCode);
    const filteredList = codeList?.filter((code) => code);
    return filteredList?.length !== codeList?.length;
  };

  const updateServeStatusOnOrder = (value) => {
    dispatch(markPOSAsSold({ ...value, locationId: selectedLocation?.id }));
  };

  return (
    <Page title={`Kitchen Display`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Kitchen Display`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Sale',
              href: '',
            },
            { name: `Kitchen Display` },
          ]}
          action={<></>}
        />

        <LoadingButton
          type='submit'
          variant='contained'
          size='large'
          onClick={() => {
            dispatch(getForKitchenDisplay(selectedLocation?.id));
          }}
          style={{ width: '100%' }}
        >
          Refresh Data
        </LoadingButton>
        <br />
        <br />
        <Grid container spacing={3}>
          {posOrderList?.map((value) => {
            return (
              <Grid item xs={12} md={6} lg={3} key={value?.pos_id}>
                <Card
                  sx={{ p: 3 }}
                  style={{
                    height: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                  }}
                >
                  <Stack spacing={2}>
                    {countdowns[value?.pos_id] && (
                      <Typography
                        variant='h3'
                        style={{
                          textAlign: 'center',
                          padding: '10px',
                          width: '100%',
                          backgroundColor: countdowns[value?.pos_id]?.includes('-') ? '#a00000' : '#c3ffc3',
                          color: countdowns[value?.pos_id]?.includes('-') ? 'white' : 'black',
                          borderRadius: '10px',
                        }}
                      >
                        {countdowns[value?.pos_id]}
                      </Typography>
                    )}
                    <RowStyle>
                      <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                        Order ID:
                      </Typography>
                      <Typography variant='body2'>{value?.pos_id}</Typography>
                    </RowStyle>
                    <RowStyle>
                      <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                        Date:
                      </Typography>
                      <Typography variant='body2'>{utcMoment(value?.pos_dateTime).format('DD MMMM YYYY HH:mm:ss')}</Typography>
                    </RowStyle>
                    <RowStyle>
                      <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                        Customer Name:
                      </Typography>
                      <Typography variant='body2'>{value?.customerName}</Typography>
                    </RowStyle>
                    <Stack spacing={1}>
                      {value?.pos_items?.map((items) => {
                        return (
                          <Stack
                            direction='row'
                            style={{
                              cursor: 'pointer',
                              borderRadius: '10px',
                              padding: '15px',
                              boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
                            }}
                          >
                            <Image
                              alt={items?.foodDescription}
                              src={items?.foodImage}
                              sx={{
                                width: 48,
                                height: 48,
                                borderRadius: 1.5,
                                flexShrink: 0,
                              }}
                            />

                            <Box
                              sx={{
                                flexGrow: 1,
                                minWidth: 150,
                                marginLeft: '20px',
                              }}
                            >
                              <Link
                                component={RouterLink}
                                to='#'
                                sx={{
                                  color: 'text.primary',
                                  typography: 'subtitle2',
                                }}
                              >
                                {items?.foodName}
                              </Link>

                              <Stack direction='row'>
                                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                  QTY : {items?.qty}
                                </Typography>
                              </Stack>
                            </Box>

                            {items?.posCode ? (
                              <a
                                onClick={() => {
                                  const url = `/dashboard/stock/single-stock-item/${items?.posCode}`;
                                  window.open(url, '_blank', 'noopener,noreferrer');
                                }}
                              >
                                <Tooltip title={capitalCase(items?.posCode)}>
                                  <Chip
                                    key={`${items?.id}_${items?.posCode}`}
                                    label={items?.posCode}
                                    sx={{
                                      width: '90px',
                                      m: 0.5,
                                    }}
                                  />
                                </Tooltip>
                              </a>
                            ) : items?.isShowCookButton !== 1 ? (
                              <Checkbox onClick={() => handleItemVariableName(value?.pos_id, 'isShowCookButton', items?.id)} />
                            ) : (
                              <Button
                                variant='outlined'
                                color='warning'
                                onClick={() => {
                                  handleItemVariableName(value?.pos_id, 'isShowCookButton', items?.id);
                                  setAsCookedFood(value, items);
                                }}
                              >
                                Set Cooked
                              </Button>
                            )}
                          </Stack>
                        );
                      })}
                    </Stack>

                    <Stack direction='row' spacing={1.5}>
                      <Button
                        fullWidth
                        variant='contained'
                        color='warning'
                        onClick={() => {
                          manageModel(value);
                        }}
                      >
                        View Order
                      </Button>
                      <Button
                        fullWidth
                        variant='contained'
                        color='success'
                        disabled={isUpdateServeButtonDisabled(value)}
                        onClick={() => {
                          updateServeStatusOnOrder(value);
                        }}
                      >
                        Update Serve
                      </Button>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ padding: '10px 0 0 10px' }}>
            <h1>Order Number {selectedObject?.pos_id}</h1>
            <div style={hrLine} />
          </Box>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Stack spacing={3} sx={{ p: 2 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ height: '23vh', alignItems: 'center', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Customer Details</p>
                        <div sx={{ flexDirection: 'column' }}>
                          <div sx={{ alignItems: 'center', mb: 2 }}>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={textTitleStyle}>Date & Time:</td>
                                  <td style={textInformationStyle}>{moment(selectedObject?.pos_dateTime).format('Do MMMM YYYY, h:mm:ss a')}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>POS Created By</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_entered_userName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>ID:</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_customerId}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Name:</td>
                                  <td style={textInformationStyle}>{selectedObject?.customerName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Phone:</td>
                                  <td style={textInformationStyle}>{selectedObject?.customerPhone}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Birthday:</td>
                                  <td style={textInformationStyle}>{moment(selectedObject?.customerBirthday).format('DD MMMM')}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ height: '23vh', alignItems: 'center', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Drawer Details</p>
                        <div sx={{ flexDirection: 'column' }}>
                          <div sx={{ alignItems: 'center', mb: 2 }}>
                            <table>
                              <tbody>
                                <tr>
                                  <td style={textTitleStyle}>ID:</td>
                                  <td style={textInformationStyle}>{selectedObject?.cashdrawers_id}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Location:</td>
                                  <td style={textInformationStyle}>{selectedObject?.cashdrawers_locationName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Current Status:</td>
                                  <td style={textInformationStyle}>{selectedObject?.cashdrawers_status}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Sale Type:</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_saleTypeName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Location:</td>
                                  <td style={textInformationStyle}>{selectedObject?.pos_locationName}</td>
                                </tr>
                                <tr>
                                  <td style={textTitleStyle}>Special Note:</td>
                                  <td style={textInformationStyle}>{selectedObject?.specialNote}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ height: '33vh', overflowY: 'auto', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Order History</p>
                        <div sx={{ flexDirection: 'column' }}>
                          <div sx={{ alignItems: 'center', mb: 2 }}>
                            <table>
                              <tbody>
                                {selectedObject?.pos_logs?.map((posLogIndex, index) => (
                                  <tr>
                                    <td style={posLogStyle}>{moment(posLogIndex?.dateTime).format('DD/MM HH:mm')}</td>
                                    <td style={posLogStyle}>{posLogIndex?.description}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ height: '33vh', overflowY: 'auto', p: 3 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <p style={topicStyling}>Payment Receivings</p>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                          <thead>
                            <tr>
                              <th style={tableHeaderStyle}>Name</th>
                              <th style={tableHeaderStyle}>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedObject?.pos_payments?.map((cartItem) => (
                              <tr key={cartItem?.id} style={tableRowStyle}>
                                <td style={tableCellStyle}>{cartItem?.paymentTypesName}</td>
                                <td style={tableCellStyle}>{fCurrency(cartItem?.amount)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    </Card>
                  </Grid>
                  {/*<Grid item xs={12} md={12}>*/}
                  {/*  <Card sx={{ display: "flex", alignItems: "left", flexDirection: "column", p: 3 }}>*/}
                  {/*    <p style={topicStyling}>Order Status</p>*/}
                  {/*    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: '100%' }}>*/}
                  {/*      {['Preparing', 'Served', 'Refunded', 'Canceled']?.map(value=> <a onClick={()=> {*/}
                  {/*        if( selectedObject?.pos_status === 'Served' && value === 'Preparing') {*/}
                  {/*          toast.error("After Served you are not allowed to go to preparing!")*/}
                  {/*        } else if(['Refunded', 'Canceled'].includes(selectedObject?.pos_status)){*/}
                  {/*          toast.error(`After ${value}, You are not elegible to change the status!`)*/}
                  {/*        } else  if(value === 'Served') {*/}
                  {/*          toast.error("Not Allowed to set to served from here. Please try kitchen Display!")*/}
                  {/*        } else {*/}
                  {/*          dispatch(updatePosStatus({ id: selectedObject?.pos_id, status: value }));*/}
                  {/*        }*/}
                  {/*      }}>*/}
                  {/*        <p style={getOrderStatusStyling(value)}>*/}
                  {/*          {value}*/}
                  {/*        </p>*/}
                  {/*      </a>)}*/}
                  {/*    </Box>*/}
                  {/*  </Card>*/}
                  {/*</Grid>*/}
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={3} sx={{ p: 2 }}>
                <Card sx={{ height: '60vh', overflowY: 'auto', p: 3 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <p style={topicStyling}>Ordered Foods</p>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th style={tableHeaderStyle}>Name</th>
                          <th style={tableHeaderStyle}>Qty</th>
                          <th style={tableHeaderStyle}>Unit Price</th>
                          <th style={tableHeaderStyle}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedObject?.pos_items?.map((cartItem) => (
                          <tr key={cartItem?.id} style={tableRowStyle}>
                            <td style={tableCellStyle}>{cartItem?.foodName}</td>
                            <td style={tableCellStyle}>{cartItem?.qty} </td>
                            <td style={tableCellStyle}>{fCurrencyWithoutSymbol(cartItem?.itemPrice)}</td>
                            <td style={tableCellStyle}>{fCurrencyWithoutSymbol(cartItem?.itemPrice * cartItem?.qty)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                </Card>
                <Card style={{ height: '15vh', padding: '20px' }}>
                  <p style={topicStyling}>Total Amount</p>
                  <p
                    style={{
                      fontSize: '40px',
                      fontWeight: 800,
                      textAlign: 'center',
                      width: '100%',
                      color: 'red',
                    }}
                  >
                    {fCurrency(selectedObject?.pos_totalAmount)}
                  </p>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}

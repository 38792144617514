class SmsCounter {
  static gsm7bitChars = '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
  static gsm7bitExChar = '\\^{}\\[~\\]|€';
  static gsm7bitRegExp = new RegExp('^[' + SmsCounter.gsm7bitChars + ']*$');
  static gsm7bitExRegExp = new RegExp('^[' + SmsCounter.gsm7bitChars + SmsCounter.gsm7bitExChar + ']*$');
  static gsm7bitExOnlyRegExp = new RegExp('^[' + SmsCounter.gsm7bitExChar + ']*$');

  static GSM_7BIT = 'GSM_7BIT';
  static GSM_7BIT_EX = 'GSM_7BIT_EX';
  static UTF16 = 'UTF16';

  static messageLength = {
    GSM_7BIT: 160,
    GSM_7BIT_EX: 160,
    UTF16: 70,
  };

  static multiMessageLength = {
    GSM_7BIT: 153,
    GSM_7BIT_EX: 153,
    UTF16: 67,
  };

  static count(text) {
    let encoding = this.detectEncoding(text);
    let length = text.length;
    if (encoding === this.GSM_7BIT_EX) {
      length += this.countGsm7bitEx(text);
    }
    let per_message = this.messageLength[encoding];
    if (length > per_message) {
      per_message = this.multiMessageLength[encoding];
    }
    let messages = Math.ceil(length / per_message);
    let remaining = per_message * messages - length;
    if (remaining === 0 && messages === 0) {
      remaining = per_message;
    }
    return {
      encoding: encoding,
      length: length,
      per_message: per_message,
      remaining: remaining,
      messages: messages,
    };
  }

  static detectEncoding(text) {
    if (text.match(this.gsm7bitRegExp) != null) {
      return this.GSM_7BIT;
    } else if (text.match(this.gsm7bitExRegExp) != null) {
      return this.GSM_7BIT_EX;
    } else {
      return this.UTF16;
    }
  }

  static countGsm7bitEx(text) {
    let chars = [];
    for (let i = 0, len = text.length; i < len; i++) {
      let char2 = text[i];
      if (char2.match(this.gsm7bitExOnlyRegExp) != null) {
        chars.push(char2);
      }
    }
    return chars.length;
  }
}

export default SmsCounter;

import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../../store';
import { get, post, put } from '../../../inteceptor';
import moment from 'moment';

const initialState = {
  error: null,
  time: null,
  data: [],
  addData: {},
  sorted: [],
};

const slice = createSlice({
  name: 'ExpenseCategories',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = { message: action.payload, time: moment.now().toString() };
    },

    getExpenseCategoriesSuccess(state, action) {
      state.data = action.payload.data;
    },

    addOrUpdateExpenseCategories(state, action) {
      state.addData = {
        data: action.payload.data,
        time: moment.now().toString(),
      };
    },

    clearAll(state) {
      state.addData = {};
      state.error = {};
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      state.checkout.activeStep = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { filterCategories } = slice.actions;

// ----------------------------------------------------------------------

export function getExpenseCategories() {
  return async () => {
    try {
      const response = await get(`ExpenseCategories`);
      if (response.status) {
        dispatch(slice.actions.getExpenseCategoriesSuccess(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addExpenseCategories(body) {
  return async () => {
    try {
      const response = await post(`ExpenseCategories`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateExpenseCategories(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateExpenseCategories(body) {
  return async () => {
    try {
      const response = await put(`ExpenseCategories`, body);
      if (response.status) {
        dispatch(slice.actions.addOrUpdateExpenseCategories(response));
      } else {
        dispatch(slice.actions.hasError(response.message));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

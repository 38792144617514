import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerFromUUID } from '../../../../redux/slices/customerRedux/customerRedux';

export default function WinningPage({ uuid }) {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState({});

  const customerData = useSelector((state) => state?.customers.data);

  useEffect(() => {
    if (uuid) {
      dispatch(getCustomerFromUUID(uuid));
    }
  }, [uuid, dispatch]);

  useEffect(() => {
    if (customerData && customerData.length > 0) {
      setCustomer(customerData[0]);
    }
  }, [customerData]);

  return (
    <div className='fixedMobileSizeForWinning'>
      <div className='content'>
        {customer?.auruduURL && <img src={customer.auruduURL} alt='Aurudu' className='winningItemBanner' />}
        {customer?.name && <p className='winningName'>{customer.name}</p>}
        {customer?.name && <img src={'https://res.cloudinary.com/bubblemania/image/upload/v1712462042/aurudu/Rectangle_1.png'} alt='Aurudu' className='winnerNameLine' />}
      </div>
    </div>
  );
}

import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '../../../../redux/store';

const DeliveryType = ({ data, setData, onClickSound, onChangeTab }) => {
  const theme = useTheme();
  const { saleTypes } = useSelector((state) => state?.sales);

  const handleCardClick = (index) => {
    setData((currentData) => {
      return {
        ...currentData,
        deliveryInfo: { ...saleTypes[index], index: index },
      };
    });
    onClickSound();
    setTimeout(() => {
      onChangeTab('order_info');
    }, 80);
  };

  return (
    <Grid container spacing={2} justifyContent='center'>
      {saleTypes?.map((value, index) => (
        <Grid item key={index}>
          <Card
            sx={{
              width: 345,
              border: 'black',
              boxShadow:
                data?.index === index
                  ? `${theme.palette.primary.main} 0px 0.0625em 0.0625em, ${theme.palette.primary.light} 0px 0.125em 0.5em, ${theme.palette.primary.main} 0px 0px 0px 1px inset`
                  : '',
              transition: 'transform 0.6s',
              transform: data?.index === index ? 'scale(1.1)' : 'scale(1)',
              margin: '10px',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => handleCardClick(index)}
          >
            <CardMedia sx={{ height: 200 }} image={value?.image} title={value?.name} />
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                {value?.name}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                {value?.description}
              </Typography>
            </CardContent>
            <CardActions>
              <LoadingButton
                variant={data?.index === index ? 'contained' : 'outlined'}
                size='large'
                style={{
                  width: '100%',
                }}
              >
                {data?.index === index ? 'Selected' : value?.name}
              </LoadingButton>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default DeliveryType;

import React from 'react';
import { FormControlLabel, Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function ExSwitch({ code, label, dataModel, handleUpdate, ...other }) {
  const internalValue = other?.value ? other?.value || null : dataModel?.[code] || null;

  const switchStyles = {
    marginLeft: '2px',
    border: `1px solid ${internalValue ? '#909eab' : '#dbe0e4'}`,
    padding: '5px',
    borderRadius: '10px',
  };

  const labelStyle = {
    fontSize: 16,
    margin: '10px',
    color: internalValue ? '#212b36' : '#909eab',
  };

  return (
    <FormControlLabel
      style={switchStyles}
      control={<IOSSwitch checked={internalValue} onChange={(e) => handleUpdate(code, e.target.checked)} />}
      label={<span style={labelStyle}>{label}</span>}
      {...other}
    />
  );
}
